import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateOrganisationMutation } from '@/app/services/organisation'
import {
    selectCurrencySymbol,
    selectOrganisation,
    selectUseImperialUnits,
    setOrganisation,
} from '@/app/slices/organisationSlice'
import SetupModeSelect from '@/common/components/SetupModeSelect/SetupModeSelect'
import { useToolBoxTreatments } from '@/common/hooks'
import { fractionToPercentage, percentageToFraction } from '@/common/utils'

const classes = {
    title: {
        fontWeight: 500,
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: 'text.secondary',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const EditFoldingProcessForm = ({ foldingSettings, onCancel, onSave }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const [updateOrganisation] = useUpdateOrganisationMutation()

    const [isSaving, setIsSaving] = useState(false)

    const { showFoldingProductionWarnings } = useToolBoxTreatments()

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            foldingSettingsId: foldingSettings?.foldingSettingsId,
            maximumPressTonnage: foldingSettings?.maximumPressTonnage,
            maximumPressWidth: foldingSettings?.maximumPressWidth,
            baseHourlyRate: foldingSettings?.baseHourlyRate,
            setupMode: foldingSettings?.setupMode,
            addedLabourHourlyRate: foldingSettings?.addedLabourHourlyRate,
            productionOverride: fractionToPercentage(foldingSettings?.productionOverride),
            setupOverride: fractionToPercentage(foldingSettings?.setupOverride),
        },
    })

    const handleFoldingSettingsButtonClick = async (data, _) => {
        setIsSaving(true)

        const foldingSettings = { ...data }
        foldingSettings.productionOverride = percentageToFraction(foldingSettings.productionOverride)
        foldingSettings.setupOverride = percentageToFraction(foldingSettings.setupOverride)
        foldingSettings.organisationId = organisation.organisationId

        try {
            const updatedOrganisation = await updateOrganisation({
                organisationDto: {
                    organisationId: organisation.organisationId,
                    hourlyRate: organisation.hourlyRate,
                    setupTimeSeconds: organisation.setupTimeSeconds,
                    sheetChangeTimeSeconds: organisation.sheetChangeTimeSeconds,
                    setupMode: organisation.setupMode,
                    sheetChangeMode: organisation.sheetChangeMode,
                    sheetMarkupPercentage: percentageToFraction(organisation.sheetMarkupPercentage),
                    foldingSettings: foldingSettings,
                },
            }).unwrap()

            dispatch(setOrganisation(updatedOrganisation))

            enqueueSnackbar(t('Folding settings updated'), { variant: 'success' })
            onSave()
        } catch (error) {
            enqueueSnackbar(t(error.response?.data?.title || 'An error occurred'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <>
            <Typography
                sx={classes.title}
                variant="h5"
            >
                {t('Edit folding settings')}
            </Typography>

            {showFoldingProductionWarnings ? (
                <>
                    <Controller
                        control={control}
                        name="maximumPressTonnage"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                inputProps={{
                                    'data-testid': 'maximum-press-tonnage',
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment>{t('tonnes')}</InputAdornment>,
                                }}
                                label={t('Maximum press tonnage')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            required: 'Required',
                            min: {
                                value: 0,
                                message: t('Must be greater than {{number}}', { number: 0 }),
                            },
                            max: {
                                value: 1000,
                                message: t('Must be less than {{number}}', { number: 1000 }),
                            },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumPressWidth"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                inputProps={{
                                    'data-testid': 'maximum-press-width',
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment>{useImperialUnits ? 'in' : 'mm'}</InputAdornment>,
                                }}
                                label={t('Maximum press width')}
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            required: t('Required'),
                            min: {
                                value: 0,
                                message: t('Must be greater than {{number}}', { number: 0 }),
                            },
                            max: {
                                value: useImperialUnits ? 400 : 10000,
                                message: t('Must be less than {{number}}', {
                                    number: useImperialUnits ? 400 : 10000,
                                }),
                            },
                        }}
                    />
                </>
            ) : null}

            <Controller
                control={control}
                name="baseHourlyRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'base-hourly-rate',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment>{currencySymbol}</InputAdornment>,
                        }}
                        label={t('Base hourly rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: 'Required',
                    min: { value: 0, message: 'Must be greater than 0' },
                }}
            />

            <Controller
                control={control}
                name="addedLabourHourlyRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'additional-labour-hourly-rate',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment>{currencySymbol}</InputAdornment>,
                        }}
                        label={t('Additional labour hourly rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: 'Required',
                    min: { value: 0, message: 'Must be greater than 0' },
                }}
            />

            <Controller
                control={control}
                name="setupMode"
                render={({ field: { onChange, value } }) => (
                    <SetupModeSelect
                        classes={{
                            root: undefined,
                        }}
                        className={classes.input}
                        fullWidth={true}
                        includeNoneOption={false}
                        inputProps={{
                            'data-testid': 'folding-setup-mode',
                        }}
                        label={t('Setup Mode')}
                        labelPosition="top"
                        labelText={''}
                        value={value}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />

            <Controller
                control={control}
                name="setupOverride"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'setup-override',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment>%</InputAdornment>,
                        }}
                        label={t('Setup override')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: 'Required',
                    min: {
                        value: 0,
                        message: t('Must be greater than {{number}}', { number: 0 }),
                    },
                }}
            />

            <Controller
                control={control}
                name="productionOverride"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'production-override',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment>%</InputAdornment>,
                        }}
                        label={t('Production override')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: 'Required',
                    min: {
                        value: 0,
                        message: t('Must be greater than {{number}}', { number: 0 }),
                    },
                }}
            />

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="secondary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleFoldingSettingsButtonClick)}
                >
                    {isSaving ? t('Saving...') : t('Update')}
                </Button>
            </Box>
        </>
    )
}

EditFoldingProcessForm.propTypes = {
    foldingSettings: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
}

export default EditFoldingProcessForm
