import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Close, Edit, Save } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

import SaveNotesDialog from '../common/SaveNotesDialog'

const classes = {
    icon: {
        fontSize: '16px',
    },
}

const QuoteNotesModal = ({ onClose, open, quote }) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const [updateQuote] = useUpdateQuoteMutation()

    const [isEditingVendorNotes, setIsEditingVendorNotes] = useState(false)
    const [isEditingPrivateNotes, setIsEditingPrivateNotes] = useState(false)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

    const [vendorNotes, setVendorNotes] = useState(quote?.vendorNotes)
    const [privateNotes, setPrivateNotes] = useState(quote?.privateNotes)

    const handleUpdateQuote = async (newStuff) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    id: quoteId,
                    organisationId: organisationId,
                    ...newStuff,
                },
            }).unwrap()
        } catch (error) {
            const errorMessage = t('$t(An error occurred) updating $t(quote) notes.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const toggleEditVendorNotes = () => {
        if (isEditingVendorNotes) {
            handleUpdateQuote({ ...quote, vendorNotes })
        }
        setIsEditingVendorNotes((v) => !v)
    }
    const toggleEditPrivateNotes = () => {
        if (isEditingPrivateNotes) {
            handleUpdateQuote({ ...quote, privateNotes })
        }
        setIsEditingPrivateNotes((v) => !v)
    }

    const handleVendorNotesChange = (event) => {
        const { value } = event.target
        if (value != vendorNotes) {
            setVendorNotes(value)
        }
    }

    const handlePrivateNotesChange = (event) => {
        const { value } = event.target
        if (value != privateNotes) {
            setPrivateNotes(value)
        }
    }

    const handleClose = (_, reason) => {
        if (reason !== 'backdropClick') {
            if (isEditingPrivateNotes || isEditingVendorNotes) {
                setShowConfirmationDialog(true)
            } else {
                onClose()
            }
        }
    }
    const onSaveNotesDialogCancel = () => {
        setShowConfirmationDialog(false)
        onClose()
    }

    const onSaveNotesDialogConfirm = () => {
        setShowConfirmationDialog(false)
        handleUpdateQuote({
            ...quote,
            privateNotes,
            vendorNotes,
        })
        onClose()
    }

    useEffect(() => {
        setVendorNotes(quote?.vendorNotes)
        setPrivateNotes(quote?.privateNotes)

        return () => {
            setVendorNotes('')
            setPrivateNotes('')
        }
    }, [quote])

    return (
        <Dialog
            open={open}
            PaperProps={{ sx: { width: 400 } }}
            disableEscapeKeyDown
            onClose={handleClose}
        >
            <DialogTitle
                alignItems="center"
                data-testid="quote-notes-modal"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="strong1">{`${t('NOTES FOR QUOTE')} ${quote?.name}`}</Typography>
                <IconButton
                    color="secondary"
                    size="small"
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                >
                    <Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="quote-vendor-notes-label"
                                variant="strong2"
                            >
                                {t('Vendor notes')}
                            </Typography>
                            <IconButton
                                color="secondary"
                                size="small"
                                onClick={toggleEditVendorNotes}
                            >
                                {isEditingVendorNotes ? <Save sx={classes.icon} /> : <Edit sx={classes.icon} />}
                            </IconButton>
                        </Box>
                        <TextField
                            color="secondary"
                            data-testid="quote-vendor-notes"
                            helperText={t(
                                'These notes will be visible on the Quote, Order Confirmation, Work Order, Work Order Summary and Delivery Docket.'
                            )}
                            InputProps={{
                                readOnly: !isEditingVendorNotes,
                                style: { fontSize: 14 },
                            }}
                            value={vendorNotes}
                            fullWidth
                            multiline
                            onChange={handleVendorNotesChange}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="quote-private-notes-label"
                                    variant="strong2"
                                >
                                    {t('Private notes')}
                                </Typography>
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    onClick={toggleEditPrivateNotes}
                                >
                                    {isEditingPrivateNotes ? <Save sx={classes.icon} /> : <Edit sx={classes.icon} />}
                                </IconButton>
                            </Box>
                        </Box>
                        <TextField
                            color="secondary"
                            data-testid="quote-private-notes"
                            helperText={t('These notes will only be visible on the Work Order and Work Order Summary.')}
                            InputProps={{
                                readOnly: !isEditingPrivateNotes,
                                style: { fontSize: 14 },
                            }}
                            value={privateNotes}
                            fullWidth
                            multiline
                            onChange={handlePrivateNotesChange}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <SaveNotesDialog
                open={showConfirmationDialog}
                onCancel={onSaveNotesDialogCancel}
                onConfirm={onSaveNotesDialogConfirm}
            />
        </Dialog>
    )
}

export default QuoteNotesModal

QuoteNotesModal.propTypes = {
    open: PropTypes.bool,
    quote: PropTypes.object,
    onClose: PropTypes.func,
}
