import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSplitTreatments } from '@splitsoftware/splitio-react'

import { selectCurrentUser } from '@/app/slices/appSlice'
import { getProductDetails, RESELLERS } from '@/common/utils'

const splitTreatments = {
    show3dButton: 'ToolBox_Show3DPartsButton',
    replaceSplinesWithLinearSegments: 'Toolbox_ReplaceSplineWithLinearSegmentsUnfold3d',
    showPricing: 'ToolBox_quoting_iteration1',
    showQuotingIteration2: 'ToolBox_quoting_iteration2',
    showQuickBooks: 'ToolBox_QuickBooks',
    showOrdering: 'ToolBox_ordering',
    showCustomers: 'ToolBox_customers',
    showFolding: 'ToolBox_folding',
    showInvoices: 'ToolBox_invoices',
    showLibellulaWhitelabelling: 'ToolBox_libellula_whitelabel',
    showStrikerWhitelabelling: 'ToolBox_Striker_whitelabel',
    showSecondaryProcesses: 'ToolBox_secondary_operations',
    showFoldingProductionWarnings: 'ToolBox_FoldingProductionWarnings',
    showAdminTools: 'ToolBox_ShowAdminTools',
    showCustomerCentral: 'ToolBox_CustomerCentral',
    showMaterialRevamp: 'ToolBox_material_revamp',
    showNestView: 'ToolBox_DisplayNestView',
    showCuttingTechManagement: 'ToolBox_ShowCuttingTechManagement',
    showPartLibrary: 'ToolBox_PartLibrary',
    showTube: 'ToolBox_Tube',
    showXero: 'ToolBox_Xero',
    isReady: false,
    showCsvImportExportButtons: 'ToolBox_ImportExportSheetsRateTables',
    showWebStore: 'ToolBox_WebStore',
    showPdfButton: 'ToolBox_PDF',
    showVoucherCode: 'ToolBox_VoucherCode',
    showUserflowTooltips: 'ToolBox_TooltipsToggle',
    showEmailCustomerModal: 'ToolBox_EmailCustomerModal',
    showPDFAttachmentCheckbox: 'ToolBox_PDFAttachments',
    previewResellerName: undefined,
    showIntegrations: false,
    showPdfFooterFields: 'ToolBox_PDFFooterFields',
    showDownloadPdfs: 'ToolBox_DownloadPDFs',
    showProductionPDFs: 'ToolBox_ProductionPDFs',
    showMiscItems: 'ToolBox_misc_items',
    showFixedPriceParts: 'ToolBox_FixedPriceParts',
    showMultipleTaxRates: 'ToolBox_MultipleTaxRates',
    showPayments: 'ToolBox_Payments',
    showCustomerTaxRates: 'ToolBox_CustomerTaxRates',
    showViewer3D: 'ToolBox_ShowViewer3D',
    showViewerTube: 'ToolBox_ShowViewerTube',
    showRetryIntegration: 'ToolBox_RetryIntegration',
    showShipping: 'ToolBox_Shipping',
    showIntegrationStatus: 'ToolBox_QBOXeroSuccessStatus',
    showGrainDirection: 'ToolBox_GrainDirection',
}

const resellerName = import.meta.env.VITE_RESELLER

export function useToolBoxTreatments() {
    const currentUser = useSelector(selectCurrentUser)

    const productDetails = getProductDetails()

    const attributes = {
        emailAddress: currentUser ? currentUser.emailAddress : undefined,
        reseller: resellerName ? RESELLERS[resellerName].name : undefined,
    }

    const cleanSplitTreatments = { ...splitTreatments }
    delete cleanSplitTreatments.isReady
    delete cleanSplitTreatments.previewResellerName
    delete cleanSplitTreatments.showIntegrations

    const { isReady, treatments } = useSplitTreatments({ names: Object.values(cleanSplitTreatments), attributes })

    const resultTreatments = useMemo(() => {
        const result = {
            ...splitTreatments,
        }
        return Object.keys(splitTreatments).reduce((result, key) => {
            result[key] = treatments[splitTreatments[key]]?.treatment === 'on'
            return result
        }, result)
    }, [treatments])

    resultTreatments.isReady = isReady
    resultTreatments.showLibellulaWhitelabelling =
        resultTreatments.showLibellulaWhitelabelling || import.meta.env.VITE_RESELLER === RESELLERS.libellula.name
    resultTreatments.showStrikerWhitelabelling =
        resultTreatments.showStrikerWhitelabelling || import.meta.env.VITE_RESELLER === RESELLERS.striker.name

    resultTreatments.previewResellerName = resultTreatments.showLibellulaWhitelabelling
        ? RESELLERS.libellula.name
        : resultTreatments.showStrikerWhitelabelling
          ? RESELLERS.striker.name
          : undefined
    resultTreatments.showWebStore = resultTreatments.showWebStore && productDetails.hasWebStoreAccess
    resultTreatments.showIntegrations = resultTreatments.showXero || resultTreatments.showQuickBooks

    return resultTreatments
}
