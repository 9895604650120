import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useGetCuttingTechnologiesQuery, useUpdateCuttingTechnologyMutation } from '@/app/services/cuttingTechnologies'
import { useUpdateOrganisationMutation } from '@/app/services/organisation'
import {
    selectIsOnTrial,
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
    selectResolvedPlanMetadata,
    setOrganisation,
} from '@/app/slices/organisationSlice'
import { TbxDrawer, TbxShowToggle } from '@/common/components'
import { ProcessesToolbar } from '@/common/components/MainAppBar'
import RoleRequiredContainer from '@/common/components/RoleRequiredContainer/RoleRequiredContainer'
import TbxFoldingCard from '@/common/components/TbxFoldingCard/TbxFoldingCard'
import TbxTechCard from '@/common/components/TbxTechCard/TbxTechCard'
import { useToolBoxTreatments } from '@/common/hooks'
import { fractionToPercentage, percentageToFraction } from '@/common/utils'
import EditFoldingProcessForm from '@/features/processes/components/EditFoldingProcessForm/EditFoldingProcessForm'

import AddCuttingTechnologyForm from '../components/AddCuttingTechnologyForm/AddCuttingTechnologyForm'
import AddSecondaryProcessForm from '../components/AddSecondaryProcessForm/AddSecondaryProcessForm'
import { SecondaryProcessesTable } from '../components/SecondaryProcessesTable/SecondaryProcessesTable'

const classes = {
    centeredContent: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    updateButton: {
        mx: 1,
        my: 2,
    },
    inputsContainer: {
        marginTop: 2,
        width: 440,
    },
    selectInput: {
        width: 140,
    },
    textInput: {
        width: 130,
        paddingRight: 3,
    },
    setupTimeInput: {
        width: 107,
        paddingRight: 3,
    },
    alignEnd: {
        textAlign: 'right',
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        boxSizing: 'border-box',
    },
    errorLabel: {
        color: 'error.main',
    },
    card: {
        flexDirection: 'column',
        gap: '24px',
        width: '486px',
        background: 'background.paper',
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '8px',
        boxShadow: 'none',
        marginBottom: '24px',
    },

    titleSection: {
        display: 'flex',
        marginBottom: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    techListGroup: {
        mb: 2,
    },

    techList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        padding: '0px',
        gap: '24px',
        flexWrap: 'wrap',
    },
}

const Process = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const isOnTrial = useSelector(selectIsOnTrial)
    const resolvedPlanMetadata = useSelector(selectResolvedPlanMetadata)

    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })

    const [updateOrganisation] = useUpdateOrganisationMutation()
    const [updateCuttingTechnology] = useUpdateCuttingTechnologyMutation()

    const [isLoading, setIsLoading] = useState(false)

    const [showAddCuttingTechDrawer, setShowAddCuttingTechDrawer] = useState(false)
    const [showAddSecondaryProcessDrawer, setShowAddSecondaryProcessDrawer] = useState(false)
    const [currentCuttingTechID, setCurrentCuttingTechID] = useState()
    const [currentSecondaryProcess, setCurrentSecondaryProcess] = useState()
    const [showFoldingSettingsDrawer, setShowFoldingSettingsDrawer] = useState(false)
    const [showArchivedCuttingTechs, setShowArchivedCuttingTechs] = useState(false)
    const [canAddCuttingTech, setCanAddCuttingTech] = useState(false)
    const [canEditFirstCuttingTech, setCanEditFirstCuttingTech] = useState(false)

    const { showCuttingTechManagement, showFolding, showSecondaryProcesses } = useToolBoxTreatments()

    const selectAndEditMaterialCuttingTech = (cuttingTechID) => () => {
        setCurrentCuttingTechID(cuttingTechID)
        handleAddCuttingTechDrawerOpen()
    }

    const selectAndEditSecondaryProcess = (secondaryProcess) => {
        setCurrentSecondaryProcess(secondaryProcess)
        handleSecondaryProcessDrawerOpen()
    }

    const handleAddCuttingTechDrawerOpen = () => {
        setShowAddCuttingTechDrawer(true)
    }

    const handleSecondaryProcessDrawerOpen = () => {
        setShowAddSecondaryProcessDrawer(true)
    }

    const handleAddCuttingTechDrawerSaveClose = async () => {
        setCurrentCuttingTechID(undefined)
        setShowAddCuttingTechDrawer(false)
    }

    const handleSecondaryProcessDrawerSaveClose = async () => {
        setShowAddSecondaryProcessDrawer(false)
        setCurrentSecondaryProcess(undefined)
    }

    const handleAddCuttingTechDrawerClose = () => {
        setCurrentCuttingTechID(undefined)
        setShowAddCuttingTechDrawer(false)
    }

    const handleSecondaryProcessDrawerClose = () => {
        setCurrentSecondaryProcess(undefined)
        setShowAddSecondaryProcessDrawer(false)
    }

    const handleFoldingDrawerOpen = () => {
        setShowFoldingSettingsDrawer(true)
    }

    const handleFoldingDrawerSave = () => {
        handleFoldingDrawerClose()
    }

    const handleFoldingDrawerClose = () => {
        setShowFoldingSettingsDrawer(false)
    }

    const handleUpdateCuttingTechAttribute = async (payload) => {
        setIsLoading(true)
        try {
            await updateCuttingTechnology({ organisationId, cuttingTechnology: payload })

            enqueueSnackbar(t('Cutting technology updated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('An error occurred updating your cutting technology'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleShowArchivedCuttingTechsChange = () => {
        setShowArchivedCuttingTechs(!showArchivedCuttingTechs)
    }

    const cuttingTechs = useMemo(() => {
        let cuttingTechs
        if (cuttingTechnologies) {
            cuttingTechs = Object.values(cuttingTechnologies)
        }
        if (!showArchivedCuttingTechs) {
            cuttingTechs = cuttingTechs?.filter((cuttingTech) => cuttingTech.isDeleted === false)
        }

        const groupedCuttingTechs = {}
        cuttingTechs?.forEach((cuttingTech) => {
            if (!groupedCuttingTechs[cuttingTech.type]) {
                groupedCuttingTechs[cuttingTech.type] = []
            }
            groupedCuttingTechs[cuttingTech.type].push(cuttingTech)
        })
        Object.keys(groupedCuttingTechs).forEach((key) => {
            groupedCuttingTechs[key].sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
        })

        return groupedCuttingTechs
    }, [cuttingTechnologies, showArchivedCuttingTechs])

    useEffect(() => {
        setCanAddCuttingTech(paidFeatures?.hasAdvancedMaterials)
        setCanEditFirstCuttingTech(resolvedPlanMetadata?.tube)
    }, [isOnTrial, paidFeatures, resolvedPlanMetadata])

    // LEGACY MATERIALS
    const getDefaultValues = useMemo(
        () => ({
            setupMode: organisation?.setupMode,
            sheetChangeMode: organisation?.sheetChangeMode,
            hourlyRate: organisation?.hourlyRate,
            setupTimeSeconds: organisation?.setupTimeSeconds,
            sheetChangeTimeSeconds: organisation?.sheetChangeTimeSeconds,
            sheetMarkupPercentage: fractionToPercentage(organisation?.sheetMarkupPercentage),
            foldingSettings: {
                foldingSettingsId: organisation?.foldingSettings?.foldingSettingsId,
                maximumPressTonnage: organisation?.foldingSettings?.maximumPressTonnage,
                maximumPressWidth: organisation?.foldingSettings?.maximumPressWidth,
                baseHourlyRate: organisation?.foldingSettings?.baseHourlyRate,
                setupMode: organisation?.foldingSettings?.setupMode,
                addedLabourHourlyRate: organisation?.foldingSettings?.addedLabourHourlyRate,
                productionOverride: fractionToPercentage(organisation?.foldingSettings?.productionOverride),
                setupOverride: fractionToPercentage(organisation?.foldingSettings?.setupOverride),
            },
        }),
        [organisation]
    )

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: getDefaultValues,
    })

    const handleResetClick = () => {
        reset(getDefaultValues)
    }

    const handleUpdateClick = async (settingsState) => {
        setIsLoading(true)

        const foldingSettings = { ...settingsState.foldingSettings }
        foldingSettings.productionOverride = percentageToFraction(settingsState.foldingSettings.productionOverride)
        foldingSettings.setupOverride = percentageToFraction(settingsState.foldingSettings.setupOverride)
        foldingSettings.organisationId = organisationId

        try {
            const response = await updateOrganisation({
                organisationDto: {
                    organisationId: organisationId,
                    hourlyRate: settingsState.hourlyRate,
                    setupTimeSeconds: settingsState.setupTimeSeconds,
                    sheetChangeTimeSeconds: settingsState.sheetChangeTimeSeconds,
                    setupMode: settingsState.setupMode,
                    sheetChangeMode: settingsState.sheetChangeMode,
                    foldingSettings: foldingSettings,
                    sheetMarkupPercentage: percentageToFraction(settingsState.sheetMarkupPercentage),
                },
            }).unwrap()

            dispatch(setOrganisation(response))

            enqueueSnackbar(t('Tooling settings updated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('An error occurred updating your tooling settings'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(handleResetClick, [organisation])

    // END LEGACY MATERIALS

    const renderCuttingTechs = () => {
        const enableFirstRotaryCuttingTech = canEditFirstCuttingTech && !canAddCuttingTech
        let enabledCuttingTech
        if (enableFirstRotaryCuttingTech) {
            enabledCuttingTech = cuttingTechs?.Rotary?.find((ct) => ct.type === 'Rotary')
            // if the first rotary cutting tech is default, find the first flat cutting tech instead
            if (enabledCuttingTech?.isDefault) {
                enabledCuttingTech = cuttingTechs?.Flat?.find((ct) => ct.type === 'Flat')
            }
        }

        const cuttingTechGroups = Object.keys(cuttingTechs)
        cuttingTechGroups.sort((a, b) => a.localeCompare(b))

        cuttingTechGroups.forEach((cuttingTechGroup) => {
            const cuttingTechGroupArray = cuttingTechs[cuttingTechGroup]
            const defaultCuttingTechIndex = cuttingTechGroupArray.findIndex((ct) => ct.isDefault)
            if (defaultCuttingTechIndex > 0) {
                const defaultCuttingTech = cuttingTechGroupArray[defaultCuttingTechIndex]
                cuttingTechGroupArray.splice(defaultCuttingTechIndex, 1)
                cuttingTechGroupArray.unshift(defaultCuttingTech)
            }
        })

        return (
            <Box>
                {cuttingTechGroups.length ? (
                    cuttingTechGroups?.map((cuttingTechGroup) => {
                        return (
                            <Box
                                key={cuttingTechGroup}
                                sx={classes.techListGroup}
                            >
                                <Typography
                                    data-testid="cutting-tech-group-heading"
                                    mb={1}
                                    variant="h6"
                                >
                                    {t(cuttingTechGroup)}
                                </Typography>
                                <Box sx={classes.techList}>
                                    {cuttingTechs[cuttingTechGroup]?.map((cuttingTech) => {
                                        return (
                                            <TbxTechCard
                                                canAddCuttingTech={
                                                    enableFirstRotaryCuttingTech
                                                        ? enabledCuttingTech?.cuttingTechnologyId ===
                                                          cuttingTech.cuttingTechnologyId
                                                        : canAddCuttingTech
                                                }
                                                cuttingTech={cuttingTech}
                                                key={cuttingTech.cuttingTechnologyId}
                                                onEdit={selectAndEditMaterialCuttingTech}
                                                onToggleAttribute={handleUpdateCuttingTechAttribute}
                                            />
                                        )
                                    })}
                                </Box>
                            </Box>
                        )
                    })
                ) : (
                    <Typography
                        data-testid="no-cutting-technologies"
                        style={{ marginBottom: '24px' }}
                        variant="body1"
                    >
                        {t('$t(No cutting technologies found)')}
                    </Typography>
                )}
            </Box>
        )
    }

    return (
        <>
            <ProcessesToolbar
                canAddCuttingTech={canAddCuttingTech}
                handleAddCuttingTechClick={handleAddCuttingTechDrawerOpen}
                handleAddSecondaryProcessClick={handleSecondaryProcessDrawerOpen}
                handleCancelUpdateClick={handleResetClick}
                handleUpdateProcessesClick={handleSubmit(handleUpdateClick)}
                isLoading={isLoading}
                isUpdateButtonDisabled={!isDirty || !isValid}
            />
            <RoleRequiredContainer role="administrator">
                <Box sx={classes.container}>
                    {!organisation ? (
                        <Box sx={classes.centeredContent}>
                            <Typography
                                color="error.main"
                                variant="body1"
                            >
                                {t('$t(An error occurred) loading your process settings')}
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {showCuttingTechManagement ? (
                                <div style={{ marginBottom: '24px' }}>
                                    <Box sx={classes.titleSection}>
                                        <Typography
                                            data-testid="cutting-technologies-heading"
                                            variant="h5"
                                        >
                                            {t('Cutting technologies')}
                                        </Typography>
                                        <TbxShowToggle
                                            checked={showArchivedCuttingTechs}
                                            data-testid="show-archived-techs-toggle"
                                            label={t('Show archived technologies')}
                                            onChange={handleShowArchivedCuttingTechsChange}
                                        />
                                    </Box>
                                    {renderCuttingTechs()}
                                </div>
                            ) : null}
                            {showFolding ? (
                                <div style={{ marginBottom: '24px' }}>
                                    <Typography
                                        data-testid="secondary-processes-heading"
                                        style={{ marginBottom: '12px' }}
                                        variant="h5"
                                    >
                                        {t('Secondary processes')}
                                    </Typography>
                                    <Typography
                                        data-testid="folding-heading"
                                        style={{ marginBottom: '12px' }}
                                        variant="h6"
                                    >
                                        {t('Folding')}
                                    </Typography>
                                    <TbxFoldingCard onEdit={handleFoldingDrawerOpen} />
                                </div>
                            ) : null}

                            {showSecondaryProcesses ? (
                                <SecondaryProcessesTable onEditProcess={selectAndEditSecondaryProcess} />
                            ) : null}
                        </>
                    )}
                </Box>

                {showCuttingTechManagement ? (
                    <>
                        <TbxDrawer
                            showDrawer={showAddCuttingTechDrawer}
                            onClose={handleAddCuttingTechDrawerClose}
                        >
                            <AddCuttingTechnologyForm
                                cuttingTechnology={
                                    (currentCuttingTechID && cuttingTechnologies[currentCuttingTechID]) || null
                                }
                                onCancel={handleAddCuttingTechDrawerClose}
                                onSave={handleAddCuttingTechDrawerSaveClose}
                            />
                        </TbxDrawer>

                        <TbxDrawer
                            showDrawer={showFoldingSettingsDrawer}
                            onClose={handleFoldingDrawerClose}
                        >
                            <EditFoldingProcessForm
                                foldingSettings={organisation?.foldingSettings}
                                onCancel={handleFoldingDrawerClose}
                                onSave={handleFoldingDrawerSave}
                            />
                        </TbxDrawer>
                    </>
                ) : null}

                <TbxDrawer
                    showDrawer={showAddSecondaryProcessDrawer}
                    onClose={handleSecondaryProcessDrawerClose}
                >
                    <AddSecondaryProcessForm
                        secondaryProcess={currentSecondaryProcess || null}
                        onCancel={handleSecondaryProcessDrawerClose}
                        onSave={handleSecondaryProcessDrawerSaveClose}
                    />
                </TbxDrawer>
            </RoleRequiredContainer>
        </>
    )
}

export default Process
