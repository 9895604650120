import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PostAdd, SaveAlt } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetMaterialQuery } from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { useLazyExportSheetsQuery } from '@/app/services/sheets'
import { selectUserRole } from '@/app/slices/appSlice'
import { selectCurrencySymbol, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import {
    clearSelectedSheets,
    selectSelectedSheet,
    selectSelectedSheets,
    setSelectedSheet,
    setSelectedSheets,
} from '@/app/slices/sheetsSlice'
import { TbxDrawer } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    browserFileDownload,
    currentDateTimeString,
    PARTS_PER_PAGE_DEFAULT_VALUE,
    PARTS_PER_PAGE_OPTIONS,
    PARTS_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AddSheet from './AddSheet'
import ImportSheetsRecords from './ImportSheetsRecords'
import SheetsBulkEdit from './SheetsBulkEdit'
import SheetsTableRow from './SheetsTableRow'

const classes = {
    table: {
        background: (theme) => theme.palette.background.paper,
        tableLayout: 'fixed',
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderBottom: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0 0',
        borderCollapse: 'separate',
    },

    headerTableCell: {
        padding: '5px',
        verticalAlign: 'bottom',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        background: (theme) => theme.palette.background.paper,
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderTop: 'none',
        boxSizing: 'border-box',
        borderRadius: '0px 0px 8px 8px',
    },
    columnsTitles: {
        '& th': {
            paddingBottom: 0,
            borderBottom: 'none',
        },
    },
    columnsUnits: {
        '& th': {
            paddingTop: 0,
        },
    },
    headerTitle: {
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },
    headerSubtitle: {
        letterSpacing: '0.17px',
        color: 'text.secondary',
    },

    headerSubtitle__cuttingTechnology: {
        fontWeight: 500,
    },
    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
    tableContainer: {
        paddingBottom: '40px',
    },

    tablePaginationCaption: {
        fontSize: '12px',
        color: '#5E7387',
    },
    importButton: {
        margin: '0 16px',
    },
}

const SheetsTable = ({ selectedMaterialId, showArchivedSheets }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const [readOnly, setReadOnly] = useState(true)
    const userRole = useSelector(selectUserRole)

    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const [exportSheets] = useLazyExportSheetsQuery()

    const { data: selectedMaterial } = useGetMaterialQuery({ organisationId, materialId: selectedMaterialId })
    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables } = useGetRateTablesQuery({ organisationId })

    const sheets = useMemo(() => {
        if (!selectedMaterial?.sheets) return []
        return selectedMaterial.sheets
    }, [selectedMaterial])

    const selectedSheets = useSelector(selectSelectedSheets)
    const selectedSheet = useSelector(selectSelectedSheet)

    const [showAddSheetDrawer, setShowAddSheetDrawer] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        PARTS_PER_PAGE_VARIABLE,
        PARTS_PER_PAGE_DEFAULT_VALUE
    )

    const { showCsvImportExportButtons, showPricing } = useToolBoxTreatments()

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const getFooterStyle = () => {
        return {
            bottom: 0,
            width: '100%',
            zIndex: 1200,
            opacity: 1,
        }
    }

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [rows, setRows] = useState([])

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = useCallback((a, b, orderBy) => {
        let first = a[orderBy]
        let next = b[orderBy]
        if (orderBy === 'size' || orderBy === '') {
            first = a.sheetWidth > a.sheetHeight ? a.sheetWidth : a.sheetHeight
            next = b.sheetWidth > b.sheetHeight ? b.sheetWidth : b.sheetHeight
        } else {
            first = a[orderBy]
            next = b[orderBy]
        }

        switch (typeof first) {
            case 'number':
                if (next < first) return -1
                if (next > first) return 1
                break
            case 'string':
                return next?.toString()?.localeCompare(first, undefined, { sensitivity: 'base' })
            case 'object':
                if (!first.$type || !first.$type.includes('width')) {
                    return 0
                }
                return descendingComparator(
                    { size: first.sheetHeight * first.sheetWidth },
                    { size: next.sheetHeight * next.sheetWidth },
                    orderBy
                )
            default:
                return 0
        }
        return 0
    }, [])

    const getComparator = useCallback(
        (order, orderBy) => {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        },
        [descendingComparator]
    )

    const createColumnHeader = (columnId, columnTitle, isSortable) => (
        <TableSortLabel
            active={isSortable ? orderBy === columnId : null}
            direction={isSortable && orderBy === columnId ? order : 'asc'}
            hideSortIcon={!isSortable}
            sx={classes.sortLabel}
            onClick={
                isSortable
                    ? () => {
                          handleRequestSort(columnId)
                      }
                    : undefined
            }
        >
            <Typography
                sx={classes.headerTitle}
                variant="body2"
            >
                {columnTitle}
            </Typography>
        </TableSortLabel>
    )

    const handleDrawerClose = () => {
        dispatch(setSelectedSheet(null))
        setShowAddSheetDrawer(false)
    }

    const handleImportSheetModalClose = () => {
        setShowImportModal(false)
    }

    const handleEditSheetClick = (sheet) => {
        dispatch(setSelectedSheet(sheet))
        setShowAddSheetDrawer(!showAddSheetDrawer)
    }

    const validCuttingTechnologies = useMemo(() => {
        if (
            !selectedMaterial?.rateTables ||
            !selectedMaterial?.rateTables.length ||
            !cuttingTechnologies ||
            !Object.keys(cuttingTechnologies).length ||
            !rateTables ||
            !Object.keys(rateTables).length
        ) {
            return []
        }
        const cuttingTechs = selectedMaterial?.rateTables
            .map((mrt) => cuttingTechnologies[rateTables[mrt.rateTableId]?.cuttingTechnologyId])
            .filter((ct) => !!ct)
        return cuttingTechs
    }, [cuttingTechnologies, rateTables, selectedMaterial])

    const handleCheckboxClick = (event) => {
        const { checked } = event.target
        if (checked) {
            dispatch(setSelectedSheets(rows))
        } else {
            dispatch(clearSelectedSheets())
        }
    }

    const allSheetsSelected = useMemo(() => {
        if (!rows || !rows.length || !selectedSheets || !selectedSheets.length) return false
        return selectedSheets.length === rows.length
    }, [rows, selectedSheets])

    const materialIs = useCallback(
        (materialType) => {
            return selectedMaterial?.type === materialType
        },
        [selectedMaterial]
    )

    const handleImportClick = () => {
        setShowImportModal(true)
    }
    const handleExportClick = async () => {
        try {
            const response = await exportSheets({
                organisationId,
                materialId: selectedMaterial?.materialId,
            }).unwrap()

            const filename = `${selectedMaterial?.type === 'Rotary' ? 'Rotary' : 'Flat'}-${selectedMaterial?.materialName}-Sheets-${currentDateTimeString()}.csv`

            browserFileDownload(response, filename, 'text/csv')

            enqueueSnackbar(t('Sheets exported successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to export sheets'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    useEffect(() => {
        setReadOnly(userRole !== 'administrator' && userRole !== 'owner')
    }, [userRole])

    useEffect(() => {
        if (sheets) {
            setRows(
                stableSort(
                    showArchivedSheets ? sheets : sheets?.filter((sheet) => !sheet.isDeleted),
                    getComparator(order, orderBy)
                )
            )
        }
    }, [order, orderBy, sheets, stableSort, getComparator, showArchivedSheets])

    return (
        <TableContainer sx={classes.tableContainer}>
            <Table sx={classes.table}>
                <TableHead>
                    <TableRow sx={classes.columnsTitles}>
                        <TableCell style={{ width: '48px' }}>&nbsp;</TableCell>

                        <TableCell
                            align="right"
                            data-testid="sheet-thickness-header"
                            style={{ width: '80px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('thickness', t('Thickness'), true)}
                        </TableCell>

                        {materialIs('Rotary') ? (
                            <TableCell
                                align="left"
                                data-testid="rotary-profile-header"
                                style={{ width: '100px' }}
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('profile', t('Profile'), true)}
                            </TableCell>
                        ) : null}

                        <TableCell
                            align="right"
                            data-testid="sheet-size-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('size', t('Size'), true)}
                        </TableCell>

                        {materialIs('Rotary') ? (
                            <TableCell
                                align="right"
                                data-testid="rotary-length-header"
                                style={{ width: '80px' }}
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('length', t('Length'), true)}
                            </TableCell>
                        ) : null}

                        {showPricing ? (
                            <TableCell
                                align="right"
                                data-testid="sheet-cost-header"
                                style={{ width: '80px' }}
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader(
                                    'sheetCost',
                                    materialIs('Rotary') ? t('Unit cost') : t('Sheet cost'),
                                    true
                                )}
                            </TableCell>
                        ) : null}

                        <TableCell
                            align="right"
                            data-testid="sheet-rate-header"
                            style={{ width: '80px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader(
                                'sheetRatePrice',
                                materialIs('Rotary') ? t('Unit rate') : t('Sheet rate'),
                                false
                            )}
                        </TableCell>

                        <TableCell
                            align="right"
                            data-testid="sheet-extra-markup-header"
                            style={{ width: '80px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('extraMarkupPercent', t('Extra markup'), false)}
                        </TableCell>

                        <TableCell
                            align="right"
                            data-testid="sheet-applied-markup-header"
                            style={{ width: '80px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('appliedMarkup', t('Applied markup'), false)}
                        </TableCell>

                        <TableCell
                            align="right"
                            data-testid="sheet-expiry-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('expiry', t('Expiry'), false)}
                        </TableCell>

                        <TableCell
                            align="right"
                            data-testid="sheet-last-used-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('lastUsed', t('Last used'), false)}
                        </TableCell>

                        {showPricing ? (
                            <TableCell
                                data-testid="sheet-consumption-mode-header"
                                style={{ width: '100px' }}
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('defaultUsage', t('Consumption mode'), false)}
                            </TableCell>
                        ) : null}

                        {validCuttingTechnologies.length ? (
                            <TableCell
                                colSpan={validCuttingTechnologies.length}
                                data-testid="cutting-technologies-header"
                                style={{
                                    width: `${40 * validCuttingTechnologies.length + 20}px`,
                                }}
                                sx={classes.headerTableCell}
                            >
                                <Typography
                                    sx={classes.headerTitle}
                                    variant="body2"
                                >
                                    {t('Cutting technologies')}
                                </Typography>
                            </TableCell>
                        ) : null}

                        <TableCell
                            align="right"
                            data-testid="sheet-actions-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        ></TableCell>
                    </TableRow>

                    <TableRow sx={classes.columnsUnits}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={allSheetsSelected}
                                onChange={handleCheckboxClick}
                            />
                        </TableCell>

                        <TableCell align="right">
                            <Typography
                                sx={classes.headerSubtitle}
                                variant="body2"
                            >
                                {useImperialUnits ? 'in' : 'mm'}
                            </Typography>
                        </TableCell>

                        {materialIs('Rotary') ? <TableCell>&nbsp;</TableCell> : null}

                        <TableCell align="right">
                            <Typography
                                sx={classes.headerSubtitle}
                                variant="body2"
                            >
                                {useImperialUnits ? 'in' : 'mm'}
                            </Typography>
                        </TableCell>

                        {materialIs('Rotary') ? (
                            <TableCell align="right">
                                <Typography
                                    sx={classes.headerSubtitle}
                                    variant="body2"
                                >
                                    {useImperialUnits ? 'in' : 'mm'}
                                </Typography>
                            </TableCell>
                        ) : null}

                        {showPricing ? (
                            <TableCell align="right">
                                <Typography
                                    sx={classes.headerSubtitle}
                                    variant="body2"
                                >
                                    {currencySymbol}
                                </Typography>
                            </TableCell>
                        ) : null}

                        <TableCell align="right">
                            <Typography
                                sx={classes.headerSubtitle}
                                variant="body2"
                            >
                                {`${currencySymbol}/${useImperialUnits ? 'lb' : 'kg'}`}
                            </Typography>
                        </TableCell>

                        <TableCell align="right">
                            <Typography
                                sx={classes.headerSubtitle}
                                variant="body2"
                            >
                                %
                            </Typography>
                        </TableCell>

                        <TableCell align="right">
                            <Typography
                                sx={classes.headerSubtitle}
                                variant="body2"
                            >
                                %
                            </Typography>
                        </TableCell>

                        <TableCell>&nbsp;</TableCell>

                        <TableCell>&nbsp;</TableCell>

                        {showPricing ? <TableCell>&nbsp;</TableCell> : null}

                        {validCuttingTechnologies.length
                            ? validCuttingTechnologies.map((ct) => (
                                  <TableCell
                                      data-testid={`cutting-technology-header-${ct.name}`}
                                      key={ct.cuttingTechnologyId}
                                      style={{ width: '40px' }}
                                  >
                                      <Typography
                                          color="textSecondary"
                                          sx={[classes.headerSubtitle, classes.headerSubtitle__cuttingTechnology]}
                                          variant="body2"
                                      >
                                          {t(ct.name)}
                                      </Typography>
                                  </TableCell>
                              ))
                            : null}

                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        ?.slice(currentPage * currentRowsPerPage, currentPage * currentRowsPerPage + currentRowsPerPage)
                        .map((sheet) => (
                            <SheetsTableRow
                                key={sheet.sheetId}
                                selectedMaterial={selectedMaterial}
                                sheet={sheet}
                                validCuttingTechnologies={validCuttingTechnologies}
                                onEditClick={handleEditSheetClick}
                            />
                        ))}
                </TableBody>
            </Table>
            <Box sx={classes.tableFooter}>
                {showCsvImportExportButtons ? (
                    <>
                        <Button
                            color="secondary"
                            data-testid="import-button"
                            size="small"
                            startIcon={<PostAdd />}
                            sx={classes.importButton}
                            variant="outlined"
                            onClick={handleImportClick}
                        >
                            {t('Import')}
                        </Button>
                        <Button
                            color="secondary"
                            data-testid="export-button"
                            size="small"
                            startIcon={<SaveAlt />}
                            variant="outlined"
                            onClick={handleExportClick}
                        >
                            {t('Export')}
                        </Button>
                    </>
                ) : null}

                <TablePagination
                    classes={{
                        caption: classes.tablePaginationCaption,
                    }}
                    component="div"
                    count={showArchivedSheets ? sheets.length : sheets?.filter((sheet) => !sheet.isDeleted).length}
                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                    labelRowsPerPage={t('Sheets per page')}
                    page={currentPage}
                    rowsPerPage={currentRowsPerPage}
                    rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                    style={getFooterStyle()}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <TbxDrawer
                showDrawer={showAddSheetDrawer}
                onClose={handleDrawerClose}
            >
                <AddSheet
                    selectedMaterialId={selectedMaterialId}
                    sheet={selectedSheet}
                    onCancel={handleDrawerClose}
                    onClose={handleDrawerClose}
                />
            </TbxDrawer>
            <SheetsBulkEdit selectedMaterial={selectedMaterial} />
            {showImportModal ? <ImportSheetsRecords onClose={handleImportSheetModalClose} /> : null}
        </TableContainer>
    )
}

SheetsTable.propTypes = {
    selectedMaterialId: PropTypes.string,
    showArchivedSheets: PropTypes.bool,
}

export default SheetsTable
