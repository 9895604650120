import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Delete, DragHandle, ExpandMore } from '@mui/icons-material'
import { AccordionSummary, Box, Checkbox, CircularProgress, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useDeleteMiscItemMutation, useGetMiscItemsQuery } from '@/app/services/miscItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import {
    addSelectedMiscItem,
    removeExpandedMiscItem,
    removeSelectedMiscItem,
    selectExpandedMiscItems,
    selectSelectedMiscItems,
} from '@/app/slices/miscItemsSlice'
import { selectOrganisation, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { selectedQuoteStatus, selectIsCalculatingQuote, setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import useNumberFormatter from '@/common/hooks/useNumberFormatter'
import MiscItemIcon from '@/common/icons/MiscItemIcon/MiscItemIcon'
import { QUOTE_STATUS } from '@/common/utils'
import TbxDialog from '@/features/web-store/components/shared/TbxDialog'
import { formatCurrency } from '@/features/web-store/helpers/utilities'

import MiscItemName from './MiscItemName'

const classes = {
    summaryRoot: {
        height: 62,
        backgroundColor: 'accordionSummary.main',
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            margin: '12px 0',
            '&.Mui-expanded': {
                margin: '12px 0',
            },
        },
    },
    AccordionSummaryLeft: {
        gap: 1.5,
    },

    AccordionSummaryRight: {
        gap: 6,
    },

    summaryThumbnailWrapper: {
        height: '50px',
        width: '50px',
    },

    summaryThumbnailImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },

    miscItemSummaryCollapsed: {
        gap: 5,
    },
    miscItemSummaryCollapsed_LinePrice: {
        width: '70px',
    },
    smallText: {
        fontSize: '0.75rem',
    },

    iconColor: { color: 'text.secondary' },
}

const MiscItemSummary = ({ listeners, miscItemId, setActivatorNodeRef }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const organisationSettings = useSelector(selectOrganisation)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const hidePrices = useSelector(selectedQuoteStatus) === QUOTE_STATUS.NotCalculated

    const selectedMiscItemsArray = useSelector(selectSelectedMiscItems)
    const expandedMiscItemsArray = useSelector(selectExpandedMiscItems)
    const isCalculating = useSelector(selectIsCalculatingQuote)

    const { n } = useNumberFormatter()

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const formatNumberWithX = (str) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) => n(Number(part)))
        return formattedParts.join(' x ')
    }

    const { data: selectedQuote, isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { miscItem } = useGetMiscItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                miscItem: data?.find((item) => item.id === miscItemId),
            }),
        }
    )

    const [deleteMiscItem, { isLoading: isDeleting }] = useDeleteMiscItemMutation()
    const [updateQuote] = useUpdateQuoteMutation()

    const [isSelected, setIsSelected] = useState(false)
    const [isExpanded, setIsExpanded] = useState(true)

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const unit = useImperialUnits ? 'in' : 'mm'
    const weightUnits = useImperialUnits ? 'lb' : 'kg'

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDeleteDialogOpen = (event) => {
        event.stopPropagation()
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const handleRemoveQuoteItem = async () => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        }
        try {
            await deleteMiscItem({
                organisationId,
                quoteId,
                miscItemId: miscItem?.id,
            }).unwrap()
            dispatch(removeExpandedMiscItem(miscItem?.id))
            enqueueSnackbar(`${miscItem?.name} ${t('deleted')}`, {
                variant: 'success',
            })
            setIsDeleteDialogOpen(false)

            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        } catch (error) {
            enqueueSnackbar(`${t('Failed to delete')} ${miscItem?.name} from quote`, {
                variant: 'error',
            })
            setIsDeleteDialogOpen(false)
        }
    }

    const handleSelectionChange = () => {
        setIsSelected(!isSelected)
        if (!isSelected) {
            dispatch(addSelectedMiscItem(miscItem?.id))
        } else {
            dispatch(removeSelectedMiscItem(miscItem?.id))
        }
    }

    useEffect(() => {
        if (selectedMiscItemsArray.includes(miscItem?.id)) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [selectedMiscItemsArray, miscItem?.id])

    useEffect(() => {
        setIsExpanded(expandedMiscItemsArray.includes(miscItem?.id))
    }, [expandedMiscItemsArray, miscItem?.id])

    return (
        <>
            <AccordionSummary
                aria-controls={`panel${miscItem?.id}-content`}
                aria-label="Expand"
                expandIcon={<ExpandMore />}
                id={`panel${miscItem?.id}-header`}
                sx={classes.summaryRoot}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryLeft}
                >
                    <Typography variant="body1">{miscItem?.index + 1}</Typography>

                    <IconButton
                        aria-label="Drag"
                        color="secondary"
                        size="small"
                        {...listeners}
                        ref={setActivatorNodeRef}
                        sx={{ cursor: 'grab' }}
                    >
                        <DragHandle />
                    </IconButton>

                    <Checkbox
                        checked={isSelected}
                        color="primary"
                        inputProps={{
                            'data-testid': 'select-misc-item',
                        }}
                        size="small"
                        sx={{ p: 0 }}
                        onChange={handleSelectionChange}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Box
                        alignItems="center"
                        display="flex"
                        gap={2}
                    >
                        {!isExpanded ? <MiscItemIcon titleAccess={t('Miscellaneous item icon')} /> : null}

                        <MiscItemName
                            id={miscItem?.id}
                            name={miscItem?.name}
                        />
                    </Box>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryRight}
                >
                    {isCalculating || isFetchingQuote ? (
                        <Box
                            alignItems="center"
                            data-testid="calculating-header"
                            display="flex"
                            justifyContent="center"
                        >
                            <Typography
                                color="secondary"
                                letterSpacing={1}
                                mr={2}
                                textTransform={'uppercase'}
                                variant="strong2"
                            >
                                {isCalculating ? t('Calculating') : t('Loading')}
                            </Typography>
                            <CircularProgress
                                color="secondary"
                                size={24}
                            />
                        </Box>
                    ) : null}

                    {!isExpanded && !isCalculating && !isFetchingQuote ? (
                        <Box
                            display="flex"
                            sx={classes.miscItemSummaryCollapsed}
                        >
                            {miscItem?.dimensions ? (
                                <Box
                                    alignItems="flex-end"
                                    className="miscItemSummaryCollapsed_Quantity"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {t('Dimensions')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatNumberWithX(miscItem?.dimensions)} {unit}
                                    </Typography>
                                </Box>
                            ) : null}

                            {miscItem?.weight ? (
                                <Box
                                    alignItems="flex-end"
                                    className="miscItemSummaryCollapsed_Quantity"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {t('Weight')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {n(miscItem?.weight)} {weightUnits}
                                    </Typography>
                                </Box>
                            ) : null}

                            {miscItem?.productionTimePerItemSeconds ? (
                                <Box
                                    alignItems="flex-end"
                                    className="miscItemSummaryCollapsed_Quantity"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {t('Time')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {n(miscItem?.productionTimePerItemSeconds)} {t('s')}
                                    </Typography>
                                </Box>
                            ) : null}

                            <Box
                                alignItems="flex-end"
                                className="miscItemSummaryCollapsed_Quantity"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Quantity')}
                                </Typography>
                                <Typography variant="body2">x {q(miscItem?.quantity)}</Typography>
                            </Box>
                            <Box
                                alignItems="flex-end"
                                className="miscItemSummaryCollapsed_UnitPrice"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Unit price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!hidePrices && !isCalculating ? (
                                    <Typography variant="body2">
                                        {miscItem?.itemPrice
                                            ? formatCurrency(
                                                  miscItem?.itemPrice,
                                                  organisationSettings.currencyCode,
                                                  organisationSettings.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {String.fromCharCode(8212)}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                alignItems="flex-end"
                                display="flex"
                                flexDirection="column"
                                sx={classes.miscItemSummaryCollapsed_LinePrice}
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Line price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!hidePrices && !isCalculating ? (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {miscItem?.linePrice
                                            ? formatCurrency(
                                                  miscItem?.linePrice,
                                                  organisationSettings.currencyCode,
                                                  organisationSettings.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {String.fromCharCode(8212)}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ) : null}

                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <IconButton
                            aria-label="delete"
                            color="secondary"
                            data-testid="delete-quote-item-name"
                            onClick={(event) => handleDeleteDialogOpen(event)}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            </AccordionSummary>
            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Yes, delete')}
                content={`${t('Are you sure you want to delete part')} ${miscItem?.name}`}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleRemoveQuoteItem}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={miscItemId}
                title={`${t('Delete part')} ${miscItem?.name}?`}
            />
        </>
    )
}

MiscItemSummary.propTypes = {
    listeners: PropTypes.object,
    miscItemId: PropTypes.string,
    setActivatorNodeRef: PropTypes.func,
}

export default MiscItemSummary
