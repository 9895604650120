import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AttachMoney, DeleteForeverRounded, Edit, TimerOutlined, UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useDeleteQuoteItemsMutation, useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectDisplayQuotePricing, setDisplayQuotePricing } from '@/app/slices/appSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { QUOTE_STATUS } from '@/common/utils'
import TbxDialog from '@/features/web-store/components/shared/TbxDialog'

import {
    clearCollapsedItems,
    clearExpandedItems,
    clearSelectedItems,
    selectSelectedItems,
    setCollapsedItemsIds,
    setSelectedItemsIds,
    setSelectedQuoteStatus,
} from '../../../../app/slices/quoteItemsSlice'

import QuoteItemsBulkEdit from './BulkEdit/QuoteItemsBulkEdit'
import QuoteItemListFilter from './QuoteItemListFilter'
import QuoteItemListSort from './QuoteItemListSort'

const QuoteItemListActions = ({ isLoadingItems }) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const displayPricing = useSelector(selectDisplayQuotePricing)
    const selectedItemsArray = useSelector(selectSelectedItems)
    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { data: quoteItems } = useGetQuoteItemsQuery({ organisationId, quoteId })

    const [deleteQuoteItems, { isLoading: isDeleting }] = useDeleteQuoteItemsMutation()

    const [updateQuote] = useUpdateQuoteMutation()

    const [allCollapsed, setAllCollapsed] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

    const quoteItemsIds = useMemo(() => quoteItems?.map((item) => item.id), [quoteItems])

    const handleDeleteDialogOpen = () => {
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const collapseAll = () => {
        dispatch(setCollapsedItemsIds(quoteItemsIds))
        setAllCollapsed(true)
    }

    const expandAll = () => {
        dispatch(clearCollapsedItems())
        setAllCollapsed(false)
    }

    const handleSelectAllItems = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            dispatch(setSelectedItemsIds(quoteItemsIds))
        } else {
            dispatch(clearSelectedItems())
        }
    }

    const handleToggleDisplayPricing = (value) => () => {
        if (value !== displayPricing) {
            dispatch(setDisplayQuotePricing(value))
        }
    }

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDeleteItems = async () => {
        const quoteItemsCount = selectedItemsArray.length

        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        }

        try {
            await deleteQuoteItems({
                organisationId,
                quoteId: quoteId,
                quoteItemsIds: selectedItemsArray,
            }).unwrap()
            dispatch(clearSelectedItems())
            dispatch(clearExpandedItems())

            enqueueSnackbar(t(`${quoteItemsCount} parts deleted`), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t(`Failed to delete ${quoteItemsCount} $t(quote) items`), {
                variant: 'error',
            })
        } finally {
            setIsDeleteDialogOpen(false)
        }
    }

    const handleEditButtonClick = () => {
        setIsEditDialogOpen(true)
    }

    const handleEditDialogClose = () => {
        setIsEditDialogOpen(false)
    }

    const selectionHasPartsFromLibrary = useMemo(() => {
        return selectedItemsArray.some((id) => {
            const item = quoteItems.find((item) => item.id === id)
            return item?.partLibraryEntryId
        })
    }, [quoteItems, selectedItemsArray])

    const itemsSelectedLabel = useMemo(() => {
        const itemsSelectedCount = selectedItemsArray.length
        return quoteItemsIds?.length === selectedItemsArray.length
            ? 'All items selected'
            : selectedItemsArray.length === 0
              ? 'Select all'
              : `${itemsSelectedCount} ${itemsSelectedCount > 1 ? 'items' : 'item'} selected`
    }, [selectedItemsArray, quoteItemsIds])

    useEffect(() => {
        if (!quoteItemsIds?.length) {
            if (selectAll !== false) {
                setSelectAll(false)
            }
        } else {
            const allSelected = quoteItemsIds.every((id) => selectedItemsArray.includes(id))
            if (selectAll !== allSelected) {
                setSelectAll(allSelected)
            }
        }
    }, [quoteItemsIds, selectedItemsArray, selectAll])

    useEffect(() => {
        // Clean up selected items when the component is unmounted
        return () => {
            dispatch(clearSelectedItems())
            dispatch(clearCollapsedItems())
        }
    }, [])

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={3}
                justifyContent="space-between"
                mb={2}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                >
                    <FormControlLabel
                        aria-label="Select all part"
                        control={
                            <Checkbox
                                checked={selectAll}
                                color="secondary"
                                indeterminate={!selectAll ? selectedItemsArray.length > 0 : null}
                                inputProps={{
                                    'data-testid': 'select-all',
                                }}
                                size="small"
                                onChange={handleSelectAllItems}
                            />
                        }
                        data-testid="select-all-label"
                        label={t(itemsSelectedLabel)}
                        sx={{ ml: 0 }}
                    />

                    <TbxTooltip
                        title={
                            !selectedItemsArray.length
                                ? t('Select the parts to edit')
                                : selectionHasPartsFromLibrary
                                  ? t('You cannot edit parts from the library')
                                  : ''
                        }
                        arrow
                    >
                        <span>
                            <IconButton
                                aria-label="delete"
                                color="secondary"
                                disabled={!selectedItemsArray.length || selectionHasPartsFromLibrary}
                                size="small"
                                onClick={handleEditButtonClick}
                            >
                                <Edit />
                            </IconButton>
                        </span>
                    </TbxTooltip>

                    <TbxTooltip
                        title={!selectedItemsArray.length ? t('Select the parts to delete') : ''}
                        arrow
                    >
                        <span>
                            <IconButton
                                aria-label="delete"
                                color="secondary"
                                disabled={!selectedItemsArray.length}
                                size="small"
                                onClick={handleDeleteDialogOpen}
                            >
                                <DeleteForeverRounded />
                            </IconButton>
                        </span>
                    </TbxTooltip>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={2}
                >
                    <QuoteItemListSort />

                    <QuoteItemListFilter />

                    <ToggleButtonGroup
                        aria-label="text alignment"
                        color="secondary"
                        data-testid="prices-times-toggle"
                        size="small"
                        value={displayPricing}
                        exclusive
                    >
                        <ToggleButton
                            aria-label="Prices"
                            sx={{ color: 'text.secondary' }}
                            value={true}
                            onClick={handleToggleDisplayPricing(true)}
                        >
                            <AttachMoney />
                        </ToggleButton>
                        <ToggleButton
                            aria-label="Times"
                            sx={{ color: 'text.secondary' }}
                            value={false}
                            onClick={handleToggleDisplayPricing(false)}
                        >
                            <TimerOutlined />
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {allCollapsed ? (
                        <IconButton
                            aria-label="Expand all"
                            color="secondary"
                            size="large"
                            onClick={expandAll}
                        >
                            <UnfoldMore />
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-label="Collapse all"
                            color="secondary"
                            size="large"
                            onClick={collapseAll}
                        >
                            <UnfoldLess />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Proceed')}
                content={t(
                    `You are about to delete ${selectedItemsArray.length} ${
                        selectedItemsArray.length > 1 ? 'parts' : 'part'
                    } from $t(quote)`
                )}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleDeleteItems}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={quoteId}
                title={t(`Delete ${selectedItemsArray.length} ${selectedItemsArray.length > 1 ? 'parts' : 'part'}`)}
            />
            {isEditDialogOpen ? (
                <QuoteItemsBulkEdit
                    open={isEditDialogOpen}
                    onClose={handleEditDialogClose}
                />
            ) : null}
        </>
    )
}

QuoteItemListActions.propTypes = {
    isLoadingItems: PropTypes.bool,
}

export default QuoteItemListActions
