import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontFamily: theme.typography.inter,
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        padding: '6px 12px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
    },
}))

const TbxTooltip = ({
    arrow = true,
    children,
    disableFocusListener = false,
    disableTouchListener = false,
    followCursor = false,
    leaveDelay,
    title,
}) => {
    return (
        <StyledTooltip
            arrow={arrow}
            disableFocusListener={disableFocusListener}
            disableTouchListener={disableTouchListener}
            followCursor={followCursor}
            leaveDelay={leaveDelay}
            title={title}
        >
            {children}
        </StyledTooltip>
    )
}

TbxTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    arrow: PropTypes.bool,
    disableFocusListener: PropTypes.bool,
    disableTouchListener: PropTypes.bool,
    followCursor: PropTypes.bool,
    leaveDelay: PropTypes.number,
}

export default TbxTooltip
