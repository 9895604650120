import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import CurrencyInput from '@/common/components/CurrencyInput/CurrencyInput'
import { DELIVERY_PRICING_OPTIONS } from '@/common/utils'

import DeliveryContainers from './DeliveryContainers/DeliveryContainers'
import DeliveryPrices from './DeliveryPrices/DeliveryPrices'
import DeliveryZones from './DeliveryZones/DeliveryZones'

const SmartShippingCalculator = ({ form }) => {
    const { t } = useTranslation()

    const { control, getValues } = form

    const deliveryPricingOption = getValues('deliveryPricingOption')

    const isImperial = useSelector(selectUseImperialUnits)

    return (
        <Grid
            marginBlock={0}
            paddingInline={2}
            spacing={3}
            xs={12}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="smart-shipping-calculator"
                    id="smart-shipping-calculator"
                    mb={1}
                    variant="h6"
                >
                    {t('Smart shipping calculator')}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {t(
                        'The smart shipping calculator uses the customer’s delivery address, along with the weight and dimensions of the items in the order, to calculate a delivery price. This is based on a system of zones and containers that you define.'
                    )}
                </Typography>
            </Grid>

            <DeliveryZones />

            <DeliveryContainers />

            <Grid xs={12}>
                <Typography
                    data-testid="delivery-prices"
                    id="delivery-prices"
                    mb={1}
                    variant="strong1"
                >
                    {t('Delivery prices')}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {t(
                        'Manage delivery prices for your organisation by assigning relevant containers to each zone. Set a container price for each zone the container is assigned to.'
                    )}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Controller
                    control={control}
                    name="minimumDeliveryCharge"
                    render={({ field: { onBlur, onChange, ref, value } }) => (
                        <CurrencyInput
                            customPlaceholder="No minimum"
                            InputLabelProps={{
                                'data-testid': 'minimum-delivery-price-label',
                                shrink: true,
                            }}
                            inputProps={{
                                'data-testid': 'minimum-delivery-price-field',
                            }}
                            inputRef={ref}
                            label={t('Minimum delivery price')}
                            size="medium"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                />
            </Grid>

            <Grid xs={12}>
                <Typography
                    data-testid="delivery-pricing-option"
                    mb={2}
                    variant="body1"
                >
                    {t('Delivery pricing option')}
                </Typography>

                <Controller
                    control={control}
                    key="deliveryPricingOption"
                    name="deliveryPricingOption"
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup
                            data-testid="delivery-pricing-options-radio-group"
                            sx={{ marginBottom: 2 }}
                            value={value}
                            onChange={(e, value) => {
                                onChange(value)
                            }}
                        >
                            {Object.values(DELIVERY_PRICING_OPTIONS).map((option) => (
                                <>
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        data-testid={`delivery-pricing-option-${option.value}`}
                                        key={option.value}
                                        label={t(option.label(isImperial))}
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                color: 'text.secondary',
                                            },
                                        }}
                                        value={option.value}
                                    />
                                    <Typography
                                        color="text.secondary"
                                        mb={2}
                                        ml={4}
                                        mt={-1}
                                        variant="body2"
                                    >
                                        {t(option.helperText(isImperial))}
                                    </Typography>
                                </>
                            ))}
                        </RadioGroup>
                    )}
                />
            </Grid>

            <DeliveryPrices deliveryPricingOption={deliveryPricingOption} />
        </Grid>
    )
}

SmartShippingCalculator.propTypes = {
    form: PropTypes.object.isRequired,
}

export default SmartShippingCalculator
