import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material'
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    ThemeProvider,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    selectQuoteItemsForQuote,
    useAddQuoteItemsFromPartsMutation,
    useDeleteQuoteItemMutation,
    useGetQuoteItemDrawingQuery,
    useGetQuoteItemsQuery,
    useResetQuoteItemDrawingMutation,
    useUpdateQuoteItemDrawingMutation,
} from '@/app/services/quoteItems'
import { useCalculateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectQuoteItemDrawingId, setSelectedQuoteItemDrawingId } from '@/app/slices/quoteItemsSlice'
import { AlertDialog, LoadingOverlay, TbxTooltip } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks/useToolBoxTreatments'
import { theme as darkTheme } from '@/common/themes/DarkTheme'
import { StrikerThemeOverride } from '@/common/themes/overrides/StrikerThemeOverride'
import { WhitelabelThemeOverride } from '@/common/themes/overrides/WhitelabelThemeOverride'
import {
    quoteHasUnsetItems,
    quoteItemErrors,
    quoteItemHasIssues,
    quoteItemWarnings,
} from '@/common/utils/quoteUtilities'

import DrawingDoctorDrawer from '../DrawingDoctor/Components/DrawingDoctorDrawer/DrawingDoctorDrawer'
import ExtractPartDialog from '../DrawingDoctor/Components/ExtractPartDialog'
import DrawingDoctor from '../DrawingDoctor/DrawingDoctor'
import Drawing from '../DrawingDoctor/Entities/Drawing'
import ScaleDrawingModal from '../ScaleDrawingModal/ScaleDrawingModal'

import RenderDimensions from './RenderDimensions'

const classes = {
    dialogRoot: {
        overflow: 'hidden',
    },
    topBar: {
        borderBottom: '1px solid hsl(0, 0%, 35%)',
        boxSizing: 'border-box',
        height: 72,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'hsl(0, 0%, 15%)',
        gap: 2,
    },
    topBarTitle: {
        width: 400,
        py: 3,
        px: 1,
    },
    topBarActions: {
        justifyContent: 'space-between',
        flex: 1,
    },
    topBarInfo: {
        width: 260,
        p: 3,
        justifyContent: 'flex-end',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    dialogContent: (showCadView) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: showCadView ? 'hsl(0, 0%, 0%)' : 'hsl(0, 0%, 25%)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        p: 0,
    }),
    toggleButtonGroup: {
        backgroundColor: 'primary.main',
    },
    toggleButton: {
        color: 'primary.contrastText',
        padding: '8px 12px',
        '&.Mui-selected': {
            color: 'primary.contrastText',
        },
    },
    toggleButtonGroupSecondary: {
        backgroundColor: 'secondary.main',
    },

    toggleButtonSecondary: {
        color: 'secondary.contrastText',
        padding: '6px 12px',
        borderColor: 'secondary.main',
        '&.Mui-selected': {
            color: 'secondary.contrastText',
            backgroundColor: 'secondary.dark',
        },
    },
}

const DrawingDoctorModal = ({ onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams()
    const { quoteId } = params
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const quoteItemId = useSelector(selectQuoteItemDrawingId)
    const quoteItems = useSelector((state) => selectQuoteItemsForQuote(state, { organisationId, quoteId }))

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [uploadQuoteItems] = useAddQuoteItemsFromPartsMutation()
    const [deleteQuoteItem] = useDeleteQuoteItemMutation()
    const [updateQuoteItemDrawing] = useUpdateQuoteItemDrawingMutation()
    const [calculateQuoteMutation] = useCalculateQuoteMutation()
    const [resetDrawing] = useResetQuoteItemDrawingMutation()

    const { data: rawDrawing, isLoading: isLoadingDrawing } = useGetQuoteItemDrawingQuery({
        organisationId,
        quoteId,
        quoteItemId: quoteItemId,
    })

    const { showLibellulaWhitelabelling, showStrikerWhitelabelling } = useToolBoxTreatments()

    const [cadView, setCadView] = useState(null)
    const [showLegend, setShowLegend] = useState(false)
    const [showShortcuts, setShowShortcuts] = useState(false)
    const [showCadView, setShowCadView] = useState(false)
    const [showExtractPartDialog, setShowExtractPartDialog] = useState(false)
    const [showExtractAllPartsDialog, setShowExtractAllPartsDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [actionMenu, setActionMenu] = useState(null)
    const [selectedQuoteItemId, setSelectedQuoteItemId] = useState(quoteItemId)
    const [drawing, setDrawingState] = useState({})
    const [selectedPaths, setSelectedPaths] = useState({})
    const [isUpdatingDrawing, setIsUpdatingDrawing] = useState(false)
    const [resetDrawingDialog, setResetDrawingDialog] = useState(false)
    const [showScaleDrawingModal, setShowScaleDrawingModal] = useState(false)

    const numberOfSelectedPaths = Object.keys(selectedPaths).length

    const isPartFlat = quoteItem?.profile === undefined || quoteItem?.profile === null || quoteItem?.profile === ''
    const hasIssues = quoteItemHasIssues(quoteItem)
    const hasWarnings = quoteItemWarnings(quoteItem)
    const hasErrors = quoteItemErrors(quoteItem)
    const actionMenuOpen = Boolean(anchorEl)

    const canCalculateQuote = useMemo(() => {
        return !quoteHasUnsetItems(quoteItems)
    }, [quoteItems])

    const platform = window.navigator.platform.toLowerCase()
    const isMacOS = platform.indexOf('mac') !== -1

    const quoteItemIndex = useMemo(() => {
        return quoteItems.findIndex((item) => item.id === quoteItem?.id)
    }, [quoteItems, quoteItem])

    const isPartFromLibrary = useMemo(() => {
        return Boolean(quoteItem?.partLibraryEntryId)
    }, [quoteItem])

    const isSelectedPathsEmpty = useMemo(() => {
        return Object.keys(selectedPaths).length === 0
    }, [selectedPaths])

    const resolvedDarkTheme = useMemo(
        () =>
            showLibellulaWhitelabelling
                ? WhitelabelThemeOverride(darkTheme)
                : showStrikerWhitelabelling
                  ? StrikerThemeOverride(darkTheme)
                  : darkTheme,
        [showLibellulaWhitelabelling, showStrikerWhitelabelling, darkTheme]
    )

    const showPrevButton = quoteItemIndex > 0
    const showNextButton = quoteItemIndex < quoteItems.length - 1

    const drawingDimensions = useMemo(() => {
        if (!isSelectedPathsEmpty && numberOfSelectedPaths === 1) {
            const path = drawing.paths[Object.keys(selectedPaths)[0]]
            return {
                width: path.width,
                height: path.height,
            }
        }

        return {
            width: drawing?.width,
            height: drawing?.height,
        }
    }, [drawing, isSelectedPathsEmpty, numberOfSelectedPaths, selectedPaths])

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleUpdateQuoteItemDrawing = async (quoteItemDrawing) => {
        setIsUpdatingDrawing(true)
        try {
            const quoteItemId = quoteItem?.id
            quoteItemDrawing.paths = Object.values(quoteItemDrawing.paths)
            const drawing = { ...quoteItemDrawing }
            const result = await updateQuoteItemDrawing({
                organisationId,
                quoteId,
                quoteItemId,
                drawing,
            }).unwrap()

            if (canCalculateQuote) calculateQuote()
            enqueueSnackbar(t('Drawing updated'), { variant: 'success' })
            return result
        } catch (error) {
            enqueueSnackbar(t('Failed to update the drawing'), {
                variant: 'error',
            })
        } finally {
            setIsUpdatingDrawing(false)
        }
    }

    const handleViewChange = (event, newView) => setShowCadView(newView)

    const handleActionClick = (menu) => (event) => {
        setAnchorEl(event.currentTarget)
        setActionMenu(menu)
    }
    const handleActionMenuClose = () => {
        setAnchorEl(null)
        setActionMenu(null)
    }

    const toggleShowLegend = () => {
        setShowLegend((showLegend) => !showLegend)
        handleActionMenuClose()
    }
    const toggleShowShortcuts = () => {
        setShowShortcuts((showShortcuts) => !showShortcuts)
        handleActionMenuClose()
    }

    // Removing error paths
    const handleRemoveNonSolidLinesClick = () => {
        const updatedDrawing = new Drawing(drawing)

        for (const k in updatedDrawing.paths) {
            if (!updatedDrawing.paths[k].isSolid) {
                updatedDrawing.deletePath(k)
            }
        }

        //setDrawingState(updatedDrawing)
        handleUpdateQuoteItemDrawing(updatedDrawing)
    }

    const handleRemoveOpenInnersClick = () => {
        const updatedDrawing = new Drawing(drawing)

        for (const k in updatedDrawing.paths) {
            if (!updatedDrawing.paths[k].isClosed && updatedDrawing.paths[k].layer?.type !== 'Folding') {
                updatedDrawing.deletePath(k)
            }
        }

        //setDrawingState(updatedDrawing)
        handleUpdateQuoteItemDrawing(updatedDrawing)
    }

    // Extracting paths
    const handleExtractSelectedClick = () => {
        setShowExtractPartDialog(true)
    }

    const handleExtractAllPartsClick = () => {
        setShowExtractAllPartsDialog(true)
    }

    const hideShowExtractPartDialog = () => {
        setShowExtractPartDialog(false)
    }

    const hideShowExtractAllPartDialog = () => {
        setShowExtractAllPartsDialog(false)
    }

    const handleSelectedPathExtractionSubmit = (name) => {
        handleExtractSelectedPaths(name)
        setShowExtractPartDialog(false)
    }

    const handleAllPathExtractionSubmit = (name) => {
        handleExtractAllPaths(name)
        setShowExtractAllPartsDialog(false)
    }

    const extractPathToDrawingDto = (path, name) => {
        const internalPaths = []
        for (let i = 0; i < path.enclosesIds.length; i++) {
            const p = drawing.paths[path.enclosesIds[i]]
            internalPaths.push(p)
        }

        const newDrawing = Drawing.drawingFromOuterPath(name, path, internalPaths)

        return newDrawing.toDto()
    }

    const hasRemainingPaths = (extractedPathIds, outerPathIds) => {
        // extracted paths must be at least equal to outer paths if all paths are to be removed
        if (extractedPathIds.length >= outerPathIds.length) {
            for (const pathId of outerPathIds) {
                //if we can't find an outer path in our list of extracted parts there will be paths remaining in drawing
                if (!extractedPathIds.find((id) => id === pathId)) {
                    return true
                }
            }
            // if all outer paths exist in the extracted paths then we know no parts will remain
            return false
        }
        return true
    }

    const handleDeleteSelectedAndInsideClick = () => {
        const updatedDrawing = new Drawing(drawing)

        for (const k in selectedPaths) {
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteInsidePath(pathId)
            updatedDrawing.deletePath(pathId)
        }

        //setDrawingState(updatedDrawing)
        handleUpdateQuoteItemDrawing(updatedDrawing)
    }

    const addDrawingsToQuote = (drawings, newPartProps) => {
        const partsToAdd = []
        drawings.forEach((drawing) => {
            const newPart = {
                drawing: drawing,
                partSource: 'drawingFile',
                originalDrawingId: newPartProps.originalDrawingId,
                quantity: newPartProps.quantity ?? 1,
                isImperial: newPartProps.isImperial,
                cuttingTechnologyId: newPartProps.cuttingTechnologyId,
                materialId: newPartProps.materialId,
                sheetId: newPartProps.sheetId,
                thickness: newPartProps.thickness,
            }

            partsToAdd.push(newPart)
        })
        uploadQuoteItems({ organisationId, quoteId: quoteId, parts: partsToAdd })
        onClose()
    }

    const handlePartsExtracted = (partDrawings, originalPart, deleteOriginalPart) => {
        if (deleteOriginalPart) {
            deleteQuoteItem(originalPart.id)
        }

        addDrawingsToQuote(partDrawings, originalPart)
    }

    const handleExtractSelectedPaths = (name) => {
        if (numberOfSelectedPaths > 0) {
            let count = 1

            const pathKeys = Object.keys(selectedPaths)
            const pathsToExtract = []

            pathKeys.forEach((key) => {
                const path = drawing.paths[key]

                const partName = name + ` ${count}`
                count += 1

                const drawingDto = extractPathToDrawingDto(path, partName)
                pathsToExtract.push(drawingDto)
            })
            const deleteOriginalPart = !hasRemainingPaths(pathKeys, drawing?.partOuterPathIds)
            handlePartsExtracted(pathsToExtract, quoteItem, deleteOriginalPart)
            handleDeleteSelectedAndInsideClick()
        }
    }

    const handleAllPartsExtracted = (partDrawings, originalPart) => {
        deleteQuoteItem({ organisationId, quoteId: quoteId, quoteItemId: quoteItem?.id })
        addDrawingsToQuote(partDrawings, originalPart)
    }

    const handleExtractAllPaths = (namePrefix) => {
        let count = 1
        const extractedDrawings = []

        for (const k in drawing.paths) {
            const path = drawing.paths[k]
            if (!path.isText() && !path.layer.isInfo() && path.isOuter()) {
                const partName = namePrefix + ` ${count}`
                count += 1

                const drawingDto = extractPathToDrawingDto(path, partName)
                extractedDrawings.push(drawingDto)
            }
        }

        handleAllPartsExtracted(extractedDrawings, quoteItem)
    }

    // Deleting paths
    const handleDeleteSelectedClick = () => {
        const updatedDrawing = new Drawing(drawing)

        for (const k in selectedPaths) {
            updatedDrawing.deletePath(k)
        }

        //setDrawingState(updatedDrawing)
        handleUpdateQuoteItemDrawing(updatedDrawing)
        setSelectedPaths({})
    }

    const handleDeleteInsideSelectedClick = () => {
        if (numberOfSelectedPaths === 1) {
            const updatedDrawing = new Drawing(drawing)
            const k = Object.keys(selectedPaths)[0]
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteInsidePath(pathId)

            //setDrawingState(updatedDrawing)
            handleUpdateQuoteItemDrawing(updatedDrawing)
            setSelectedPaths({})
        }
    }

    const handleDeleteOutsideSelectedClick = () => {
        if (numberOfSelectedPaths === 1) {
            const updatedDrawing = new Drawing(drawing)
            const k = Object.keys(selectedPaths)[0]
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteOutsidePath(pathId)

            //setDrawingState(updatedDrawing)
            handleUpdateQuoteItemDrawing(updatedDrawing)
            setSelectedPaths({})
        }
    }

    // Change path layers
    const changeSelectedEntitiesToCutting = () => {
        changeSelectedEntitiesToLayer('Cutting')
    }

    const changeSelectedEntitiesToEtching = () => {
        changeSelectedEntitiesToLayer('Etching')
    }

    const changeSelectedEntitiesToFolding = () => {
        changeSelectedEntitiesToLayer('Folding')
    }

    const changeSelectedEntitiesToInformation = () => {
        changeSelectedEntitiesToLayer('Information')
    }

    const changeSelectedEntitiesToLayer = (layerName) => {
        enqueueSnackbar(t(`Changing selected entities to ${layerName} layer`), {
            variant: 'info',
        })

        const selectedPathKeys = Object.keys(selectedPaths)

        const updatedDrawing = new Drawing(drawing)

        selectedPathKeys.forEach((key) => {
            updatedDrawing.paths[key].layer.type = layerName
            updatedDrawing.paths[key].entities.forEach((entity) => {
                entity.layer.type = layerName
            })
        })

        //setDrawingState(updatedDrawing)
        handleUpdateQuoteItemDrawing(updatedDrawing)
        setSelectedPaths({})
    }

    const handleSelectQuoteItemChange = (event) => {
        setSelectedPaths({})
        const selectedQuoteItemId = event.target.value
        setSelectedQuoteItemId(selectedQuoteItemId)
        dispatch(setSelectedQuoteItemDrawingId(selectedQuoteItemId))
    }

    const handlePrevPartClick = () => {
        setSelectedPaths({})
        const prevQuoteItem = quoteItems[quoteItemIndex - 1]

        if (prevQuoteItem) {
            setSelectedQuoteItemId(prevQuoteItem.id)
            dispatch(setSelectedQuoteItemDrawingId(prevQuoteItem.id))
        }
    }

    const handleNextPartClick = () => {
        setSelectedPaths({})
        const nextQuoteItem = quoteItems[quoteItemIndex + 1]

        if (nextQuoteItem) {
            setSelectedQuoteItemId(nextQuoteItem.id)
            dispatch(setSelectedQuoteItemDrawingId(nextQuoteItem.id))
        }
    }

    const handleResetDrawingButtonClick = () => {
        setResetDrawingDialog(true)
    }

    const handleResetDrawing = async () => {
        try {
            await resetDrawing({
                organisationId,
                quoteId,
                quoteItemId,
            }).unwrap()

            enqueueSnackbar(t('Drawing reset'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to reset the drawing'), {
                variant: 'error',
            })
        }
    }

    useEffect(() => {
        if (!rawDrawing) {
            return
        }

        const drawingObj = new Drawing(structuredClone(rawDrawing))

        if (!drawingObj) {
            return
        }

        setDrawingState(drawingObj)
    }, [rawDrawing])

    useEffect(() => {
        if (!quoteItem?.id) return

        const baseURL = import.meta.env.VITE_API_SERVER_URL
        fetch(`${baseURL}/store/${organisationId}/${quoteId}/quote-items/${quoteItem?.id}/cad-view`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.text() // SVG is typically returned as text
            })
            .then((svgData) => setCadView(svgData))
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error)
            })
    }, [quoteItem, organisationId, quoteId])

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (!isSelectedPathsEmpty && !isUpdatingDrawing && !isPartFromLibrary) {
                if (
                    (event.key === 'Backspace' || event.key === 'Delete') &&
                    event.target.nodeName !== 'INPUT' &&
                    event.target.nodeName !== 'TEXTAREA'
                ) {
                    event.preventDefault()
                    event.stopPropagation()
                    handleDeleteSelectedClick()
                }
                if (event.code === 'KeyC' && event.altKey) {
                    event.preventDefault()
                    event.stopPropagation()
                    changeSelectedEntitiesToCutting()
                }
                if (event.code === 'KeyE' && event.altKey) {
                    event.preventDefault()
                    event.stopPropagation()
                    changeSelectedEntitiesToEtching()
                }
                if (event.code === 'KeyI' && event.altKey) {
                    event.preventDefault()
                    event.stopPropagation()
                    changeSelectedEntitiesToInformation()
                }
                if (event.code === 'KeyF' && event.altKey) {
                    event.preventDefault()
                    event.stopPropagation()
                    changeSelectedEntitiesToFolding()
                }
            }

            if (event.code === 'KeyS' && event.altKey) {
                event.preventDefault()
                event.stopPropagation()
                toggleShowShortcuts()
            }
            if (event.code === 'KeyP' && event.altKey) {
                event.preventDefault()
                event.stopPropagation()
                toggleShowLegend()
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [selectedPaths, isSelectedPathsEmpty, isUpdatingDrawing])

    return (
        <ThemeProvider theme={resolvedDarkTheme}>
            <Dialog
                open={open}
                sx={classes.dialogRoot}
                disableEscapeKeyDown
                fullScreen
                onClose={onClose}
            >
                <DialogContent sx={classes.dialogContent(showCadView)}>
                    <Box sx={classes.topBar}>
                        <Box sx={[classes.topBarTitle, classes.flex]}>
                            <IconButton
                                color="primary"
                                disabled={!showPrevButton}
                                onClick={handlePrevPartClick}
                            >
                                <ArrowBackIos fontSize="small" />
                            </IconButton>
                            <FormControl fullWidth>
                                <InputLabel
                                    color="primary"
                                    id="select-quote-item-option-label"
                                    shrink={true}
                                >
                                    {t('Select part')}
                                </InputLabel>
                                <Select
                                    color="primary"
                                    id="quote-item-option"
                                    inputProps={{
                                        'data-testid': 'quote-item-option-input',
                                    }}
                                    label={t('Shipping option')}
                                    labelId="quote-item-option-label"
                                    value={selectedQuoteItemId}
                                    variant="standard"
                                    onChange={handleSelectQuoteItemChange}
                                >
                                    {quoteItems.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {t(option.name)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <IconButton
                                color="primary"
                                disabled={!showNextButton}
                                onClick={handleNextPartClick}
                            >
                                <ArrowForwardIos fontSize="small" />
                            </IconButton>

                            {/* {!hasIssues && <QuoteItemStatus />}
                            {hasIssues && hasWarnings && (
                                <QuoteItemStatus
                                    status="warning"
                                    messages={hasWarnings}
                                />
                            )}
                            {hasIssues && hasErrors && (
                                <QuoteItemStatus
                                    status="error"
                                    messages={hasErrors}
                                />
                            )} */}
                        </Box>

                        <Box sx={[classes.topBarActions, classes.flex]}>
                            <ToggleButtonGroup
                                data-testid="quote-item-toolbar-button-toggle-cad-view"
                                size="small"
                                sx={classes.toggleButtonGroupSecondary}
                                value={showCadView}
                                exclusive
                                onChange={handleViewChange}
                            >
                                <ToggleButton
                                    sx={classes.toggleButtonSecondary}
                                    value={false}
                                >
                                    {t('Cut Path')}
                                </ToggleButton>
                                <ToggleButton
                                    sx={classes.toggleButtonSecondary}
                                    value={true}
                                >
                                    {isPartFlat ? t('Original CAD') : t('3D Model')}
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <ButtonGroup
                                variant="contained"
                                disableElevation
                            >
                                {isPartFlat ? (
                                    <TbxTooltip
                                        title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                        arrow
                                    >
                                        <span>
                                            <Button
                                                disabled={showCadView || isPartFromLibrary}
                                                onClick={() => setShowScaleDrawingModal(true)}
                                            >
                                                {t('Scale')}
                                            </Button>
                                        </span>
                                    </TbxTooltip>
                                ) : null}
                                <TbxTooltip
                                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                    arrow
                                >
                                    <span>
                                        <Button
                                            disabled={showCadView || isPartFromLibrary}
                                            onClick={handleActionClick('remove')}
                                        >
                                            {t('Remove')}
                                        </Button>
                                    </span>
                                </TbxTooltip>
                                <TbxTooltip
                                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                    arrow
                                >
                                    <span>
                                        <Button
                                            disabled={showCadView || isPartFromLibrary}
                                            onClick={handleActionClick('extract')}
                                        >
                                            {t('Extract')}
                                        </Button>
                                    </span>
                                </TbxTooltip>
                                <TbxTooltip
                                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                    arrow
                                >
                                    <span>
                                        <Button
                                            disabled={showCadView || isPartFromLibrary}
                                            onClick={handleActionClick('delete')}
                                        >
                                            {t('Delete')}
                                        </Button>
                                    </span>
                                </TbxTooltip>
                                <TbxTooltip
                                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                    arrow
                                >
                                    <span>
                                        <Button
                                            disabled={showCadView || isPartFromLibrary}
                                            onClick={handleActionClick('line-entity')}
                                        >
                                            {t('Modify')}
                                        </Button>
                                    </span>
                                </TbxTooltip>
                                <Button onClick={handleActionClick('help')}>{t('Help')}</Button>
                            </ButtonGroup>

                            <TbxTooltip
                                title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                                arrow
                            >
                                <span>
                                    <Button
                                        color="error"
                                        disabled={isPartFromLibrary}
                                        variant="contained"
                                        onClick={handleResetDrawingButtonClick}
                                    >
                                        {t('Reset drawing')}
                                    </Button>
                                </span>
                            </TbxTooltip>

                            <Menu
                                anchorEl={anchorEl}
                                id="remove-action-menu"
                                open={actionMenuOpen ? actionMenu === 'remove' : null}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem
                                    disabled={isUpdatingDrawing}
                                    onClick={handleRemoveNonSolidLinesClick}
                                >
                                    {t('Non-solid lines')}
                                </MenuItem>
                                <MenuItem
                                    disabled={isUpdatingDrawing}
                                    onClick={handleRemoveOpenInnersClick}
                                >
                                    {t('Open inners')}
                                </MenuItem>
                            </Menu>

                            <Menu
                                anchorEl={anchorEl}
                                id="extract-action-menu"
                                open={actionMenuOpen ? actionMenu === 'extract' : null}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={handleExtractSelectedClick}
                                >
                                    {t('Extract selected')}
                                </MenuItem>
                                <MenuItem
                                    className="drawing-doctor-menu-extract-all"
                                    onClick={handleExtractAllPartsClick}
                                >
                                    {t('Extract all parts in drawing')}
                                </MenuItem>
                            </Menu>

                            <Menu
                                anchorEl={anchorEl}
                                id="delete-action-menu"
                                open={actionMenuOpen ? actionMenu === 'delete' : null}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={handleDeleteSelectedClick}
                                >
                                    <ListItemText>{t('Delete selected')}</ListItemText>
                                    <Typography variant="body2">{'\u232B'}</Typography>
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={handleDeleteSelectedAndInsideClick}
                                >
                                    {t('Delete selected and paths inside')}
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={handleDeleteInsideSelectedClick}
                                >
                                    {t('Delete paths inside selected')}
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={handleDeleteOutsideSelectedClick}
                                >
                                    {t('Delete paths outside selected')}
                                </MenuItem>
                            </Menu>

                            <Menu
                                anchorEl={anchorEl}
                                id="line-entity-action-menu"
                                open={actionMenuOpen ? actionMenu === 'line-entity' : null}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={changeSelectedEntitiesToCutting}
                                >
                                    <ListItemText>{t('Set path to cutting')}</ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} C`}</Typography>
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={changeSelectedEntitiesToEtching}
                                >
                                    <ListItemText> {t('Set path to etching')}</ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} E`}</Typography>
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={changeSelectedEntitiesToInformation}
                                >
                                    <ListItemText sx={{ marginRight: 4 }}>{t('Set path to information')}</ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} I`}</Typography>
                                </MenuItem>
                                <MenuItem
                                    disabled={isSelectedPathsEmpty || isUpdatingDrawing}
                                    onClick={changeSelectedEntitiesToFolding}
                                >
                                    <ListItemText>{t('Set path to folding')}</ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} F`}</Typography>
                                </MenuItem>
                            </Menu>

                            <Menu
                                anchorEl={anchorEl}
                                id="help-action-menu"
                                open={actionMenuOpen ? actionMenu === 'help' : null}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem onClick={toggleShowShortcuts}>
                                    <ListItemText sx={{ marginRight: 4 }}>
                                        {showShortcuts ? t('Hide shortcuts') : t('Show shortcuts')}
                                    </ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} S`}</Typography>
                                </MenuItem>
                                <MenuItem onClick={toggleShowLegend}>
                                    <ListItemText>
                                        {' '}
                                        {showLegend ? t('Hide path legend') : t('Show path legend')}
                                    </ListItemText>
                                    <Typography variant="body2">{`${isMacOS ? '\u2325' : 'Alt +'} P`}</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>

                        <Box sx={[classes.topBarInfo, classes.flex]}>
                            <RenderDimensions
                                quoteItemId={quoteItemId}
                                selectedPaths={selectedPaths}
                            />
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={onClose}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                    {isLoadingDrawing ? (
                        <LoadingOverlay labelText="Loading drawing..." />
                    ) : (
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="row"
                            height="calc(100vh - 72px)"
                        >
                            <DrawingDoctorDrawer
                                drawing={drawing}
                                quoteItemId={quoteItemId}
                            />
                            <DrawingDoctor
                                disabled={typeof quoteItem?.partLibraryEntryId === 'undefined' ? false : true}
                                drawing={drawing}
                                originalCadSvg={cadView}
                                quoteItemId={quoteItemId}
                                selectedPaths={selectedPaths}
                                showCadView={showCadView}
                                showLegend={showLegend}
                                showShortcuts={showShortcuts}
                                onSelectPath={setSelectedPaths}
                            />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            <ExtractPartDialog
                initialName={drawing?.name}
                multiple={false}
                open={showExtractPartDialog}
                onClose={hideShowExtractPartDialog}
                onSubmit={handleSelectedPathExtractionSubmit}
            />

            <ExtractPartDialog
                initialName={drawing?.name}
                multiple={true}
                open={showExtractAllPartsDialog}
                onClose={hideShowExtractAllPartDialog}
                onSubmit={handleAllPathExtractionSubmit}
            />

            <ScaleDrawingModal
                drawingDimensions={drawingDimensions}
                handleClose={() => {
                    setShowScaleDrawingModal(false)
                    setSelectedPaths({})
                }}
                open={showScaleDrawingModal}
            />

            <AlertDialog
                buttonVariant="contained"
                cancelButtonText={t('No, keep changes')}
                content={t(
                    'Are you sure you want to reset the drawing to its original state? This action cannot be undone.'
                )}
                okButtonText={t('Yes, reset drawing')}
                open={resetDrawingDialog}
                title={t('Confirm reset drawing')}
                onCancelClose={() => setResetDrawingDialog(false)}
                onOkClose={() => {
                    handleResetDrawing()
                    setResetDrawingDialog(false)
                }}
            />
        </ThemeProvider>
    )
}

DrawingDoctorModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default DrawingDoctorModal
