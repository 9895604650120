import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFrom3DMutation } from '@/app/services/quoteItems'
import { useGetQuoteQuery } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'
import Unfold from '@/features/quotes/components/Unfold/Unfold'

const classes = {
    dialogRoot: {
        borderRadius: 1,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        p: 0,
    },
}

const Add3DPartsDialog = ({ is3DTube = false, onClose, open = false }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const { quoteId } = useParams()
    const organisationId = useSelector((state) => selectOrganisationId(state))

    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const [addQuoteItemsFrom3d] = useAddQuoteItemsFrom3DMutation()

    const handleClose = () => {
        onClose()
    }

    const handleAdd3DParts = async () => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        try {
            await addQuoteItemsFrom3d({ organisationId, quoteId: quoteId }).unwrap()

            const successMessage = is3DTube
                ? `${t('Rotary parts added to $t(quote)')}`
                : `${t('3D parts added to $t(quote)')}`

            enqueueSnackbar(successMessage, { variant: 'success' })

            handleClose()
        } catch (error) {
            const errorMessage = is3DTube ? `${t('Rotary parts not added')}` : `${t('3D parts not added')}`
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <Unfold
                    organisationId={organisationId}
                    quoteId={quoteId}
                    unfoldTube={is3DTube}
                    onCancelClicked={handleClose}
                    onSubmitClicked={handleAdd3DParts}
                />
            </DialogContent>
        </Dialog>
    )
}

Add3DPartsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    is3DTube: PropTypes.bool,
    open: PropTypes.bool,
}

export default Add3DPartsDialog
