import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Close, Search } from '@mui/icons-material'
import { Box, Dialog, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { useDeletePartLibraryEntryMutation, useGetPartLibraryEntriesQuery } from '@/app/services/partLibrary'
import {
    selectIsOnFreePlan,
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
} from '@/app/slices/organisationSlice'
import {
    selectOpenPartQuoteHistoryModal,
    selectSelectedPartFromLibrary,
    setOpenPartQuoteHistoryModal,
    setSelectedPartFromLibrary,
} from '@/app/slices/quoteItemsSlice'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import { useToolBoxTreatments } from '@/common/hooks'

import PartLibraryTable from './PartLibraryTable'
import PartQuoteHistoryDialog from './PartQuoteHistoryDialog'

const classes = {
    // Search Header CSS
    searchBar: {
        display: 'flex',
        width: '100%',
        marginBottom: '24px',
        justifyContent: 'space-between',
    },
    iconButton: {
        padding: 0,
    },
    quoteHistotyDialog: {
        display: 'flex',
        width: '100%',
        gap: 3,
        mb: 3,
    },
    searchInput: {
        fontFamily: 'roboto',
        '& input::placeholder': {
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'roboto',
            color: 'black',
        },
        width: '19%',
        backgroundColor: 'white',
    },
    textFieldMaterial: {
        width: '12%',
        backgroundColor: 'white',
    },
    textFieldThickness: {
        width: '12%',
        backgroundColor: 'white',
    },
    textFieldLastQuote: {
        width: '14%',
        backgroundColor: 'white',
    },
    textFieldProjectStaus: {
        width: '17%',
        backgroundColor: 'white',
    },
    paper: {
        padding: 2,
        textAlign: 'center',
        color: 'text.secondary',
    },
    dialogPaper: {
        minWidth: '90%',
        maxWidth: '90%',
        minHeight: '90%',
        maxHeight: '90%',
    },
    dialogPaper17: {
        minWidth: '20%',
        maxWidth: '20%',
    },
    datePicker: {
        display: 'flex',
    },
    textFieldDate: {
        width: '170px',
        height: '32px',
        marginTop: 0,
        backgroundColor: 'white',
    },
    root: {
        margin: 0,
        padding: 2,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: (theme) => theme.palette.grey[500],
    },
}

const DialogTitle = ({ children, onClose, ...other }) => {
    return (
        <MuiDialogTitle
            sx={classes.root}
            {...other}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size="large"
                    sx={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

DialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
}

const PartLibraryTab = ({ customerId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const isPartQuoteHistoryModalOpen = useSelector(selectOpenPartQuoteHistoryModal)
    const selectedPartFromLibrary = useSelector(selectSelectedPartFromLibrary)

    const userTimeZone = dayjs.tz.guess()

    const {
        data: partsFromLibrary,
        isFetching,
        isLoading,
    } = useGetPartLibraryEntriesQuery({
        organisationId,
        customerId,
    })

    const [deletePartLibraryEntry] = useDeletePartLibraryEntryMutation()

    const [materials, setMaterials] = useState(['All'])
    const [filterByMaterial, setFilterByMaterial] = useState('All')
    const [thickness, setThickness] = useState(['All'])
    const [filterByThickness, setFilterByThickness] = useState('All')
    const [filterByLastQuotePeriodFrom, setFilterByLastQuotePeriodFrom] = useState(null)
    const [filterByLastQuotePeriodTo, setFilterByLastQuotePeriodTo] = useState(null)
    const [partLibraryIdOrNameSearch, setpartLibraryIdOrNameSearch] = useState('')
    const [partsArchivedFilter, setPartsArchivedFilter] = useState(false)

    const [showDetailSecondaryProcessDialog, setShowDetailSecondaryProcessDialog] = useState(false)
    const [secondaryProcesses, setSecondaryProcesses] = useState([])

    const { showPartLibrary } = useToolBoxTreatments()

    const isPartLibraryEnabled = showPartLibrary && paidFeatures?.hasPartLibrary

    const handleProjectMoreDetailsClick = async (e, selectedPartFromLibrary) => {
        e.preventDefault()

        dispatch(setSelectedPartFromLibrary(selectedPartFromLibrary))
        dispatch(setOpenPartQuoteHistoryModal(true))
    }

    // Start Secondary Process Dialog
    const handleDetailSecondaryProcessClick = (e, partLibraryEntrySecondaryProcesses, numberOfFolds) => {
        const processes = [
            ...(numberOfFolds > 0 ? [{ secondaryProcess: { secondaryProcessId: uuidv4(), name: t('Folding') } }] : []),
            ...partLibraryEntrySecondaryProcesses,
        ]
        e.preventDefault()
        setShowDetailSecondaryProcessDialog(true)
        setSecondaryProcesses(processes)
    }

    const handleCloseSecondaryProcessDialog = () => {
        setShowDetailSecondaryProcessDialog(false)
    }
    // End Secondary Process Dialg

    // Start Handlers for the Filters
    const handleChangeFilterByMaterial = (event) => {
        setFilterByMaterial(event.target.value)
    }
    const handleChangeFilterByThickness = (event) => {
        setFilterByThickness(event.target.value)
    }
    const handleChangeFilterByLastQuotePeriodFrom = (date) => {
        setFilterByLastQuotePeriodFrom(date?.utc()?.format() || null)
    }
    const handleChangeFilterByLastQuotePeriodTo = (date) => {
        setFilterByLastQuotePeriodTo(date?.utc()?.format() || null)
    }
    const handlePartsArchivedFilter = (event) => {
        setPartsArchivedFilter(event.target.checked)
    }
    const handleSearchPartLibraryIdOrName = (event) => {
        setpartLibraryIdOrNameSearch(event.target.value)
    }
    // End Handlers for the Filters

    // Start Archive and Unarchive Methods
    const archivePartLibrary = async (selectedPartLibrary) => {
        try {
            await deletePartLibraryEntry({
                organisationId,
                quoteId: selectedPartLibrary.lastQuote.quoteId,
                partLibraryEntryId: selectedPartLibrary.partLibraryId,
                deletePartLibraryEntry: true,
            }).unwrap()
        } catch (error) {
            const errorMessage = t('$t(An error occurred) while archive part library entry')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }
    const unarchivePartLibrary = async (selectedPartLibrary) => {
        try {
            await deletePartLibraryEntry({
                organisationId,
                quoteId: selectedPartLibrary.lastQuote.quoteId,
                partLibraryEntryId: selectedPartLibrary.partLibraryId,
                deletePartLibraryEntry: false,
            }).unwrap()
        } catch (error) {
            const errorMessage = t('$t(An error occurred) while unarchive part library entry')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }
    // End Archive and Unarchive Methods

    // Start Part's Project Histoy Methods
    const handleClosPartQuoteHistory = () => {
        dispatch(setOpenPartQuoteHistoryModal(false))
        dispatch(setSelectedPartFromLibrary(null))
    }

    // END Part's Project Histoy Methods

    const checkMaterialValueExist = (partLibrary) => {
        if (
            typeof partLibrary.material.materialName !== 'undefined' &&
            partLibrary.material.materialName === filterByMaterial
        )
            return partLibrary
        else return {}
    }
    const checkThicknessValueExist = (partLibrary) => {
        if (partLibrary.thickness.toString() === filterByThickness) return partLibrary
        else return {}
    }
    const checkLastQuotePeriodValueRangeExist = (partLibraryItem) => {
        if (typeof partLibraryItem.lastQuote.lastModifiedDate !== 'undefined') {
            const min = dayjs(filterByLastQuotePeriodFrom)
            const max = dayjs(filterByLastQuotePeriodTo)
            const lastModifiedDate = dayjs(partLibraryItem.lastQuote.lastModifiedDate)

            if (lastModifiedDate.isBetween(min, max, 'day', '[]')) return partLibraryItem
            else return {}
        } else return {}
    }
    const _partsFromLibrary = partsFromLibrary?.filter((partLibraryItem) => {
        if (Object.keys(partLibraryItem).length > 0 && filterByMaterial !== 'All') {
            partLibraryItem = checkMaterialValueExist(partLibraryItem)
        }
        if (Object.keys(partLibraryItem).length > 0 && filterByThickness !== 'All') {
            partLibraryItem = checkThicknessValueExist(partLibraryItem)
        }
        if (
            Object.keys(partLibraryItem).length > 0 &&
            filterByLastQuotePeriodFrom != null &&
            filterByLastQuotePeriodTo != null
        ) {
            partLibraryItem = checkLastQuotePeriodValueRangeExist(partLibraryItem)
        }
        // If the record passed from above filters and not equals to null than
        if (Object.keys(partLibraryItem).length > 0) {
            if (partLibraryIdOrNameSearch.length > 0) {
                return (
                    (partLibraryItem.partId.toLowerCase().includes(partLibraryIdOrNameSearch.toLowerCase()) ||
                        partLibraryItem.name.toLowerCase().includes(partLibraryIdOrNameSearch.toLowerCase())) &&
                    (!partsArchivedFilter
                        ? partLibraryItem.isDeleted === false
                        : partLibraryItem.isDeleted === true || partLibraryItem.isDeleted === false)
                )
            } else {
                return !partsArchivedFilter
                    ? partLibraryItem.isDeleted === false
                    : partLibraryItem.isDeleted === true || partLibraryItem.isDeleted === false
            }
        }
    })

    useEffect(() => {
        if (!isLoading) {
            const uniqueMaterials = [...new Set(partsFromLibrary.map((item) => item.material.materialName))]
            uniqueMaterials.unshift('All')
            setMaterials(uniqueMaterials)

            const uniqueThickness = [...new Set(partsFromLibrary.map((item) => item.thickness.toString()))]
            uniqueThickness.sort((a, b) => a - b)
            uniqueThickness.unshift('All')
            setThickness(uniqueThickness)
        }
    }, [isLoading, partsFromLibrary])

    return (
        <div>
            {isPartLibraryEnabled ? (
                <>
                    <Grid
                        sx={classes.searchBar}
                        container
                    >
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="large"
                                            sx={classes.iconButton}
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={t('Search part ID or name')}
                            size="small"
                            sx={classes.searchInput}
                            value={partLibraryIdOrNameSearch}
                            variant="outlined"
                            onChange={handleSearchPartLibraryIdOrName}
                        ></TextField>

                        <TextField
                            id="outlined-filter-material"
                            label={t('Material')}
                            name=""
                            size="small"
                            sx={classes.textFieldMaterial}
                            value={filterByMaterial}
                            variant="outlined"
                            select
                            onChange={handleChangeFilterByMaterial}
                        >
                            {materials.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {option.toLowerCase() === 'all' ? t('All') : option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="outlined-filter-by-thickness"
                            label={t('Thickness')}
                            name=""
                            size="small"
                            sx={classes.textFieldThickness}
                            value={filterByThickness}
                            variant="outlined"
                            select
                            onChange={handleChangeFilterByThickness}
                        >
                            {thickness.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {option.toLowerCase() === 'all' ? t('All') : option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TbxLocalizationProvider>
                            <DesktopDatePicker
                                format="DD-MMM-YYYY"
                                label={t('From')}
                                maxDate={dayjs()}
                                slotProps={{
                                    textField: {
                                        placeholder: 'From date',
                                        size: 'small',
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    },
                                    field: {
                                        clearable: true,
                                        onClear: () => {
                                            setFilterByLastQuotePeriodFrom(null)
                                            setFilterByLastQuotePeriodTo(null)
                                        },
                                    },
                                }}
                                sx={classes.textFieldDate}
                                timezone={userTimeZone}
                                value={filterByLastQuotePeriodFrom ? dayjs(filterByLastQuotePeriodFrom) : null}
                                onChange={handleChangeFilterByLastQuotePeriodFrom}
                            />

                            <DesktopDatePicker
                                disabled={filterByLastQuotePeriodFrom ? false : true}
                                format="DD-MMM-YYYY"
                                label={t('To')}
                                maxDate={dayjs()}
                                minDate={filterByLastQuotePeriodFrom ? dayjs(filterByLastQuotePeriodFrom) : null}
                                slotProps={{
                                    textField: {
                                        placeholder: 'To date',
                                        size: 'small',
                                        variant: 'outlined',
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    },
                                    field: {
                                        clearable: true,
                                        onClear: () => {
                                            setFilterByLastQuotePeriodFrom(null)
                                            setFilterByLastQuotePeriodTo(null)
                                        },
                                    },
                                }}
                                sx={classes.textFieldDate}
                                timezone={userTimeZone}
                                value={filterByLastQuotePeriodTo ? dayjs(filterByLastQuotePeriodTo) : null}
                                onChange={handleChangeFilterByLastQuotePeriodTo}
                            />
                        </TbxLocalizationProvider>
                        <Box sx={classes.toggleContainer}>
                            {t('Show archived parts')}
                            <Switch
                                checked={partsArchivedFilter}
                                color="primary"
                                onChange={handlePartsArchivedFilter}
                            />
                        </Box>
                    </Grid>
                    {!isOnFreePlan ? (
                        <PartLibraryTable
                            archivePartLibrary={archivePartLibrary}
                            handleDetailSecondaryProcessClick={handleDetailSecondaryProcessClick}
                            handleProjectMoreDetailsClick={handleProjectMoreDetailsClick}
                            isImperial={organisation.defaultDrawingUnits === 'Imperial'}
                            isLoading={isLoading || isFetching}
                            organisation={organisation}
                            partsFromLibrary={_partsFromLibrary}
                            unarchivePartLibrary={unarchivePartLibrary}
                        />
                    ) : null}
                </>
            ) : null}

            <PartQuoteHistoryDialog
                handleClose={handleClosPartQuoteHistory}
                open={isPartQuoteHistoryModalOpen}
                partLibrary={selectedPartFromLibrary}
            />

            <Dialog
                aria-labelledby="part-quote-history-dialog"
                open={showDetailSecondaryProcessDialog}
                PaperProps={{
                    sx: classes.dialogPaper17,
                }}
                onClose={handleCloseSecondaryProcessDialog}
            >
                <DialogTitle
                    id="SP-dialog"
                    onClose={handleCloseSecondaryProcessDialog}
                >
                    {t('Secondary Process')}
                </DialogTitle>
                <DialogContent dividers>
                    <ul>
                        {secondaryProcesses.map((item) => (
                            <li key={item.secondaryProcess.secondaryProcessId}>{item.secondaryProcess.name}</li>
                        ))}
                    </ul>
                </DialogContent>
            </Dialog>
        </div>
    )
}

PartLibraryTab.propTypes = {
    customerId: PropTypes.string.isRequired,
}

export default PartLibraryTab
