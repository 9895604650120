import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useCreateCuttingTechnologyMutation,
    useUpdateCuttingTechnologyMutation,
} from '@/app/services/cuttingTechnologies'
import {
    selectOrganisationId,
    selectPaidFeatures,
    selectShowAccelerationSettings,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import SetupModeSelect from '@/common/components/SetupModeSelect/SetupModeSelect'
import SheetChangeModeSelect from '@/common/components/SheetChangeModeSelect/SheetChangeModeSelect'
import { useToolBoxTreatments } from '@/common/hooks'

import CuttingTechTypeSelect from '../CuttingTechTypeSelect/CuttingTechTypeSelect'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: 'text.primary',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const AddCuttingTechnologyForm = ({ cuttingTechnology, onCancel, onSave }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { showTube } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const showAccelerationSettings = useSelector(selectShowAccelerationSettings)

    const [updateCuttingTechnology] = useUpdateCuttingTechnologyMutation()
    const [createCuttingTechnology] = useCreateCuttingTechnologyMutation()

    const [isSaving, setIsSaving] = useState(false)

    const { control, formState, handleSubmit, watch } = useForm({
        mode: 'all',
        defaultValues: {
            cuttingTechnologyId: cuttingTechnology?.cuttingTechnologyId || '',
            name: cuttingTechnology?.name || '',
            maximumSheetLength: cuttingTechnology?.maximumSheetLength,
            maximumSheetWidth: cuttingTechnology?.maximumSheetWidth,
            setupTime: cuttingTechnology?.setupTime || undefined,
            setupMode: cuttingTechnology?.setupMode || 'None',
            sheetChangeMode: cuttingTechnology?.sheetChangeMode || 'None',
            sheetChangeTime: cuttingTechnology?.sheetChangeTime || undefined,
            type: cuttingTechnology?.type || 'Flat',
            maximumProfileDiameter: cuttingTechnology?.maximumProfileDiameter,
            maximumMaterialLength: cuttingTechnology?.maximumMaterialLength,
            chuckAllowance: cuttingTechnology?.chuckAllowance,
            linearAcceleration: cuttingTechnology?.linearAcceleration,
            radialAcceleration: cuttingTechnology?.radialAcceleration,
            rapidAcceleration: cuttingTechnology?.rapidAcceleration,
            rapidTraverseRate: cuttingTechnology?.rapidTraverseRate,
        },
    })

    const cuttingTechType = watch('type')

    const handleAddCuttingTechButtonClick = async (data, _) => {
        const cuttingTech = {
            ...data,
            maximumSheetLength: data.maximumSheetLength || null,
            maximumSheetWidth: data.maximumSheetWidth || null,
        }
        setIsSaving(true)
        try {
            if (cuttingTechnology) {
                await updateCuttingTechnology({ organisationId, cuttingTechnology: cuttingTech })

                enqueueSnackbar(t('Cutting technology updated'), { variant: 'success' })
            } else {
                await createCuttingTechnology({ organisationId, cuttingTechnology: cuttingTech })

                enqueueSnackbar(t('Cutting technology created'), { variant: 'success' })
            }
            onSave()
        } catch (error) {
            enqueueSnackbar(t(error.response?.data?.title || 'An error occurred'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <>
            <Typography sx={classes.title}>
                {cuttingTechnology ? t('Edit cutting technology') : t('Add cutting technology')}
            </Typography>
            <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'cutting-technology-name',
                        }}
                        label={t('Display Name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />

            <Typography sx={classes.addressTitle}>{t('Details')}</Typography>

            {showTube ? (
                <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value } }) => (
                        <CuttingTechTypeSelect
                            classes={{
                                root: undefined,
                            }}
                            disabled={!paidFeatures?.hasUnfoldTube || !!cuttingTechnology}
                            fullWidth={true}
                            includeNoneOption={false}
                            inputProps={{
                                'data-testid': 'add-cutting-technology-type',
                            }}
                            label={t('Type')}
                            sx={classes.input}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: 'Required' }}
                />
            ) : null}

            {cuttingTechType === 'Flat' ? (
                <>
                    <Controller
                        control={control}
                        name="maximumSheetLength"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-max-sheet-length',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                    ),
                                }}
                                label={t('Maximum sheet length')}
                                placeholder={t('No maximum')}
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            min: { value: 0, message: 'Must be greater than 0' },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumSheetWidth"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-max-sheet-width',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                    ),
                                }}
                                label={t('Maximum sheet width')}
                                placeholder={t('No maximum')}
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            min: { value: 0, message: 'Must be greater than 0' },
                        }}
                    />
                </>
            ) : null}
            {cuttingTechType === 'Rotary' ? (
                <>
                    <Controller
                        control={control}
                        name="maximumProfileDiameter"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-max-profile-diameter',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                    ),
                                }}
                                label={t('Maximum profile diameter')}
                                placeholder={t('No maximum')}
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            min: { value: 0, message: 'Must be greater than 0' },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumMaterialLength"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-max-material-length',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                    ),
                                }}
                                label={t('Maximum material length')}
                                placeholder={t('No maximum')}
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            min: { value: 0, message: 'Must be greater than 0' },
                        }}
                    />
                    <Controller
                        control={control}
                        name="chuckAllowance"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(fieldState.error?.message)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-chuck-allowance',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                    ),
                                }}
                                label={t('Chuck allowance')}
                                placeholder="0"
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            min: { value: 0, message: 'Must be greater than 0' },
                        }}
                    />
                </>
            ) : null}

            <Controller
                control={control}
                name="setupTime"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            'data-testid': 'add-setup-time',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('s')}</InputAdornment>,
                        }}
                        label={t('Setup time')}
                        placeholder="0"
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="setupMode"
                render={({ field: { onChange, value } }) => (
                    <SetupModeSelect
                        classes={{
                            root: undefined,
                        }}
                        fullWidth={true}
                        inputProps={{
                            'data-testid': 'add-setup-mode',
                        }}
                        label={t('Setup Mode')}
                        labelPosition="top"
                        labelText={''}
                        sx={classes.input}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="sheetChangeTime"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            'data-testid': 'add-sheet-change-time',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('s')}</InputAdornment>,
                        }}
                        label={t(cuttingTechType === 'Rotary' ? 'Loading time' : 'Sheet change time')}
                        placeholder="0"
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="sheetChangeMode"
                render={({ field: { onChange, value } }) => (
                    <SheetChangeModeSelect
                        classes={{
                            root: undefined,
                        }}
                        fullWidth={true}
                        inputProps={{
                            'data-testid': 'add-sheet-change-mode',
                        }}
                        label={t(cuttingTechType === 'Rotary' ? 'Loading mode' : 'Sheet change mode')}
                        labelPosition="top"
                        labelText={''}
                        sx={classes.input}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            {cuttingTechnology && showAccelerationSettings ? (
                <>
                    <Controller
                        control={control}
                        name="linearAcceleration"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(
                                    fieldState.invalid ? fieldState.error?.message : 'Maximum linear acceleration'
                                )}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-linear-acceleration',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {useImperialUnits ? 'in/s2' : 'mm/s2'}
                                        </InputAdornment>
                                    ),
                                }}
                                label={t('Linear acceleration *')}
                                placeholder="0"
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required', min: { value: 1, message: 'Must be a positive number' } }}
                    />

                    <Controller
                        control={control}
                        name="radialAcceleration"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(
                                    fieldState.invalid ? fieldState.error?.message : 'Maximum radial acceleration'
                                )}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-radial-acceleration',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {useImperialUnits ? 'in/s2' : 'mm/s2'}
                                        </InputAdornment>
                                    ),
                                }}
                                label={t('Radial acceleration *')}
                                placeholder="0"
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required', min: { value: 1, message: 'Must be a positive number' } }}
                    />

                    <Controller
                        control={control}
                        name="rapidAcceleration"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(
                                    fieldState.invalid ? fieldState.error?.message : 'Maximum rapid acceleration'
                                )}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-rapid-acceleration',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {useImperialUnits ? 'in/s2' : 'mm/s2'}
                                        </InputAdornment>
                                    ),
                                }}
                                label={t('Rapid acceleration *')}
                                placeholder="0"
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required', min: { value: 1, message: 'Must be a positive number' } }}
                    />

                    <Controller
                        control={control}
                        name="rapidTraverseRate"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={t(
                                    fieldState.invalid ? fieldState.error?.message : 'Maximum rapid traverse rate'
                                )}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    'data-testid': 'add-rapid-transverse-rate',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {useImperialUnits ? 'in/min' : 'mm/min'}
                                        </InputAdornment>
                                    ),
                                }}
                                label={t('Rapid traverse rate *')}
                                placeholder="0"
                                sx={classes.input}
                                type="number"
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required', min: { value: 1, message: 'Must be a positive number' } }}
                    />
                </>
            ) : null}

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="secondary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddCuttingTechButtonClick)}
                >
                    {isSaving ? t('Saving...') : cuttingTechnology ? t('Update') : t('Add')}
                </Button>
            </Box>
        </>
    )
}

AddCuttingTechnologyForm.propTypes = {
    cuttingTechnology: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
}

export default AddCuttingTechnologyForm
