import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Button, Menu, MenuItem, ThemeProvider } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useCreateQuoteMaterialListMutation } from '@/app/services/materials'
import { useAddMiscItemMutation, useGetMiscItemsQuery } from '@/app/services/miscItems'
import { selectSelectedCustomer } from '@/app/slices/customersSlice'
import { addExpandedMiscItem } from '@/app/slices/miscItemsSlice'
import { selectIsOnFreePlan, selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { useToolBoxTreatments } from '@/common/hooks'
import { theme as darkTheme } from '@/common/themes/DarkTheme'
import { StrikerThemeOverride } from '@/common/themes/overrides/StrikerThemeOverride'
import { WhitelabelThemeOverride } from '@/common/themes/overrides/WhitelabelThemeOverride'
import { QUOTE_ADD_PARTS_OPTIONS } from '@/common/utils'

import Add3DPartsDialog from '../common/Add3DPartsDialog'
import AddFromPartLibrary from '../common/AddFromPartLibrary'
import AddPDFPartsDialog from '../common/AddPDFParts'
import AddQuickPartDialog from '../common/AddQuickPartDialog'
import AddQuoteItemsDialog from '../common/AddQuoteItemsDialog'

const AddPartsButtonGroup = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const {
        showLibellulaWhitelabelling,
        showMiscItems,
        showPartLibrary,
        showPdfButton,
        showStrikerWhitelabelling,
        showTube,
    } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const selectedCustomer = useSelector(selectSelectedCustomer)

    const { data: miscItems } = useGetMiscItemsQuery({ organisationId, quoteId })

    const miscNameRef = useRef(`Miscellaneous item ${miscItems?.length + 1}`)
    const miscItemsLengthRef = useRef(miscItems?.length)

    const [createQuoteMaterialList] = useCreateQuoteMaterialListMutation()
    const [addNewMiscItem, { isLoading: isAdding }] = useAddMiscItemMutation()

    const [interfaceToShow, setInterfaceToShow] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)

    const isPartLibraryEnabled = showPartLibrary && paidFeatures?.hasPartLibrary
    const isUnfoldTubeEnabled = showTube && paidFeatures?.hasUnfoldTube
    const is3dEnabled = paidFeatures?.has3d
    const isPDFUploadEnabled = showPdfButton && paidFeatures?.hasPdfUpload
    const isMiscItemsEnabled = showMiscItems && paidFeatures?.hasMiscellaneousItems

    const open = Boolean(anchorEl)

    const createMaterialList = async (organisationId, urlParams) => {
        try {
            await createQuoteMaterialList({ organisationId, location: urlParams }).unwrap()
        } catch (error) {
            console.error(error)
        }
    }

    const handleAddPartsButtonClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleAddPartsMenuClose = () => {
        setAnchorEl(null)
    }

    const resetInterface = () => {
        setInterfaceToShow('')
        handleAddPartsMenuClose()
    }

    const handleAdd2dPartsClick = () => {
        setInterfaceToShow('2d')
        handleAddPartsMenuClose()
    }

    const handleAddFromQuickPartClick = () => {
        setInterfaceToShow('quickpart')
        handleAddPartsMenuClose()
    }

    const handleAdd3dPartsClick = useCallback(() => {
        createMaterialList(organisationId, 'Unfold3d').then(() => {
            setInterfaceToShow('3d')
            handleAddPartsMenuClose()
        })
    }, [organisationId])

    const handleAddRotaryPartsClick = useCallback(() => {
        createMaterialList(organisationId, 'Unfold3d').then(() => {
            setInterfaceToShow('3d-tube')
            handleAddPartsMenuClose()
        })
    }, [organisationId])

    const handleAddPdfPartsClick = useCallback(() => {
        createMaterialList(organisationId, 'Pdf2Quote').then(() => {
            setInterfaceToShow('pdf')
            handleAddPartsMenuClose()
        })
    }, [organisationId])

    const handleAddFromPartsLibraryClick = () => {
        try {
            setInterfaceToShow('partlibrary')
            handleAddPartsMenuClose()
        } catch (err) {
            enqueueSnackbar(t('$t(An error occurred), failed to add parts from the library.'), {
                variant: 'error',
            })
        }
    }

    const handleAddMiscItemsClick = async () => {
        handleAddPartsMenuClose()
        try {
            const newItem = await addNewMiscItem({
                organisationId,
                quoteId,
                miscItem: {
                    quoteId,
                    name: miscNameRef.current,
                    index: miscItemsLengthRef.current,
                    price: 0,
                },
            }).unwrap()

            dispatch(addExpandedMiscItem(newItem.id))

            enqueueSnackbar(t('New miscellaneous item added'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to add new miscellaneous item'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const resolvedDarkTheme = useMemo(
        () =>
            showLibellulaWhitelabelling
                ? WhitelabelThemeOverride(darkTheme)
                : showStrikerWhitelabelling
                  ? StrikerThemeOverride(darkTheme)
                  : darkTheme,
        [showLibellulaWhitelabelling, showStrikerWhitelabelling, darkTheme]
    )

    const menuItems = useMemo(() => {
        return QUOTE_ADD_PARTS_OPTIONS?.map((option) => {
            const { handler, id, label, tagAttrs } = option
            let handleOptionClick
            switch (handler) {
                case 'handleAdd2dParts':
                    handleOptionClick = handleAdd2dPartsClick
                    break
                case 'handleAdd3dParts':
                    handleOptionClick = handleAdd3dPartsClick
                    break
                case 'handleAddRotaryParts':
                    handleOptionClick = handleAddRotaryPartsClick
                    break
                case 'handleAddFromQuickParts':
                    handleOptionClick = handleAddFromQuickPartClick
                    break
                case 'handleAddPdfParts':
                    handleOptionClick = handleAddPdfPartsClick
                    break
                case 'handleAddFromPartsLibrary':
                    handleOptionClick = handleAddFromPartsLibraryClick
                    break
                case 'handleAddMiscellaneousItem':
                    handleOptionClick = handleAddMiscItemsClick
                    break

                default:
                    break
            }
            return {
                id,
                label,
                handler,
                tagAttrs,
                handleOptionClick,
            }
        })
    }, [QUOTE_ADD_PARTS_OPTIONS])

    const isMenuItemHidden = (menuItemId) => {
        if (menuItemId === 'addMiscellaneousItem') return !showMiscItems
    }

    const isMenuItemDisabled = (menuItemId) => {
        if (menuItemId === 'add3dParts') return !is3dEnabled
        if (menuItemId === 'addRotaryParts') return !isUnfoldTubeEnabled
        if (menuItemId === 'addPdfParts') return !isPDFUploadEnabled
        if (menuItemId === 'addFromPartsLibrary') return !selectedCustomer || !isPartLibraryEnabled
        if (menuItemId === 'addFromQuickParts') return isOnFreePlan
        if (menuItemId === 'addMiscellaneousItem') return !isMiscItemsEnabled || !showMiscItems
    }

    const menuItemDisabledMessage = (menuItemId) => {
        if (menuItemId === 'add3dParts') return t('Your plan does not include this feature')
        if (menuItemId === 'addRotaryParts') return t('Your plan does not include this feature')
        if (menuItemId === 'addPdfParts') return t('Your plan does not include this feature')
        if (menuItemId === 'addFromQuickParts') return t('Your plan does not include this feature')
        if (menuItemId === 'addMiscellaneousItem') return t('Your plan does not include this feature')
        if (menuItemId === 'addFromPartsLibrary') {
            return !selectedCustomer
                ? t('Select a customer to enable their Part Library')
                : t('Your plan does not include this feature')
        }
    }

    useEffect(() => {
        miscNameRef.current = `Miscellaneous item ${miscItems?.length + 1}`
        miscItemsLengthRef.current = miscItems?.length
    }, [miscItems?.length])

    return (
        <>
            <Button
                aria-controls={open ? 'add-parts-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                color="primary"
                endIcon={<KeyboardArrowDown />}
                id="add-parts-button"
                variant="contained"
                disableElevation
                onClick={handleAddPartsButtonClick}
            >
                {isAdding ? t('Adding...') : t('Add parts')}
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="quote-actions-button-menu"
                MenuListProps={{
                    'aria-labelledby': 'quote-actions-button',
                }}
                open={open}
                onClose={handleAddPartsMenuClose}
            >
                {menuItems?.map((menuItem) =>
                    !isMenuItemHidden(menuItem?.id) ? (
                        isMenuItemDisabled(menuItem?.id) ? (
                            <TbxTooltip
                                key={`${menuItem?.id}-tooltip`}
                                title={menuItemDisabledMessage(menuItem?.id)}
                                arrow
                            >
                                <span>
                                    <MenuItem
                                        disabled={isMenuItemDisabled(menuItem?.id)}
                                        key={menuItem?.id}
                                        {...menuItem?.tagAttrs}
                                    >
                                        {t(menuItem?.label)}
                                    </MenuItem>
                                </span>
                            </TbxTooltip>
                        ) : (
                            <MenuItem
                                key={menuItem?.id}
                                {...menuItem?.tagAttrs}
                                disabled={isAdding}
                                onClick={() => menuItem?.handleOptionClick()}
                            >
                                {t(menuItem?.label)}
                            </MenuItem>
                        )
                    ) : (
                        ''
                    )
                )}
            </Menu>

            <AddQuoteItemsDialog
                open={interfaceToShow === '2d'}
                onClose={resetInterface}
            />

            <ThemeProvider theme={resolvedDarkTheme}>
                <Add3DPartsDialog
                    is3DTube={interfaceToShow === '3d-tube'}
                    open={interfaceToShow === '3d' || interfaceToShow === '3d-tube'}
                    onClose={resetInterface}
                />
                <AddPDFPartsDialog
                    open={interfaceToShow === 'pdf'}
                    onClose={resetInterface}
                />

                <AddQuickPartDialog
                    open={interfaceToShow === 'quickpart'}
                    onClose={resetInterface}
                />

                <AddFromPartLibrary
                    customer={selectedCustomer}
                    open={interfaceToShow === 'partlibrary'}
                    onClose={resetInterface}
                />
            </ThemeProvider>
        </>
    )
}

export default AddPartsButtonGroup
