import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
} from '@mui/material'
import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useSendInternalQuoteEmailMutation } from '@/app/services/quotes'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { RichContentEditor } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import { getFormattedPrice, Paths, PUBLIC_DOCUMENTS, QUOTE_PAYMENT_STATUS } from '@/common/utils'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EmailCustomerModal = ({ documentToSend, handleClose, open, quote, quoteContact }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const [sendInternalQuoteEmail] = useSendInternalQuoteEmailMutation()

    const { showPDFAttachmentCheckbox, showPayments } = useToolBoxTreatments()

    const [isSending, setIsSending] = useState(false)

    const organisationOwner = organisation?.organisationUsers?.find((user) => user.organisationUserRole === 'owner')

    const paymentsEnabled = organisation?.paymentsEnabledInternal

    const quotePaymentStatus = QUOTE_PAYMENT_STATUS[quote?.paymentStatus]

    const documentName = useMemo(() => {
        if (documentToSend === PUBLIC_DOCUMENTS.Quote) return 'quote'
        if (documentToSend === PUBLIC_DOCUMENTS.OrderConfirmation) return 'order confirmation'
        if (documentToSend === PUBLIC_DOCUMENTS.TaxInvoice) return 'tax invoice'
        if (documentToSend === PUBLIC_DOCUMENTS.ProformaInvoice) return 'proforma invoice'
    }, [documentToSend, PUBLIC_DOCUMENTS])

    const documentId = useMemo(() => {
        if (documentToSend === PUBLIC_DOCUMENTS.Quote) return quote?.name
        if (documentToSend === PUBLIC_DOCUMENTS.OrderConfirmation) return quote?.name
        if (documentToSend === PUBLIC_DOCUMENTS.TaxInvoice) return quote?.invoiceNumberText
        if (documentToSend === PUBLIC_DOCUMENTS.ProformaInvoice) return quote?.name
    }, [documentToSend, quote, PUBLIC_DOCUMENTS])

    const sharedLinkURL = useMemo(
        () => `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quote?.id}/${documentToSend}`,
        [quote, documentToSend]
    )

    const defaultSubject = useMemo(
        () =>
            `${t(capitalize(documentName))} ${documentId} ${t('from')} ${organisation?.name} ${t('for')} ${
                quote?.customerName
            }`,
        [quote, organisation, documentName, documentId]
    )

    const formattedQuotePrice = useMemo(
        () =>
            getFormattedPrice(quote?.lineTaxedPrice, organisation.currencyCode, organisation.locale, {
                useGrouping: true,
            }),
        [quote, organisation]
    )

    const defaultMessage = useMemo(
        () => `${t('Hi')} ${quoteContact?.name}, \n
${t(`Here's ${documentName}`)} ${documentId} ${t('for')} ${formattedQuotePrice}. \n
${showPayments && paymentsEnabled && quotePaymentStatus === QUOTE_PAYMENT_STATUS.Unpaid ? `${t(`View and pay your ${documentName} online`)}` : `${t(`View your ${documentName} online`)}:`} <a href="${sharedLinkURL}">${t('here')}</a> \n
${t('If you have any questions, please let us know.')} \n
${t('Regards')}, \n
${organisation?.name}`,
        [
            quoteContact?.name,
            documentName,
            documentId,
            formattedQuotePrice,
            organisation?.name,
            sharedLinkURL,
            showPayments,
            quotePaymentStatus,
            paymentsEnabled,
        ]
    )

    const { control, formState, getValues, reset } = useForm({
        mode: 'all',
        defaultValues: {
            to: quoteContact?.email,
            subject: defaultSubject,
            message: defaultMessage,
            from: organisation?.name || '',
            replyTo: organisation?.webStoreSupportEmail || organisationOwner?.user?.emailAddress || '',
            includePdf: showPDFAttachmentCheckbox,
        },
    })

    const handleCloseDialog = () => {
        reset({
            to: quoteContact?.email,
            subject: defaultSubject,
            message: defaultMessage,
            from: organisation?.name || '',
            replyTo: organisation?.webStoreSupportEmail || organisationOwner?.user?.emailAddress || '',
        })
        handleClose()
    }
    const handleSendEmailToCustomer = async () => {
        setIsSending(true)
        const { includePdf, message, subject, to } = getValues()
        try {
            await sendInternalQuoteEmail({
                organisationId: organisation.organisationId,
                quoteId: quote.id,
                subject,
                message,
                toEmailAddress: to,
                includePdf,
            })
            enqueueSnackbar(t('Email sent successfully'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to send email'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            setIsSending(false)
            handleClose()
        }
    }

    useEffect(() => {
        reset({
            to: quoteContact?.email,
            subject: defaultSubject,
            message: defaultMessage,
            from: organisation?.name || '',
            replyTo: organisation?.webStoreSupportEmail || organisationOwner?.user?.emailAddress || '',
            includePdf: showPDFAttachmentCheckbox,
        })
    }, [quote, quoteContact])

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleCloseDialog}
        >
            <DialogTitle>{t(`Send ${documentName}`)}</DialogTitle>
            <DialogContent style={{ width: '600px' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    style={{ gap: 20 }}
                >
                    <Controller
                        control={control}
                        name="to"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                // className={classes.input}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message || ''}
                                label={t('To')}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: true }}
                    />

                    <Controller
                        control={control}
                        name="from"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message || ''}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                label={t('From')}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="replyTo"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message || ''}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                label={t('Reply to')}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="subject"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                // className={classes.input}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message || ''}
                                label={t('Subject')}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: true }}
                    />
                    {showPDFAttachmentCheckbox ? (
                        <FormControlLabel
                            control={
                                <Controller
                                    control={control}
                                    name="includePdf"
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox
                                            checked={Boolean(value) || false}
                                            color="primary"
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            }
                            label={t('Include PDF as attachment')}
                        />
                    ) : null}

                    <Controller
                        control={control}
                        name="message"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <RichContentEditor
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message || ''}
                                label={t('Message')}
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: true }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    disabled={isSending || !formState.isValid}
                    variant="contained"
                    onClick={handleSendEmailToCustomer}
                >
                    {isSending ? t('Sending...') : t('Send')}
                    {isSending ? (
                        <CircularProgress
                            size={20}
                            sx={{ ml: 2 }}
                        />
                    ) : null}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

EmailCustomerModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    documentToSend: PropTypes.string,
    quote: PropTypes.object,
    quoteContact: PropTypes.object,
}

export default EmailCustomerModal
