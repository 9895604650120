import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetDeliveryContainersQuery } from '@/app/services/deliveryContainers'
import { useUpdateDeliveryZoneMutation } from '@/app/services/deliveryZones'
import { selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import CurrencyInput from '@/common/components/CurrencyInput/CurrencyInput'

const AssignContainerDialog = ({ deliveryPricingOption, onClose, open, zone, zoneContainer, zoneContainers }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const isImperial = useSelector(selectUseImperialUnits)

    const { data: deliveryContainers } = useGetDeliveryContainersQuery({
        organisationId,
    })

    const containersOptions = zoneContainer
        ? deliveryContainers
        : deliveryContainers?.filter((container) => {
              return !zoneContainers.some((zoneContainer) => zoneContainer.containerId === container.containerId)
          })

    const [updateZone, { isLoading }] = useUpdateDeliveryZoneMutation()

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            zoneId: zone?.zoneId,
            containerId: zoneContainer?.containerId || null,
            flatPrice: zoneContainer?.flatPrice || null,
            pricePerDistance: zoneContainer?.pricePerDistance || null,
            pricePerWeight: zoneContainer?.pricePerWeight || null,
            isEnabled: zoneContainer?.isEnabled || true,
            zoneContainerId: zoneContainer?.zoneContainerId || null,
        },
    })

    const handleUpdateButtonClick = async (zoneContainerData, _e) => {
        try {
            await updateZone({
                organisationId,
                zone: { ...zone, zoneContainers: [...zoneContainers, zoneContainerData] },
            }).unwrap()
            enqueueSnackbar(t('Delivery container updated successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to update delivery container'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            reset()
        }

        onClose()
    }

    const closeDialog = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 2,
                    p: 2,
                    width: 'fit-content',
                    maxWidth: 350,
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                {zoneContainer ? (
                    <Typography variant="h6">
                        {t('Update container prices for {{zoneName}}', { zoneName: zone.name })}
                    </Typography>
                ) : (
                    <Typography variant="h6">
                        {t('Assign a container to {{zoneName}}', { zoneName: zone.name })}
                    </Typography>
                )}

                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mb={2}
                >
                    <Controller
                        control={control}
                        name="containerId"
                        render={({ field: { onBlur, onChange, value } }) => (
                            <FormControl
                                color="primary"
                                disabled={zoneContainer?.containerId}
                                sx={{ minWidth: 250 }}
                                variant="standard"
                                required
                            >
                                <InputLabel id="select-container-label">{t('Container to assign')}</InputLabel>
                                <Select
                                    id="select-container"
                                    label={t('Container to assign')}
                                    labelId="select-container-label"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                >
                                    <MenuItem
                                        value=""
                                        disabled
                                    >
                                        <em>{t('Select a container')}</em>
                                    </MenuItem>
                                    {containersOptions?.map((container) => (
                                        <MenuItem
                                            key={container.containerId}
                                            value={container.containerId}
                                        >
                                            {container.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {t('Select the container you want to assign to the selected zone')}
                                </FormHelperText>
                            </FormControl>
                        )}
                        rules={{ required: 'Required' }}
                    />

                    <Controller
                        control={control}
                        name="flatPrice"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <CurrencyInput
                                customPlaceholder={0}
                                error={fieldState.error}
                                helperText={fieldState.error?.message || t('Flat price for container')}
                                InputLabelProps={{
                                    'data-testid': 'flat-price-label',
                                    shrink: true,
                                }}
                                inputProps={{
                                    'data-testid': 'flat-price-field',
                                }}
                                label={t("Zone's Flat price")}
                                required={true}
                                size="medium"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                        shouldUnregister
                    />

                    {deliveryPricingOption === 'ByDistance' ? (
                        <Controller
                            control={control}
                            name="pricePerDistance"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <CurrencyInput
                                    customPlaceholder={0}
                                    error={fieldState.error}
                                    helperText={fieldState.error?.message || t('Price per distance for container')}
                                    InputLabelProps={{
                                        'data-testid': 'price-per-distance-label',
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        'data-testid': 'price-per-distance-field',
                                    }}
                                    label={isImperial ? t("Zone's per mile rate") : t("Zone's per kilometre rate")}
                                    required={true}
                                    size="medium"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: 'Required' }}
                            shouldUnregister
                        />
                    ) : null}

                    {deliveryPricingOption === 'ByWeight' ? (
                        <Controller
                            control={control}
                            name="pricePerWeight"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <CurrencyInput
                                    customPlaceholder={0}
                                    error={fieldState.error}
                                    helperText={fieldState.error?.message || t('Price per weight for container')}
                                    InputLabelProps={{
                                        'data-testid': 'price-per-weight-label',
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        'data-testid': 'price-per-weight-field',
                                    }}
                                    label={isImperial ? t("Zone's per pound rate") : t("Zone's per kilogram rate")}
                                    required={true}
                                    size="medium"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: 'Required' }}
                            shouldUnregister
                        />
                    ) : null}
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isDirty || !isValid || isLoading}
                    key="assign"
                    variant="contained"
                    onClick={handleSubmit(handleUpdateButtonClick)}
                >
                    {zoneContainer
                        ? isLoading
                            ? t('Updating...')
                            : t('Update container')
                        : isLoading
                          ? t('Assigning...')
                          : t('Assign container')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AssignContainerDialog.defaultProps = {
    open: false,
}

AssignContainerDialog.propTypes = {
    deliveryPricingOption: PropTypes.string,
    open: PropTypes.bool,
    zone: PropTypes.object,
    zoneContainer: PropTypes.object,
    zoneContainers: PropTypes.array,
    onClose: PropTypes.func,
}

export default AssignContainerDialog
