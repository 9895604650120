import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ArrowDataTransferHorizontalIcon, ArrowDataTransferVerticalIcon } from '@hugeicons/react'
import { Delete, DragHandle, ExpandMore, HowToRegRounded, LineWeightRounded, Undo } from '@mui/icons-material'
import { AccordionSummary, Box, Checkbox, CircularProgress, IconButton, Link, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetMaterialsQuery } from '@/app/services/materials'
import {
    useDeleteQuoteItemMutation,
    useGetQuoteItemsQuery,
    useUpdateQuoteItemsAttributesMutation,
} from '@/app/services/quoteItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisation, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import {
    addSelectedItem,
    removeCollapsedItem,
    removeSelectedItem,
    selectCollapsedItems,
    selectedQuoteStatus,
    selectIsCalculatingQuote,
    selectSelectedItems,
    setOpenPartQuoteHistoryModal,
    setSelectedPartFromLibrary,
    setSelectedQuoteStatus,
} from '@/app/slices/quoteItemsSlice'
import { ThumbnailImage } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import FixedPriceIcon from '@/common/icons/FixedPriceIcon/FixedPriceIcon'
import LibraryIcon from '@/common/icons/LibraryIcon/LibraryIcon'
import { QUOTE_STATUS } from '@/common/utils'
import TbxDialog from '@/features/web-store/components/shared/TbxDialog'
import { formatCurrency } from '@/features/web-store/helpers/utilities'

import QuoteItemStatus from '../../common/QuoteItemStatus'

import ItemName from './ItemName'

const classes = {
    summaryRoot: {
        height: 74,
        backgroundColor: 'accordionSummary.main',
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            margin: '12px 0',
            '&.Mui-expanded': {
                margin: '12px 0',
            },
        },
    },
    AccordionSummaryLeft: {
        gap: 1.5,
    },

    AccordionSummaryRight: {
        gap: 6,
    },

    summaryThumbnailWrapper: {
        height: '50px',
        width: '50px',
    },

    summaryThumbnailImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },

    itemSummaryCollapsed: {
        gap: 5,
    },
    itemSummaryCollapsed_LinePrice: {
        width: '70px',
    },
    smallText: {
        fontSize: '0.75rem',
    },

    iconColor: { color: 'text.secondary' },
}

const ItemSummary = ({ hasErrors, hasIssues, hasWarnings, isUnset, listeners, quoteItemId, setActivatorNodeRef }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const { showGrainDirection } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const organisationSettings = useSelector(selectOrganisation)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const hidePrices = useSelector(selectedQuoteStatus) === QUOTE_STATUS.NotCalculated

    const selectedItemsArray = useSelector(selectSelectedItems)

    const collapsedItemsArray = useSelector(selectCollapsedItems)
    const isCalculating = useSelector(selectIsCalculatingQuote)

    const { data: materials, isLoading: isLoadingMaterials } = useGetMaterialsQuery({ organisationId })

    const { data: selectedQuote, isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [deleteQuoteItem, { isLoading: isDeleting }] = useDeleteQuoteItemMutation()
    const [updateQuote] = useUpdateQuoteMutation()
    const [updateQuoteItems] = useUpdateQuoteItemsAttributesMutation()

    const [isSelected, setIsSelected] = useState(selectedItemsArray?.includes(quoteItem?.id))

    const [isCollapsed, setIsCollapsed] = useState(collapsedItemsArray?.includes(quoteItem?.id))

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const unit = useImperialUnits ? 'in' : 'mm'

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)
    const hasFixedPrice = Boolean(quoteItem?.partLibraryEntry?.fixedPrice)
    const isFixedPriceExpired = useMemo(() => {
        if (!quoteItem?.partLibraryEntry?.fixedPriceEndTime) return false
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const fixedPriceEndTime = new Date(quoteItem?.partLibraryEntry?.fixedPriceEndTime).setHours(0, 0, 0, 0)

        return currentDate > fixedPriceEndTime
    }, [quoteItem?.partLibraryEntry?.fixedPriceEndTime])

    const quoteMaterial = useMemo(() => {
        if (isLoadingMaterials) return null
        return materials[quoteItem?.materialId]
    }, [materials, quoteItem?.materialId])

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleResetButtonClick = async (event) => {
        event.stopPropagation()

        try {
            const quoteItems = {
                quoteItemIds: [quoteItem?.id],
                attributes: {
                    materialId: null,
                    thickness: null,
                    cuttingTechnologyId: null,
                    sheetId: null,
                },
            }
            await updateQuoteItems({ organisationId, quoteId, quoteItems }).unwrap()
            enqueueSnackbar(`${quoteItem?.name} ${t('reset')}`, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(`${t('Failed to reset')} ${quoteItem?.name} from quote`, {
                variant: 'error',
                autoHideDuration: 5000,
            })
        }
    }

    const handleDeleteDialogOpen = (event) => {
        event.stopPropagation()
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const handleRemoveQuoteItem = async () => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        }
        try {
            await deleteQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
            }).unwrap()
            dispatch(removeCollapsedItem(quoteItem?.id))
            dispatch(removeSelectedItem(quoteItem?.id))
            enqueueSnackbar(`${quoteItem?.name} ${t('deleted')}`, {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(`${t('Failed to delete')} ${quoteItem?.name} from quote`, {
                variant: 'error',
            })
        } finally {
            setIsDeleteDialogOpen(false)
        }
    }

    const handleSelectionChange = () => {
        setIsSelected(!isSelected)
        if (!isSelected) {
            dispatch(addSelectedItem(quoteItem?.id))
        } else {
            dispatch(removeSelectedItem(quoteItem?.id))
        }
    }

    const handleOpenPartQuoteHistoryDialog = (e) => {
        e.stopPropagation()
        dispatch(
            setSelectedPartFromLibrary({
                partId: quoteItem.partId,
                partLibraryId: quoteItem.partLibraryEntryId,
                name: quoteItem.name,
            })
        )
        dispatch(setOpenPartQuoteHistoryModal(true))
    }

    useEffect(() => {
        if (selectedItemsArray.includes(quoteItem?.id)) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [selectedItemsArray, quoteItem?.id])

    useEffect(() => {
        setIsCollapsed(collapsedItemsArray.includes(quoteItem?.id))
    }, [collapsedItemsArray, quoteItem?.id])

    return (
        <>
            <AccordionSummary
                aria-controls={`panel${quoteItem?.id}-content`}
                aria-label="Expand"
                expandIcon={<ExpandMore />}
                id={`panel${quoteItem?.id}-header`}
                sx={classes.summaryRoot}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryLeft}
                >
                    <Typography variant="body1">{quoteItem?.index + 1}</Typography>

                    <IconButton
                        aria-label="Drag"
                        color="secondary"
                        size="small"
                        {...listeners}
                        ref={setActivatorNodeRef}
                        sx={{ cursor: 'grab' }}
                    >
                        <DragHandle />
                    </IconButton>

                    <Checkbox
                        checked={isSelected}
                        color="primary"
                        inputProps={{
                            'data-testid': 'select-quote-item',
                        }}
                        size="small"
                        sx={{ p: 0 }}
                        onChange={handleSelectionChange}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Box
                        alignItems="center"
                        display="flex"
                        gap={2}
                    >
                        {isUnset ? (
                            <QuoteItemStatus
                                messages="Missing settings to calculate part"
                                status="unset"
                            />
                        ) : null}

                        {!hasIssues && !isUnset ? <QuoteItemStatus /> : null}

                        {hasIssues && hasWarnings && !isUnset ? (
                            <QuoteItemStatus
                                messages={hasWarnings}
                                status="warning"
                            />
                        ) : null}
                        {hasIssues && hasErrors && !isUnset ? (
                            <QuoteItemStatus
                                messages={hasErrors}
                                status="error"
                            />
                        ) : null}

                        {isCollapsed ? (
                            <ThumbnailImage
                                uri={quoteItem?.thumbnailUri}
                                wrapperHeight={50}
                                wrapperWidth={50}
                            />
                        ) : null}

                        <ItemName
                            id={quoteItem?.id}
                            isPartFromLibrary={isPartFromLibrary}
                            name={quoteItem?.name}
                        />
                    </Box>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryRight}
                >
                    {isCalculating || isFetchingQuote ? (
                        <Box
                            alignItems="center"
                            data-testid="calculating-header"
                            display="flex"
                            justifyContent="center"
                        >
                            <Typography
                                color="secondary"
                                letterSpacing={1}
                                mr={2}
                                textTransform={'uppercase'}
                                variant="strong2"
                            >
                                {isCalculating ? t('Calculating') : t('Loading')}
                            </Typography>
                            <CircularProgress
                                color="secondary"
                                size={24}
                            />
                        </Box>
                    ) : null}

                    {!isCollapsed && isPartFromLibrary ? (
                        <>
                            {hasFixedPrice ? (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    style={{ gap: '4px' }}
                                >
                                    <FixedPriceIcon
                                        fontSize="small"
                                        sx={isFixedPriceExpired ? { color: 'error.main' } : classes.iconColor}
                                    />
                                    <Typography
                                        color={isFixedPriceExpired ? 'error.main' : 'text.secondary'}
                                        variant="body2"
                                    >
                                        {isFixedPriceExpired ? t('Fixed price expired') : t('Fixed price')}
                                    </Typography>
                                </Box>
                            ) : null}

                            <Box
                                alignItems="center"
                                display="flex"
                                style={{ gap: '4px' }}
                            >
                                <LibraryIcon
                                    fontSize="small"
                                    sx={classes.iconColor}
                                />
                                <Link
                                    color="text.secondary"
                                    variant="body2"
                                    onClick={(event) => handleOpenPartQuoteHistoryDialog(event)}
                                >
                                    {t('Part from library')}
                                </Link>
                            </Box>
                        </>
                    ) : null}

                    {!hasErrors && isCollapsed && !isCalculating && !isFetchingQuote ? (
                        <Box
                            display="flex"
                            sx={classes.itemSummaryCollapsed}
                        >
                            {isPartFromLibrary ? (
                                <Box
                                    alignItems="flex-end"
                                    className="itemSummaryCollapsed_Thumbnail"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {t('Part ID:')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        gap={1}
                                    >
                                        {hasFixedPrice ? (
                                            <FixedPriceIcon
                                                data-testid="fixed-price-icon"
                                                fontSize="small"
                                                sx={[isFixedPriceExpired ? { color: 'error.main' } : classes.iconColor]}
                                            />
                                        ) : null}
                                        <LibraryIcon
                                            data-testid="part-library-icon"
                                            fontSize="small"
                                            sx={classes.iconColor}
                                        />
                                        <Link
                                            color="text.secondary"
                                            variant="body2"
                                            onClick={(event) => handleOpenPartQuoteHistoryDialog(event)}
                                        >
                                            {quoteItem?.partId}
                                        </Link>
                                    </Box>
                                </Box>
                            ) : null}

                            {showGrainDirection && quoteItem?.grainDirection !== 'None' ? (
                                <Box
                                    alignItems="flex-end"
                                    className="itemSummaryCollapsed_GrainDirection"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {t('Grain direction')}
                                    </Typography>

                                    {quoteItem?.grainDirection === 'Horizontal' ? (
                                        <ArrowDataTransferHorizontalIcon
                                            color="textSecondary"
                                            size={20}
                                        />
                                    ) : (
                                        <ArrowDataTransferVerticalIcon
                                            color="textSecondary"
                                            size={20}
                                        />
                                    )}
                                </Box>
                            ) : null}

                            {quoteItem?.materialId ? (
                                <Box
                                    alignItems="flex-end"
                                    className="itemSummaryCollapsed_LinePrice"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {quoteMaterial?.materialName}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        gap={1}
                                    >
                                        {quoteItem?.isCustomerSuppliedMaterial ? (
                                            <HowToRegRounded
                                                fontSize="small"
                                                sx={classes.iconColor}
                                            />
                                        ) : null}
                                        <Box
                                            display="flex"
                                            gap={0.5}
                                        >
                                            <LineWeightRounded
                                                fontSize="small"
                                                sx={classes.iconColor}
                                            />
                                            <Typography variant="body2">
                                                {quoteItem?.thickness} {unit}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : null}
                            <Box
                                alignItems="flex-end"
                                className="itemSummaryCollapsed_Quantity"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Quantity')}
                                </Typography>
                                <Typography variant="body2">x {quoteItem?.quantity}</Typography>
                            </Box>
                            <Box
                                alignItems="flex-end"
                                className="itemSummaryCollapsed_UnitPrice"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Unit price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!hidePrices && !isCalculating ? (
                                    <Typography variant="body2">
                                        {quoteItem?.itemPrice
                                            ? formatCurrency(
                                                  quoteItem?.itemPrice,
                                                  organisationSettings.currencyCode,
                                                  organisationSettings.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {String.fromCharCode(8212)}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                alignItems="flex-end"
                                display="flex"
                                flexDirection="column"
                                sx={classes.itemSummaryCollapsed_LinePrice}
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Line price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!hidePrices && !isCalculating ? (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {quoteItem?.linePrice
                                            ? formatCurrency(
                                                  quoteItem?.linePrice,
                                                  organisationSettings.currencyCode,
                                                  organisationSettings.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {String.fromCharCode(8212)}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ) : null}

                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        {hasErrors ? (
                            <IconButton
                                aria-label="reset"
                                color="secondary"
                                onClick={(event) => handleResetButtonClick(event)}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                <Undo />
                            </IconButton>
                        ) : null}

                        <IconButton
                            aria-label="delete"
                            color="secondary"
                            data-testid="delete-quote-item-name"
                            onClick={(event) => handleDeleteDialogOpen(event)}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            </AccordionSummary>
            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Yes, delete')}
                content={`${t('Are you sure you want to delete part')} ${quoteItem?.name}`}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleRemoveQuoteItem}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={quoteItemId}
                title={`${t('Delete part')} ${quoteItem?.name}?`}
            />
        </>
    )
}

ItemSummary.propTypes = {
    hasErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    hasIssues: PropTypes.bool,
    hasWarnings: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    isUnset: PropTypes.bool,
    listeners: PropTypes.object,
    quoteItemId: PropTypes.string,
    setActivatorNodeRef: PropTypes.func,
}

export default memo(ItemSummary)
