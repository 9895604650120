import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PostAdd, SaveAlt } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useLazyExportRatesQuery } from '@/app/services/rates'
import { useGetRateTableQuery } from '@/app/services/rateTables'
import { selectUserRole } from '@/app/slices/appSlice'
import { selectCurrencySymbol, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import {
    clearSelectedRates,
    selectSelectedRate,
    selectSelectedRates,
    setSelectedRate,
    setSelectedRates,
} from '@/app/slices/ratesSlice'
import TbxDrawer from '@/common/components/TbxDrawer/TbxDrawer'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    browserFileDownload,
    currentDateTimeString,
    PARTS_PER_PAGE_DEFAULT_VALUE,
    PARTS_PER_PAGE_OPTIONS,
    PARTS_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AddRateForm from './AddRateForm'
import ImportRates from './ImportRates'
import RatesBulkEdit from './RatesBulkEdit'
import RatesTableRow from './RatesTableRow'

const classes = {
    titleColumn: {
        width: '180px',
    },
    actionButtonsColumn: {
        width: '80px',
    },
    headerTableCell: {
        padding: '6px 10px',
        verticalAlign: 'bottom',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        alignItems: 'center',
        color: '#9FA2B4',
        wordBreak: 'break-word',
    },
    emptyTableMessage: {
        padding: '6px 10px',
        verticalAlign: 'bottom',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        alignItems: 'center',
        color: '#9FA2B4',
        borderBottom: 'none',
    },
    unitsColumn: {
        fontSize: '0.85rem',
        color: '#888',
        cursor: 'pointer',
        padding: '0 10px 10px',
        borderBottom: '2px solid',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        background: (theme) => theme.palette.background.paper,
        border: '1px solid rgba(52, 73, 94, 0.12)',
        boxSizing: 'border-box',
        borderRadius: '0px 0px 8px 8px',
        borderTop: 'none',
    },
    headerTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerTitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },
    headerSubtitle: {
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
        color: '#5E7387',
    },
    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
    tableContainer: {
        paddingBottom: '40px',
    },
    table: {
        background: (theme) => theme.palette.background.paper,
        tableLayout: 'fixed',
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderBottom: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0 0',
        borderCollapse: 'separate',
    },
    tablePaginationCaption: {
        fontSize: '12px',
        color: '#5E7387',
    },
    fineCuttingTitle: {
        fontWeight: 500,
        color: 'text.primary',
        textTransform: 'uppercase',
    },
    fineCuttingBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    fineCuttingBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
    importButton: {
        margin: '0 16px',
    },
}

const RatesTable = ({ selectedRateTableId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const [readOnly, setReadOnly] = useState(true)
    const userRole = useSelector(selectUserRole)

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const currencySymbol = useSelector(selectCurrencySymbol)

    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })

    const { data: selectedRateTable } = useGetRateTableQuery({
        organisationId,
        rateTableId: selectedRateTableId,
    })

    const [exportRates] = useLazyExportRatesQuery()

    const selectedRates = useSelector(selectSelectedRates)
    const selectedRate = useSelector(selectSelectedRate)
    const cuttingTech = cuttingTechnologies[selectedRateTable?.cuttingTechnologyId]

    const rates = useMemo(() => {
        if (!selectedRateTable?.rates) return []
        return selectedRateTable.rates
    }, [selectedRateTable])

    const { showCsvImportExportButtons } = useToolBoxTreatments()

    const [showImportModal, setShowImportModal] = useState(false)
    const [showAddRateDrawer, setShowAddRateDrawer] = useState(false)

    const getFooterStyle = () => {
        return {
            bottom: 0,
            width: '100%',
            zIndex: 1200,
            opacity: 1,
        }
    }

    const [currentPage, setCurrentPage] = useState(0)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useLocalStorage(PARTS_PER_PAGE_VARIABLE, PARTS_PER_PAGE_DEFAULT_VALUE)

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = useCallback((a, b, orderBy) => {
        let first = a[orderBy]
        let next = b[orderBy]
        if (orderBy === '') {
            first = a.thickness
            next = b.thickness
        } else {
            first = a[orderBy]
            next = b[orderBy]
        }

        switch (typeof first) {
            case 'number':
                if (next < first) return -1
                if (next > first) return 1
                break
            case 'string':
                return next?.localeCompare(first, undefined, { sensitivity: 'base' })
            default:
                return 0
        }
        return 0
    }, [])

    const getComparator = useCallback(
        (order, orderBy) => {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        },
        [descendingComparator]
    )

    const createColumnHeader = (columnId, columnTitle, columnSubtitle, isSortable) => (
        <TableSortLabel
            active={isSortable ? orderBy === columnId : null}
            direction={isSortable && orderBy === columnId ? order : 'asc'}
            hideSortIcon={!isSortable}
            sx={classes.sortLabel}
            onClick={
                isSortable
                    ? () => {
                          handleRequestSort(columnId)
                      }
                    : null
            }
        >
            <Box sx={classes.headerTextContainer}>
                <Typography sx={classes.headerTitle}>{columnTitle}</Typography>
                {columnSubtitle ? <Typography sx={classes.headerSubtitle}>{columnSubtitle}</Typography> : null}
            </Box>
        </TableSortLabel>
    )

    const handleCheckboxClick = (event) => {
        const { checked } = event.target
        if (checked) {
            dispatch(setSelectedRates(rows))
        } else {
            dispatch(clearSelectedRates())
        }
    }

    const allRatesSelected = useMemo(() => {
        if (!rows || !rows.length || !selectedRates || !selectedRates.length) return false
        return selectedRates.length === rows.length
    }, [rows, selectedRates])

    const handleImportClick = () => {
        setShowImportModal(true)
    }

    const handleExportClick = async () => {
        const rateTableName = selectedRateTable?.name
        const cuttingTechType = cuttingTech.name

        try {
            const response = await exportRates({ organisationId, rateTableId: selectedRateTableId }).unwrap()

            const filename = `${cuttingTechType}-${rateTableName}-Rates-${currentDateTimeString()}.csv`

            browserFileDownload(response, filename, 'text/csv')

            enqueueSnackbar(t('Rates exported successfully.'), { variant: 'success' })
        } catch (error) {
            console.error('error', error)
            enqueueSnackbar(t('Failed to export rates.'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDrawerClose = () => {
        dispatch(setSelectedRate(null))
        setShowAddRateDrawer(false)
    }

    const handleEditRateClick = (rate) => {
        dispatch(setSelectedRate(rate))
        setShowAddRateDrawer(!showAddRateDrawer)
    }
    const titleHeaderCellStyle = Object.assign({}, classes.titleColumn, classes.headerTableCell)
    const fineCuttingLeftCellStyle = Object.assign({}, classes.fineCuttingBorderLeft, classes.headerTableCell)
    const fineCuttingRightCellStyle = Object.assign({}, classes.fineCuttingBorderRight, classes.headerTableCell)
    const actionButtonsHeaderCellStyle = Object.assign({}, classes.actionButtonsColumn, classes.headerTableCell)

    useEffect(() => {
        setReadOnly(userRole !== 'administrator' && userRole !== 'owner')
    }, [userRole])

    useEffect(() => {
        if (rates) {
            setRows(
                stableSort(
                    rates.filter((rate) => !rate.isDeleted),
                    getComparator(order, orderBy)
                )
            )
        }
    }, [order, orderBy, stableSort, rates, getComparator])

    return (
        <TableContainer sx={classes.tableContainer}>
            <Table sx={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '30px' }}>
                            <Checkbox
                                checked={allRatesSelected}
                                onChange={handleCheckboxClick}
                            />
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="thickness-column-header"
                            style={{ width: '90px' }}
                            sx={titleHeaderCellStyle}
                        >
                            {createColumnHeader('thickness', t('Thickness'), useImperialUnits ? 'in' : 'mm', true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="web-column-header"
                            style={{ width: '80px' }}
                            sx={titleHeaderCellStyle}
                        >
                            {createColumnHeader('web', t('Web'), useImperialUnits ? 'in' : 'mm', true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="cutting-feed-rate-column-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader(
                                'cuttingFeedRate',
                                t('Cutting feed rate'),
                                useImperialUnits ? 'in/min' : 'mm/min',
                                true
                            )}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="pierce-time-column-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('pierceTime', t('Pierce time'), t('seconds'), true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="speed-threshold-column-header"
                            style={{ width: '128px' }}
                            sx={fineCuttingLeftCellStyle}
                        >
                            {createColumnHeader(
                                'fineSpeedThreshold',
                                t('Speed diameter'),
                                useImperialUnits ? 'in' : 'mm',
                                true
                            )}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="cutting-rate-column-header"
                            style={{ width: '120px' }}
                            sx={classes.headerTableCell}
                        >
                            <Typography
                                data-testid="fine-cutting-group-header"
                                sx={classes.fineCuttingTitle}
                                variant="body2"
                            >
                                {t('Fine Cutting')}
                            </Typography>
                            {createColumnHeader(
                                'fineCuttingRate',
                                t('Cutting rate'),
                                useImperialUnits ? 'in/min' : 'mm/min',
                                true
                            )}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="fine-pierce-time-column-header"
                            style={{ width: '120px' }}
                            sx={fineCuttingRightCellStyle}
                        >
                            {createColumnHeader('finePierceTime', t('Pierce time'), t('seconds'), true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="small-hole-limit-column-header"
                            style={{ width: '120px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader(
                                'smallHolesLimit',
                                t('Small holes limit'),
                                useImperialUnits ? 'in' : 'mm',
                                true
                            )}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="hourly-rate-multiplier-column-header"
                            style={{ width: '120px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('hourlyRateMultiplier', t('Hourly rate multiplier'), null, true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            data-testid="applied-hourly-rate-column-header"
                            style={{ width: '100px' }}
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader(
                                'hourlyRateMultiplier',
                                t('Applied hourly rate'),
                                currencySymbol + '/hr',
                                true
                            )}
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={actionButtonsHeaderCellStyle}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(!!rows &&
                        rows.length > 0 &&
                        rows?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((rate) => {
                            return (
                                <RatesTableRow
                                    disabled={readOnly || selectedRateTable?.isDeleted}
                                    key={rate.rateId}
                                    rate={rate}
                                    rateTableBaseHourlyRate={selectedRateTable?.baseHourlyRatePrice}
                                    onEditClick={handleEditRateClick}
                                />
                            )
                        })) || (
                        <TableRow>
                            <TableCell
                                align="center"
                                colSpan={11}
                                sx={classes.emptyTableMessage}
                            >
                                <Typography>{t('Add a thickness for this rate table.')}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Box sx={classes.tableFooter}>
                {showCsvImportExportButtons ? (
                    <>
                        <Button
                            color="secondary"
                            data-testid="import-button"
                            size="small"
                            startIcon={<PostAdd />}
                            sx={classes.importButton}
                            variant="outlined"
                            onClick={handleImportClick}
                        >
                            {t('Import')}
                        </Button>
                        <Button
                            color="secondary"
                            data-testid="export-button"
                            size="small"
                            startIcon={<SaveAlt />}
                            variant="outlined"
                            onClick={handleExportClick}
                        >
                            {t('Export')}
                        </Button>
                    </>
                ) : null}
                <TablePagination
                    classes={{
                        caption: classes.tablePaginationCaption,
                    }}
                    component="div"
                    count={rows?.length}
                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                    labelRowsPerPage={t('Thicknesses per page')}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                    style={getFooterStyle()}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <TbxDrawer
                showDrawer={showAddRateDrawer}
                onClose={handleDrawerClose}
            >
                <AddRateForm
                    rate={selectedRate}
                    selectedRateTableId={selectedRateTableId}
                    onCancel={handleDrawerClose}
                />
            </TbxDrawer>
            <RatesBulkEdit />
            {showImportModal ? <ImportRates onClose={() => setShowImportModal(false)} /> : null}
        </TableContainer>
    )
}

RatesTable.propTypes = {
    selectedRateTableId: PropTypes.string,
}

export default RatesTable
