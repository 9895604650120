import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import allCountries from 'country-region-data'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateTaxRateMutation, useUpdateTaxRateMutation } from '@/app/services/taxRates'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { fractionToPercentage, percentageToFraction } from '@/common/helpers/formatUtilities'

const classes = {}

const AddTaxRateDialog = ({ onClose, open, taxRate }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [createTaxRate, { isLoading: isCreating }] = useCreateTaxRateMutation()
    const [updateTaxRate, { isLoading: isUpdating }] = useUpdateTaxRateMutation()

    const [selectedCountry, setSelectedCountry] = useState(taxRate?.country?.toUpperCase() ?? '')

    const {
        control,
        formState: { isValid },

        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            displayName: taxRate?.displayName ?? '',
            country: taxRate?.country?.toUpperCase() ?? '',
            state: taxRate?.state?.toUpperCase() ?? '',
            percentage: taxRate?.percentage ? fractionToPercentage(taxRate?.percentage) : '',
            active: taxRate?.active,
        },
    })

    const unitedStatesRegions = allCountries.find((country) => country?.countryShortCode === 'US').regions
    const canadianRegions = allCountries.find((country) => country?.countryShortCode === 'CA').regions

    const handleAddOrUpdateButtonClick = async (userData, _e) => {
        const { country, displayName, percentage, state } = userData

        // if taxRate exists, update it instead of creating a new one
        if (taxRate) {
            try {
                await updateTaxRate({
                    organisationId,
                    taxRate: { id: taxRate.id, displayName },
                }).unwrap()
                enqueueSnackbar(t('Tax rate updated successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to update tax rate'), { variant: 'error', autoHideDuration: 5000 })
            } finally {
                reset()
            }
        } else {
            try {
                await createTaxRate({
                    organisationId,
                    taxRate: {
                        country,
                        state,
                        displayName,
                        percentage: percentageToFraction(percentage, 6),
                        active: true,
                        inclusive: false,
                        isDefault: false,
                    },
                }).unwrap()
                enqueueSnackbar(t('Tax rate added successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to add tax rate'), { variant: 'error', autoHideDuration: 5000 })
            } finally {
                reset()
            }
        }

        onClose()
    }

    const closeDialog = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: 350,
                    borderRadius: 2,
                    p: 2,
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                {taxRate ? (
                    <Typography variant="h6">{t('Edit tax rate')}</Typography>
                ) : (
                    <Typography variant="h6">{t('Add tax rate')}</Typography>
                )}
                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Controller
                        control={control}
                        name="displayName"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Display name') + ' *'}
                                value={value}
                                autoFocus
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />

                    <Controller
                        control={control}
                        name="country"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <Autocomplete
                                className={classes.autoComplete}
                                disableClearable={true}
                                disabled={taxRate}
                                getOptionKey={(option) => option?.countryShortCode || ''}
                                getOptionLabel={(option) => option?.countryName || ''}
                                isOptionEqualToValue={(option, value) => option?.countryShortCode === value}
                                options={allCountries}
                                renderInput={(params) => (
                                    <TextField
                                        error={fieldState.invalid}
                                        helperText={fieldState.error?.message || ''}
                                        {...params}
                                        InputLabelProps={{
                                            sx: classes.inputLabel,
                                            'data-testid': 'organisation-country-label',
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            'data-testid': 'organisation-country-input',
                                        }}
                                        label={t('Country') + ' *'}
                                        fullWidth
                                    />
                                )}
                                value={allCountries.find((country) => country?.countryShortCode === value) || null}
                                autoHighlight
                                onBlur={onBlur}
                                onChange={(e, newValue) => {
                                    setSelectedCountry(newValue?.countryShortCode)
                                    onChange(newValue?.countryShortCode)
                                }}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />

                    {selectedCountry === 'US' ? (
                        <Controller
                            control={control}
                            name="state"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <Autocomplete
                                    className={classes.autoComplete}
                                    disableClearable={true}
                                    disabled={taxRate}
                                    getOptionKey={(option) => option?.shortCode || ''}
                                    getOptionLabel={(option) => option?.name || ''}
                                    isOptionEqualToValue={(option, value) => option?.shortCode === value}
                                    options={unitedStatesRegions}
                                    renderInput={(params) => (
                                        <TextField
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            {...params}
                                            InputLabelProps={{
                                                sx: classes.inputLabel,
                                                'data-testid': 'organisation-country-label',
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                'data-testid': 'organisation-country-input',
                                            }}
                                            label={t('Region')}
                                            fullWidth
                                        />
                                    )}
                                    value={unitedStatesRegions.find((country) => country?.shortCode === value) || null}
                                    autoHighlight
                                    onBlur={onBlur}
                                    onChange={(e, newValue) => {
                                        onChange(newValue?.shortCode)
                                    }}
                                />
                            )}
                            shouldUnregister
                        />
                    ) : null}

                    {selectedCountry === 'CA' ? (
                        <Controller
                            control={control}
                            name="state"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <Autocomplete
                                    className={classes.autoComplete}
                                    disableClearable={true}
                                    disabled={taxRate}
                                    getOptionKey={(option) => option?.shortCode || ''}
                                    getOptionLabel={(option) => option?.name || ''}
                                    isOptionEqualToValue={(option, value) => option?.shortCode === value}
                                    options={canadianRegions}
                                    renderInput={(params) => (
                                        <TextField
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            {...params}
                                            InputLabelProps={{
                                                sx: classes.inputLabel,
                                                'data-testid': 'organisation-country-label',
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                'data-testid': 'organisation-country-input',
                                            }}
                                            label={t('Region')}
                                            fullWidth
                                        />
                                    )}
                                    value={canadianRegions.find((country) => country?.shortCode === value) || null}
                                    autoHighlight
                                    onBlur={onBlur}
                                    onChange={(e, newValue) => {
                                        onChange(newValue?.shortCode)
                                    }}
                                />
                            )}
                            shouldUnregister
                        />
                    ) : null}

                    <Controller
                        control={control}
                        name="percentage"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                disabled={taxRate}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                label={t('Rate') + ' *'}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            required: 'Required',
                            pattern: {
                                value: /^\d+(\.\d{1,4})?$/,
                                message: 'Invalid rate',
                            },
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isCreating || isUpdating}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isValid || isCreating || isUpdating}
                    key="add"
                    variant="contained"
                    onClick={handleSubmit(handleAddOrUpdateButtonClick)}
                >
                    {taxRate
                        ? isUpdating
                            ? t('Updating...')
                            : t('Edit tax rate')
                        : isCreating
                          ? t('Adding...')
                          : t('Add tax rate')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddTaxRateDialog.defaultProps = {
    open: false,
}

AddTaxRateDialog.propTypes = {
    open: PropTypes.bool,
    taxRate: PropTypes.object,
    onClose: PropTypes.func,
}

export default AddTaxRateDialog
