import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ChromeReaderMode, ChromeReaderModeOutlined } from '@mui/icons-material'
import { Box, Button, FormControl, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectCurrencyCode,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { useToolBoxTreatments } from '@/common/hooks'
import { useQuoteItemPropertyOptions } from '@/common/hooks/useQuoteItemPropertyOptions'
import { getCurrencyFormat, getPartDimensions, QUOTE_STATUS } from '@/common/utils'
import { formatNumber } from '@/features/web-store/helpers/utilities'

import ItemNotesModal from '../../common/ItemNotesModal'

const classes = {
    ItemDetails_Settings: {
        width: '420px',
        gap: '12px',
    },
    formControl_small: {
        width: '120px',
    },
    formControl_large: {
        width: '300px',
    },
    formControl_thickness: {
        flex: 1,
    },
    formControl_sheet: {
        flex: 2,
    },
    formControl_medium: {
        width: '180px',
    },
}

const ItemSettings = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const { showGrainDirection } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [updateQuote] = useUpdateQuoteMutation()
    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const [itemCuttingTechnology, setItemCuttingTechnology] = useState(quoteItem?.cuttingTechnologyId || '')
    const [itemMaterial, setItemMaterial] = useState(quoteItem?.materialId || '')
    const [itemThickness, setItemThickness] = useState(quoteItem?.thickness || '')
    const [itemSheet, setItemSheet] = useState(quoteItem?.sheetId || '')
    const [itemGrainDirection, setItemGrainDirection] = useState(quoteItem?.grainDirection || 'None')
    const [itemConsumptionMode, setItemConsumptionMode] = useState(quoteItem?.materialConsumptionMode || '')
    const [itemIsCustomerSuppliedMaterial, setItemIsCustomerSuppliedMaterial] = useState(
        quoteItem?.isCustomerSuppliedMaterial || false
    )
    const [itemQuantity, setItemQuantity] = useState(quoteItem?.quantity || 1)
    const [openNotesModal, setOpenNotesModal] = useState(false)
    const [quantityError, setQuantityError] = useState(false)

    const orgHasCustomerSuppliedMaterial = useMemo(() => paidFeatures?.hasCustomerSuppliedMaterial, [paidFeatures])
    const orgHasMaterialConsumptionMode = useMemo(() => paidFeatures?.hasMaterialConsumptionModeOptions, [paidFeatures])

    const materialConsumptionModeEnabled = useMemo(
        () => quoteItem?.materialId && orgHasMaterialConsumptionMode,
        [quoteItem?.materialId, orgHasMaterialConsumptionMode]
    )

    const partHasNotes = useMemo(() => {
        const { customerNotes, privateNotes, vendorNotes } = quoteItem
        return Boolean(customerNotes) || Boolean(vendorNotes) || Boolean(privateNotes)
    }, [quoteItem])

    const partDimensions = useMemo(() => getPartDimensions(quoteItem), [quoteItem])
    const units = useMemo(() => (useImperialUnits ? 'in' : 'mm'), [useImperialUnits])
    const mode = useMemo(() => ('profile' in quoteItem ? 'rotary' : 'flat'), [quoteItem])

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const {
        consumptionModeOptions,
        cuttingTechOptions,
        grainDirectionOptions,
        materialOptions,
        sheetOptions,
        thicknessOptions,
    } = useQuoteItemPropertyOptions({
        cuttingTechnologyId: itemCuttingTechnology,
        materialId: itemMaterial,
        thickness: itemThickness,
        mode,
        partDimensions,
    })

    const selectedSheet = useMemo(() => {
        if (!itemSheet) return null
        return sheetOptions?.find((option) => option.sheetId === itemSheet) || {}
    }, [itemSheet, sheetOptions])

    const handleUpdateQuote = async () => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    status: QUOTE_STATUS.NotCalculated,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()

            if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
                handleUpdateQuote()
            }
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 800)

    const disableNotesButton = false
    const showNotesModal = () => {
        setOpenNotesModal(true)
    }
    const hideNotesModal = () => {
        setOpenNotesModal(false)
    }

    const handleCuttingTechChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemCuttingTechnology !== value) {
            setItemCuttingTechnology(value)
            setItemMaterial('')
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)

            await handleUpdateQuoteItem({
                ...quoteItem,
                cuttingTechnologyId: value,
                materialId: '',
                thickness: '',
                sheetId: '',
                materialConsumptionMode: '',
                isCustomerSuppliedMaterial: '',
            })
        }
    }

    const handleMaterialChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemMaterial !== value) {
            setItemMaterial(value)
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            await handleUpdateQuoteItem({
                ...quoteItem,
                materialId: value,
                thickness: '',
                sheetId: '',
                materialConsumptionMode: '',
                isCustomerSuppliedMaterial: '',
            })
        }
    }

    const handleThicknessChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }
        const value = event?.target?.value
        if (Boolean(value) && itemThickness !== value) {
            setItemThickness(value)
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
        }
    }

    const handleSheetChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemSheet !== value) {
            const { defaultMaterialConsumptionMode, isSoldByOrganisation } =
                sheetOptions?.find((option) => option.sheetId === value) || {}
            setItemSheet(value)
            setItemConsumptionMode(defaultMaterialConsumptionMode)
            setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)
            await handleUpdateQuoteItem({
                ...quoteItem,
                sheetId: value,
                materialConsumptionMode: defaultMaterialConsumptionMode,
                isCustomerSuppliedMaterial: !isSoldByOrganisation,
            })
        }
    }

    const handleGrainDirectionChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemGrainDirection !== value) {
            setItemGrainDirection(value)
            await handleUpdateQuoteItem({
                ...quoteItem,
                grainDirection: value,
            })
        }
    }

    const handleConsumptionModeChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemConsumptionMode !== value) {
            setItemConsumptionMode(value)
            await handleUpdateQuoteItem({
                ...quoteItem,
                materialConsumptionMode: value,
            })
        }
    }

    const handleQuantityChange = async (values) => {
        debounceHandleUpdateQuoteItem.cancel()
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const { floatValue } = values
        setQuantityError(!floatValue || isNaN(floatValue) || floatValue <= 0)

        setItemQuantity(floatValue)

        if (!isNaN(floatValue) && floatValue >= 1) {
            debounceHandleUpdateQuoteItem({
                ...quoteItem,
                quantity: floatValue,
            })
        }
    }

    const handleIsCustomerSuppliedMaterialChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.checked
        setItemIsCustomerSuppliedMaterial(value)
        await handleUpdateQuoteItem({
            ...quoteItem,
            isCustomerSuppliedMaterial: value,
        })
    }

    const loadDefaultSheetOption = async () => {
        if (sheetOptions?.length && itemThickness && !itemSheet) {
            const firstSheet = sheetOptions[0]
            const { defaultMaterialConsumptionMode, isSoldByOrganisation, sheetId } = firstSheet

            if (itemSheet === sheetId) return

            setItemSheet(sheetId)
            setItemConsumptionMode(defaultMaterialConsumptionMode || '')
            setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)

            if (
                quoteItem.thickness !== itemThickness ||
                quoteItem.sheetId !== sheetId ||
                quoteItem.materialConsumptionMode !== defaultMaterialConsumptionMode ||
                quoteItem.isCustomerSuppliedMaterial !== !isSoldByOrganisation
            ) {
                await handleUpdateQuoteItem({
                    ...quoteItem,
                    thickness: itemThickness,
                    sheetId: sheetId,
                    materialConsumptionMode: defaultMaterialConsumptionMode,
                    isCustomerSuppliedMaterial: !isSoldByOrganisation,
                })

                if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
                    dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
                }
            }
        }
    }

    useEffect(() => {
        setItemQuantity(quoteItem.quantity)
    }, [quoteItem?.quantity])

    useEffect(() => {
        setItemCuttingTechnology(quoteItem?.cuttingTechnologyId || '')
    }, [quoteItem?.cuttingTechnologyId])

    useEffect(() => {
        setItemMaterial(quoteItem?.materialId || '')
    }, [quoteItem?.materialId])

    useEffect(() => {
        setItemThickness(quoteItem?.thickness || '')
    }, [quoteItem?.thickness])

    useEffect(() => {
        setItemSheet(quoteItem?.sheetId || '')
    }, [quoteItem?.sheetId])

    useEffect(() => {
        setItemConsumptionMode(quoteItem?.materialConsumptionMode || '')
    }, [quoteItem?.materialConsumptionMode])

    useEffect(() => {
        setItemIsCustomerSuppliedMaterial(quoteItem?.isCustomerSuppliedMaterial || false)
    }, [quoteItem?.isCustomerSuppliedMaterial])

    useEffect(() => {
        loadDefaultSheetOption()
    }, [JSON.stringify(sheetOptions)])

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            justifyContent="space-around"
            key={quoteItemId}
            sx={classes.ItemDetails_Settings}
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography
                    data-testid="quote-item-settings-section-title"
                    style={{ fontWeight: 700 }}
                    variant="body1"
                >
                    {t('Settings')}
                </Typography>

                <Button
                    color="secondary"
                    data-testid="nqp-quote-item-notes-button"
                    disabled={disableNotesButton}
                    size="small"
                    startIcon={partHasNotes ? <ChromeReaderMode /> : <ChromeReaderModeOutlined />}
                    variant="text"
                    onClick={showNotesModal}
                >
                    {t('Part notes')}
                </Button>
            </Box>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography data-testid="nqp-quote-item-quantity-label">{t('Quantity')}</Typography>
                <NumericFormat
                    allowNegative={false}
                    customInput={TextField}
                    decimalSeparator={currencyFormat.decimal}
                    error={quantityError}
                    helperText={quantityError ? t('Quantity cannot be less than 1') : null}
                    inputProps={{
                        style: { textAlign: 'right' },
                        'data-testid': 'nqp-quote-item-quantity',
                    }}
                    placeholder="0"
                    size="small"
                    sx={classes.formControl_medium}
                    thousandSeparator={currencyFormat.group}
                    value={itemQuantity}
                    variant="outlined"
                    onValueChange={handleQuantityChange}
                />
            </Box>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography data-testid="nqp-quote-item-cutting-technology-label">{t('Cutting technology')}</Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            disabled={!cuttingTechOptions?.length || isPartFromLibrary}
                            id="select-itemCuttingTechnology"
                            inputProps={{ 'data-testid': 'nqp-quote-item-cutting-technology' }}
                            labelId="select-itemCuttingTechnology-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemCuttingTechnology}
                            onChange={handleCuttingTechChange}
                        >
                            {cuttingTechOptions
                                ? cuttingTechOptions?.map((ct) => (
                                      <MenuItem
                                          key={ct.cuttingTechnologyId}
                                          value={ct.cuttingTechnologyId}
                                      >
                                          {ct.name}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </TbxTooltip>
            </Box>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography data-testid="nqp-quote-item-material-label">{t('Material')}</Typography>
                <TbxTooltip
                    title={
                        isPartFromLibrary
                            ? itemMaterial == '' || itemThickness == ''
                                ? t('Material for Part Library Part is no longer available for selection')
                                : t('Cannot edit parts from library')
                            : ''
                    }
                    arrow
                >
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_large}
                        variant="outlined"
                    >
                        <Select
                            disabled={!itemCuttingTechnology || !materialOptions?.length || isPartFromLibrary}
                            id="select-itemMaterial"
                            inputProps={{ 'data-testid': 'nqp-quote-item-material' }}
                            labelId="select-itemMaterial-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemMaterial}
                            onChange={handleMaterialChange}
                        >
                            {materialOptions
                                ? materialOptions?.map((material) => (
                                      <MenuItem
                                          key={material.materialId}
                                          value={material.materialId}
                                      >
                                          {material.materialName}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </TbxTooltip>
            </Box>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={1}
                justifyContent="flex-end"
                width="100%"
            >
                <TbxTooltip
                    title={
                        isPartFromLibrary
                            ? itemMaterial == '' || itemThickness == ''
                                ? t('Material for Part Library Part is no longer available for selection')
                                : t('Cannot edit parts from library')
                            : ''
                    }
                    arrow
                >
                    <FormControl
                        color="primary"
                        disabled={!thicknessOptions?.length}
                        margin="none"
                        size="small"
                        sx={classes.formControl_thickness}
                        variant="outlined"
                    >
                        <Select
                            disabled={!itemMaterial || !thicknessOptions?.length || isPartFromLibrary}
                            id="select-itemThickness"
                            inputProps={{ 'data-testid': 'nqp-quote-item-thickness' }}
                            labelId="select-itemThickness-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemThickness}
                            onChange={handleThicknessChange}
                        >
                            {thicknessOptions
                                ? thicknessOptions?.map((thickness) => (
                                      <MenuItem
                                          key={thickness}
                                          sx={{ justifyContent: 'flex-end' }}
                                          value={thickness}
                                      >
                                          {formatNumber(thickness, locale, useImperialUnits)} {units}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </TbxTooltip>
                <FormControl
                    color="primary"
                    disabled={!sheetOptions?.length}
                    margin="none"
                    size="small"
                    sx={classes.formControl_sheet}
                    variant="outlined"
                >
                    <Select
                        disabled={!itemThickness || !sheetOptions}
                        id="select-itemSheet"
                        inputProps={{ 'data-testid': 'nqp-quote-item-sheet-size' }}
                        labelId="select-itemSheet-label"
                        SelectDisplayProps={{ style: { textAlign: 'right' } }}
                        value={itemSheet}
                        onChange={handleSheetChange}
                    >
                        {sheetOptions
                            ? sheetOptions?.map((sheet) => (
                                  <MenuItem
                                      key={sheet.sheetId}
                                      sx={{ justifyContent: 'flex-end' }}
                                      value={sheet.sheetId}
                                  >
                                      {'profile' in quoteItem
                                          ? `${formatNumber(sheet.sheetLength, locale)} ${units}`
                                          : `${formatNumber(sheet.sheetWidth, locale)} x ${formatNumber(
                                                sheet.sheetHeight,
                                                locale
                                            )} ${units}`}
                                  </MenuItem>
                              ))
                            : null}
                    </Select>
                </FormControl>
            </Box>

            {showGrainDirection && !('profile' in quoteItem) ? (
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography data-testid="nqp-quote-item-grain-direction-label">{t('Grain direction')}</Typography>
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            data-testid="nqp-quote-item-grain-direction"
                            id="select-itemGrainDirection"
                            labelId="select-itemGrainDirection-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemGrainDirection}
                            onChange={handleGrainDirectionChange}
                        >
                            {grainDirectionOptions.map((direction) => (
                                <MenuItem
                                    key={`direction-${direction.value}`}
                                    value={direction.value}
                                >
                                    {direction.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            ) : null}

            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography data-testid="nqp-quote-item-consumption-mode-label">{t('Consumption mode')}</Typography>
                <FormControl
                    color="primary"
                    margin="none"
                    size="small"
                    sx={classes.formControl_medium}
                    variant="outlined"
                >
                    <Select
                        data-testid="nqp-quote-item-consumption-mode"
                        disabled={!materialConsumptionModeEnabled}
                        id="select-itemConsumptionMode"
                        labelId="select-itemConsumptionMode-label"
                        SelectDisplayProps={{ style: { textAlign: 'right' } }}
                        value={itemConsumptionMode}
                        onChange={handleConsumptionModeChange}
                    >
                        {consumptionModeOptions.map((mode) => (
                            <MenuItem
                                key={mode.value}
                                value={mode.value}
                            >
                                {mode.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography data-testid="nqp-quote-item-customer-supplied-material-label">
                    {t('Customer supplied material')}
                </Typography>
                <TbxTooltip
                    leaveDelay={500}
                    title={
                        selectedSheet ? (
                            orgHasCustomerSuppliedMaterial ? (
                                !selectedSheet.isSoldByOrganisation ? (
                                    t('Not for sale. Customer-supplied only.')
                                ) : (
                                    ''
                                )
                            ) : (
                                <UpgradePlanLink />
                            )
                        ) : (
                            ''
                        )
                    }
                    arrow
                    disableFocusListener
                    disableTouchListener
                >
                    <FormControl
                        color="primary"
                        disabled={!orgHasCustomerSuppliedMaterial || !selectedSheet?.isSoldByOrganisation}
                        margin="none"
                        size="small"
                        variant="outlined"
                    >
                        <Switch
                            checked={itemIsCustomerSuppliedMaterial}
                            data-testid="nqp-quote-item-customer-supplied-material"
                            onChange={handleIsCustomerSuppliedMaterialChange}
                        />
                    </FormControl>
                </TbxTooltip>
            </Box>

            {openNotesModal ? (
                <ItemNotesModal
                    handleUpdate={handleUpdateQuoteItem}
                    open={openNotesModal}
                    quoteItem={quoteItem}
                    onClose={hideNotesModal}
                />
            ) : null}
        </Box>
    )
}

ItemSettings.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemSettings)
