import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useGetMaterialsQuery } from '@/app/services/materials'
import {
    useGetRateTablesQuery,
    useUpdateRateTableMutation,
    useValidateRateTableNameMutation,
} from '@/app/services/rateTables'
import { selectLocale } from '@/app/slices/appSlice'
import { setSelectedMaterialId } from '@/app/slices/materialsSlice'
import {
    selectCurrencyCode,
    selectCurrencySymbol,
    selectOrganisationId,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import TbxCardWithAction from '@/common/components/TbxCardWithAction/TbxCardWithAction'
import { getFormattedPrice } from '@/common/utils'

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: 'text.primary',
        marginBottom: 1,
    },
    settingRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '12px',
    },
    settingLabel: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        color: 'text.primary',
        maxWidth: '200px',
        flexBasis: '50%',
    },
    settingValue: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        color: 'text.primary',
        height: 32,
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    material: {
        color: '#0078A4',
        textDecoration: 'underline',
        cursor: 'pointer',
        mr: 1,
    },
    textInput: {
        display: 'flex',
        flexBasis: '50%',
    },
    materialsLayout: {
        mt: 1,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
    },
}

const RateTableDetails = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const currencyCode = useSelector(selectCurrencyCode)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const locale = useSelector(selectLocale)

    const { data: materials, isLoading: isLoadingMaterial } = useGetMaterialsQuery({ organisationId })
    const { data: rateTables, isLoading: isLoadingRT } = useGetRateTablesQuery({ organisationId })

    const selectedRateTableId = useSelector(selectSelectedRateTableId)
    const selectedRateTable = rateTables[selectedRateTableId]

    const [updateRateTable] = useUpdateRateTableMutation()
    const [validateRateTableName] = useValidateRateTableNameMutation()

    const [isEditing, setIsEditing] = useState(false)

    const materialsUsingRateTable = useMemo(() => {
        if (materials) {
            const hasRateTableFilter = (materialRateTable) => materialRateTable.rateTableId === selectedRateTableId

            const materialsFilter = (material) => material.materialRateTables?.some(hasRateTableFilter)
            const filteredMaterials = Object.values(materials)?.filter(materialsFilter)

            return filteredMaterials
        }
    }, [materials, selectedRateTableId])

    const {
        control,
        formState: { isValid },
        getValues,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            name: selectedRateTable?.name,
            baseHourlyRatePrice: selectedRateTable?.baseHourlyRatePrice,
            etchingFeedRate: selectedRateTable?.etchingFeedRate,
        },
    })

    useEffect(() => {
        reset({
            name: selectedRateTable?.name,
            baseHourlyRatePrice: selectedRateTable?.baseHourlyRatePrice,
            etchingFeedRate: selectedRateTable?.etchingFeedRate,
        })
        setIsEditing(false)
    }, [selectedRateTable])

    const validateName = async (name) => {
        const requestBody = {
            cuttingTechnologyId: selectedRateTable?.cuttingTechnologyId,
            excludeRateTableId: selectedRateTable?.rateTableId,
            name: name,
        }
        const result = await validateRateTableName({ organisationId, requestBody }).unwrap()
        return result || t('This rate table name is not available to be used with this cutting technology.')
    }

    const handleUpdateClick = async (data) => {
        try {
            await updateRateTable({
                organisationId,
                rateTable: { rateTableId: selectedRateTableId, ...data },
            }).unwrap()

            enqueueSnackbar(t('Rate table settings updated'), { variant: 'success' })

            reset({ ...data })
        } catch (error) {
            enqueueSnackbar(t('An error occurred updating rate table settings.'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        }
    }
    const toggleEditRateTable = () => {
        if (isEditing && isValid) {
            const data = {
                ...getValues(),
            }
            handleUpdateClick(data)
        }
        if (isValid) {
            setIsEditing(!isEditing)
        }
    }

    const handleMaterialNameClick = (material) => {
        dispatch(setSelectedMaterialId(material.materialId))
        navigate('/materials')
    }

    return !isLoadingMaterial && !isLoadingRT ? (
        <Box sx={classes.container}>
            <Box sx={classes.card}>
                <TbxCardWithAction
                    disabled={selectedRateTable?.isDeleted}
                    isEditing={isEditing}
                    title={t('Rate table settings')}
                    onActionButtonClick={toggleEditRateTable}
                >
                    {!isEditing ? (
                        <>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Name')}</Typography>
                                <Typography sx={classes.settingValue}>{selectedRateTable?.name}</Typography>
                            </Box>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Base hourly rate')}</Typography>
                                <Typography sx={classes.settingValue}>
                                    {getFormattedPrice(selectedRateTable?.baseHourlyRatePrice, currencyCode, locale)}
                                </Typography>
                            </Box>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Etching feed rate')}</Typography>
                                <Typography sx={classes.settingValue}>
                                    {selectedRateTable?.etchingFeedRate} {useImperialUnits ? 'in/min' : 'mm/min'}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Name') + ' *'}</Typography>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                        <TextField
                                            disabled={selectedRateTable?.isDeleted}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            sx={classes.textInput}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                        />
                                    )}
                                    rules={{ required: 'Required', validate: validateName }}
                                />
                            </Box>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Base hourly rate') + ' *'}</Typography>
                                <Controller
                                    control={control}
                                    name="baseHourlyRatePrice"
                                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                        <TextField
                                            disabled={selectedRateTable?.isDeleted}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                ),
                                            }}
                                            sx={classes.textInput}
                                            type="number"
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                        />
                                    )}
                                    rules={{ required: 'Required' }}
                                />
                            </Box>
                            <Box sx={classes.settingRow}>
                                <Typography sx={classes.settingLabel}>{t('Etching feed rate')}</Typography>
                                <Controller
                                    control={control}
                                    name="etchingFeedRate"
                                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                        <TextField
                                            disabled={selectedRateTable?.isDeleted}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        {useImperialUnits ? 'in/min' : 'mm/min'}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={classes.textInput}
                                            type="number"
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Box>
                        </>
                    )}
                </TbxCardWithAction>
            </Box>
            <Box sx={classes.card}>
                <TbxCardWithAction title={t('Materials using this setup')}>
                    <Box sx={classes.materialsLayout}>
                        {materialsUsingRateTable.length ? (
                            materialsUsingRateTable.map((material) => (
                                <Typography
                                    key={material.materialId}
                                    sx={classes.material}
                                    variant="body2"
                                    onClick={() => handleMaterialNameClick(material)}
                                >
                                    {material.materialName}
                                </Typography>
                            ))
                        ) : (
                            <Typography
                                style={{ color: 'red' }}
                                variant="body2"
                            >
                                {t(
                                    'No materials are using this rate table. Please update the rate table on any material.'
                                )}
                            </Typography>
                        )}
                    </Box>
                </TbxCardWithAction>
            </Box>
        </Box>
    ) : null
}

export default RateTableDetails
