import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'

import { selectOrganisation, selectPaidFeatures, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import FormLocationPicker from '@/common/components/FormComponents/FormLocationPicker'
import { useToolBoxTreatments } from '@/common/hooks'
import { DELIVERY_PRICING_METHODS, DELIVERY_PRICING_OPTIONS, QUOTE_SOURCE_TYPES } from '@/common/utils'

const classes = {
    shippingOptionSwitch: {
        marginLeft: 0,
        marginBottom: 2,
        gap: 1,
    },
}

const WebStoreSettings = ({ form }) => {
    const { t } = useTranslation()
    const { showShipping } = useToolBoxTreatments()
    const { control, watch } = form

    const organisation = useSelector(selectOrganisation)
    const isImperial = useSelector(selectUseImperialUnits)
    const { hasShipping } = useSelector(selectPaidFeatures)

    const defaultpickupAddress = 'View our website for our current address'
    const minLocations = (locations) => {
        return locations?.length > 0 || t('At least one location must be provided.')
    }

    const deliveryPricingOption = watch('deliveryPricingOption')
    const webStoreDefaultDeliveryMethod = watch('webStoreDefaultDeliveryMethod')
    const webStoreShippinOptionPickup = watch('webStorePickupEnabled')
    const webStoreShippinOptionDelivery = watch('webStoreDeliveryEnabled')

    const DELIVERY_PRICING_METHODS_WEBSTORE = Object.values(DELIVERY_PRICING_METHODS).filter((method) =>
        method.sources.includes(QUOTE_SOURCE_TYPES.WebStore)
    )

    return (
        <Grid
            marginBlock={0}
            paddingInline={2}
            spacing={3}
            xs={12}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="web-store-settings"
                    id="web-store-settings"
                    variant="h6"
                >
                    {t('Web Store orders settings')}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Typography
                    data-testid="web-store-shipping-options-title"
                    variant="strong1"
                >
                    {t('Shipping options')}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {t(
                        'Choose whether customers must pick up their order, must have it delivered, or if they can choose between these two options. At least one option must be enabled.'
                    )}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <FormControlLabel
                    control={
                        <Controller
                            control={control}
                            key="webStorePickupEnabled"
                            name="webStorePickupEnabled"
                            render={({ field: { onBlur, onChange, ref, value } }) => (
                                <Switch
                                    checked={value}
                                    color="primary"
                                    disabled={!webStoreShippinOptionDelivery}
                                    inputRef={ref}
                                    name="webStorePickupEnabled"
                                    size="small"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                    }
                    label={t('Pickup available')}
                    labelPlacement="start"
                    sx={classes.shippingOptionSwitch}
                />

                {webStoreShippinOptionPickup ? (
                    <Typography variant="body2">{organisation?.pickupAddress || t(defaultpickupAddress)}</Typography>
                ) : null}
            </Grid>
            <Grid xs={12}>
                <FormControlLabel
                    control={
                        <Controller
                            control={control}
                            key="webStoreDeliveryEnabled"
                            name="webStoreDeliveryEnabled"
                            render={({ field: { onBlur, onChange, ref, value } }) => (
                                <Switch
                                    checked={!!value}
                                    color="primary"
                                    disabled={!webStoreShippinOptionPickup}
                                    inputRef={ref}
                                    name="webStoreDeliveryEnabled"
                                    size="small"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                    }
                    label={t('Delivery available')}
                    labelPlacement="start"
                    sx={classes.shippingOptionSwitch}
                />

                {webStoreShippinOptionDelivery ? (
                    <>
                        <Typography
                            color="text.secondary"
                            mb={2}
                            variant="body2"
                        >
                            {t('Select countries or states where delivery is available:')}
                        </Typography>

                        <FormLocationPicker
                            control={control}
                            label={t('Start typing a location')}
                            name="webStoreDeliveryRegions"
                            rules={{
                                validate: (value) => (webStoreShippinOptionDelivery ? minLocations(value) : true),
                            }}
                            variant="outlined"
                            required
                        />
                    </>
                ) : null}
            </Grid>

            {showShipping ? (
                <Grid xs={12}>
                    <Typography
                        data-testid="web-store-delivery-setting"
                        mb={1}
                        variant="strong1"
                    >
                        {t('Delivery pricing method to use for Web Store orders')}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        mb={2}
                        variant="body2"
                    >
                        {t('Select a method for charging for delivery on the Web Store.')}
                    </Typography>
                    <Controller
                        control={control}
                        key="webStoreDefaultDeliveryMethod"
                        name="webStoreDefaultDeliveryMethod"
                        render={({ field: { onChange, ref, value } }) => (
                            <RadioGroup
                                data-testid="web-store-delivery-setting-options"
                                sx={{ marginBottom: 2 }}
                                value={value}
                                onChange={(e, value) => {
                                    onChange(value)
                                }}
                            >
                                {DELIVERY_PRICING_METHODS_WEBSTORE.map((option) => (
                                    <>
                                        <TbxTooltip
                                            key={option.value}
                                            title={
                                                option.value === 'ChargeToOrder' && !hasShipping
                                                    ? t('This feature is not included in the current plan.')
                                                    : ''
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color="primary"
                                                        inputRef={ref}
                                                    />
                                                }
                                                data-testid={`web-store-delivery-setting-option-${option.value}`}
                                                disabled={option.value === 'ChargeToOrder' ? !hasShipping : null}
                                                inputRef={ref}
                                                key={option.value}
                                                label={t(option.label)}
                                                slotProps={{
                                                    typography: {
                                                        variant: 'body1',
                                                        color: 'text.secondary',
                                                    },
                                                }}
                                                value={option.value}
                                            />
                                        </TbxTooltip>

                                        <Typography
                                            color="text.secondary"
                                            ml={4}
                                            mt={-1}
                                            variant="body2"
                                        >
                                            {t(option.helperText)}
                                        </Typography>

                                        {option.value == 'PayUponDelivery' &&
                                        webStoreDefaultDeliveryMethod === 'PayUponDelivery' ? (
                                            <>
                                                <Controller
                                                    control={control}
                                                    key="webStorePreferredDeliveryProvider"
                                                    name="webStorePreferredDeliveryProvider"
                                                    render={({
                                                        field: { onBlur, onChange, ref, value },
                                                        fieldState,
                                                    }) => (
                                                        <TextField
                                                            disabled={
                                                                webStoreDefaultDeliveryMethod !== 'PayUponDelivery'
                                                            }
                                                            error={fieldState.error}
                                                            helperText={t(
                                                                '* Required. Add the name of the preferred delivery provider'
                                                            )}
                                                            InputLabelProps={{
                                                                'data-testid':
                                                                    'web-store-preferred-delivery-provider-label',
                                                                sx: { paddingInline: 5 },
                                                            }}
                                                            inputProps={{
                                                                'data-testid':
                                                                    'web-store-preferred-delivery-provider-input',
                                                            }}
                                                            inputRef={ref}
                                                            label={t('Preferred delivery provider')}
                                                            size="small"
                                                            sx={{ marginBlock: 2, paddingInline: 4 }}
                                                            value={value}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: webStoreDefaultDeliveryMethod === 'PayUponDelivery',
                                                    }}
                                                />
                                                <Controller
                                                    control={control}
                                                    name="webStoreCustomerDeliveryProviderOption"
                                                    render={({ field: { onBlur, onChange, ref, value } }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={value}
                                                                    color="primary"
                                                                    inputRef={ref}
                                                                />
                                                            }
                                                            disabled={
                                                                webStoreDefaultDeliveryMethod !== 'PayUponDelivery'
                                                            }
                                                            label={t(
                                                                "Let the customer provide their own preferred delivery provider's name and their account number."
                                                            )}
                                                            labelPlacement="end"
                                                            slotProps={{
                                                                typography: {
                                                                    variant: 'body1',
                                                                    color: 'text.secondary',
                                                                },
                                                            }}
                                                            sx={{ marginLeft: 3, marginBottom: 2 }}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </>
                                        ) : null}

                                        {option.value === 'ChargeToOrder' && hasShipping ? (
                                            <Typography
                                                color="text.secondary"
                                                ml={4}
                                                mt={1}
                                                variant="body2"
                                            >
                                                {t('Selected pricing option:')}{' '}
                                                <strong>
                                                    {Object.values(DELIVERY_PRICING_OPTIONS)
                                                        .find((option) => option.value === deliveryPricingOption)
                                                        ?.label(isImperial)}
                                                </strong>
                                                {'. '}
                                                {t('Manage the charge settings')}{' '}
                                                <Link to="#delivery-prices">{t('here.')}</Link>
                                            </Typography>
                                        ) : null}
                                    </>
                                ))}
                            </RadioGroup>
                        )}
                    />
                </Grid>
            ) : null}
        </Grid>
    )
}

WebStoreSettings.propTypes = {
    form: PropTypes.object.isRequired,
}

export default WebStoreSettings
