import { useCallback, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import {
    AccountBalance,
    Archive,
    EditRounded,
    FavoriteBorderRounded,
    FavoriteRounded,
    Unarchive,
} from '@mui/icons-material'
import {
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import allCountries from 'country-region-data'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { useDeleteTaxRateMutation, useGetTaxRatesQuery, useUpdateTaxRateMutation } from '@/app/services/taxRates'
import { selectOrganisationId, setDefaultTaxRateId } from '@/app/slices/organisationSlice'
import { AlertDialog, TbxShowToggle, TbxTooltip } from '@/common/components'
import { fractionToPercentage } from '@/common/helpers/formatUtilities'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    TAX_RATES_PER_PAGE_DEFAULT_VALUE,
    TAX_RATES_PER_PAGE_OPTIONS,
    TAX_RATES_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AddTaxRateDialog from './AddTaxRateDialog'

const classes = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 500,
        width: '100%',
    },
    addButton: {
        width: 40,
        height: 40,
    },

    remainingUsersLabel: {
        padding: '1.5rem',
        fontWeight: 500,
        flex: 1,
    },

    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeRate: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),
}

const TaxRates = ({ control }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const { showMultipleTaxRates, showPricing, showQuotingIteration2 } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)

    const [showAddTaxRateDialog, setShowAddTaxRateDialog] = useState(false)
    const [showArchiveTaxRateDialog, setShowArchiveTaxRateDialog] = useState(false)
    const [showUnarchiveTaxRateDialog, setShowUnarchiveTaxRateDialog] = useState(false)
    const [taxRateToArchive, setTaxRateToArchive] = useState(null)
    const [taxRateToEdit, setTaxRateToEdit] = useState(null)
    const [showArchivedRates, setShowArchivedRates] = useState(false)

    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        TAX_RATES_PER_PAGE_VARIABLE,
        TAX_RATES_PER_PAGE_DEFAULT_VALUE
    )

    const { data: taxRates, refetch } = useGetTaxRatesQuery({
        organisationId,
        params: { includeDeleted: showArchivedRates },
    })
    const [updateTaxRate] = useUpdateTaxRateMutation()
    const [deleteTaxRate] = useDeleteTaxRateMutation()

    const countryName = useCallback(
        (countryCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            return country?.countryName ?? null
        },
        [allCountries]
    )

    const stateName = useCallback(
        (countryCode, stateCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            const state = country?.regions.find((region) => region.shortCode === stateCode)
            return state?.name ?? null
        },
        [allCountries]
    )

    const disableArchiveTaxRate = useMemo(() => {
        return taxRates?.filter((taxRate) => taxRate.active).length <= 1
    }, [taxRates])

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const handleAddTaxRateButtonClick = () => {
        setShowAddTaxRateDialog(true)
    }

    const handleCloseAddTaxRateDialog = () => {
        setTaxRateToEdit(null)
        setShowAddTaxRateDialog(false)
    }

    const handleArchiveTaxRateButtonClick = (taxRate) => {
        setTaxRateToArchive(taxRate)
        setShowArchiveTaxRateDialog(true)
    }

    const handleUnarchiveTaxRateButtonClick = (taxRate) => {
        setTaxRateToArchive(taxRate)
        setShowUnarchiveTaxRateDialog(true)
    }

    const handleCancelArchive = () => {
        setTaxRateToArchive(null)
        setShowArchiveTaxRateDialog(false)
        setShowUnarchiveTaxRateDialog(false)
    }

    const handleDefaultTaxRateButtonClick = async (taxRate) => {
        try {
            await updateTaxRate({
                organisationId,
                taxRate: { id: taxRate.id, isDefault: true },
            }).unwrap()

            dispatch(setDefaultTaxRateId(taxRate.id))
            enqueueSnackbar(t('Default tax rate updated successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to update default tax rate'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleArchiveConfirmation = async () => {
        try {
            await deleteTaxRate({ organisationId, taxRateId: taxRateToArchive.id }).unwrap()
            enqueueSnackbar(t('Tax rate archived successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to archive tax rate'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            setTaxRateToArchive(null)
            setShowArchiveTaxRateDialog(false)
        }
    }

    const handleUnarchiveConfirmation = async () => {
        try {
            await updateTaxRate({
                organisationId,
                taxRate: { id: taxRateToArchive.id, displayName: taxRateToArchive.displayName, active: true },
            }).unwrap()
            enqueueSnackbar(t('Tax rate unarchived successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to unarchive tax rate'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            setTaxRateToArchive(null)
            setShowUnarchiveTaxRateDialog(false)
        }
    }

    const handleEditTaxRateButtonClick = (taxRate) => {
        setTaxRateToEdit(taxRate)
        setShowAddTaxRateDialog(true)
    }

    const handleShowArchivedRatesChange = () => {
        setShowArchivedRates(!showArchivedRates)
        refetch()
    }

    return (
        <>
            {showMultipleTaxRates ? (
                <Grid xs={12}>
                    <Typography
                        data-testid="organisation-taxes-setting"
                        id="taxes"
                        sx={classes.title}
                        variant="h6"
                    >
                        {t('Taxes')}
                    </Typography>
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography
                            data-testid="organisation-pricing-setting"
                            id="Defaults"
                            sx={classes.title}
                            variant="body2"
                        >
                            {t('Add, edit or archive tax rates.')}
                        </Typography>

                        <Box width="fit-content">
                            <TbxShowToggle
                                checked={showArchivedRates}
                                data-testid="show-archived-rates-toggle"
                                label={t('Show archived rates')}
                                onChange={handleShowArchivedRatesChange}
                            />
                        </Box>

                        <Button
                            color="secondary"
                            data-testid="add-tax-rate-button"
                            size="medium"
                            startIcon={<AccountBalance />}
                            variant="outlined"
                            onClick={handleAddTaxRateButtonClick}
                        >
                            {t('Add tax rate')}
                        </Button>
                    </Box>
                </Grid>
            ) : (
                <Grid xs={12}>
                    <Typography
                        data-testid="organisation-taxes-setting"
                        id="taxes"
                        sx={classes.title}
                        variant="h6"
                    >
                        {t('Taxes')}
                    </Typography>
                </Grid>
            )}

            {showMultipleTaxRates ? (
                <Grid xs={12}>
                    <TableContainer sx={classes.tableContainer}>
                        <Table sx={classes.table}>
                            <colgroup>
                                <col style={{ width: '180px' }} />
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '120px' }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell sx={classes.headerTableCell}>{t('Tax name')}</TableCell>
                                    <TableCell sx={classes.headerTableCell}>{t('Region')}</TableCell>
                                    <TableCell
                                        align="right"
                                        sx={classes.headerTableCell}
                                    >
                                        {t('Rate')}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taxRates?.length
                                    ? taxRates
                                          .slice(
                                              currentPage * currentRowsPerPage,
                                              currentPage * currentRowsPerPage + currentRowsPerPage
                                          )
                                          .map((tax) => {
                                              return (
                                                  <TableRow
                                                      key={tax.id}
                                                      sx={classes.tableRow}
                                                  >
                                                      <TableCell
                                                          sx={[
                                                              classes.tableCell,
                                                              classes.tableCellWithEllipsis,
                                                              classes.activeRate(tax.active),
                                                          ]}
                                                      >
                                                          {tax.displayName}
                                                      </TableCell>

                                                      <TableCell
                                                          sx={[classes.tableCell, classes.activeRate(tax.active)]}
                                                      >
                                                          {countryName(tax.country)}{' '}
                                                          {tax.state ? `- ${stateName(tax.country, tax.state)}` : null}
                                                      </TableCell>

                                                      <TableCell
                                                          align="right"
                                                          sx={[classes.tableCell, classes.activeRate(tax.active)]}
                                                      >
                                                          {fractionToPercentage(tax.percentage)}%
                                                      </TableCell>

                                                      <TableCell
                                                          align="right"
                                                          sx={classes.tableCell}
                                                      >
                                                          <Box
                                                              display="flex"
                                                              justifyContent="flex-end"
                                                          >
                                                              <IconButton
                                                                  disabled={!tax.active}
                                                                  size="small"
                                                                  onClick={() => {
                                                                      handleDefaultTaxRateButtonClick(tax)
                                                                  }}
                                                              >
                                                                  {tax.isDefault ? (
                                                                      <FavoriteRounded
                                                                          fontSize="small"
                                                                          sx={classes.iconColor(tax.active)}
                                                                      />
                                                                  ) : (
                                                                      <FavoriteBorderRounded
                                                                          fontSize="small"
                                                                          sx={classes.iconColor(tax.active)}
                                                                      />
                                                                  )}
                                                              </IconButton>
                                                              <IconButton
                                                                  disabled={!tax.active}
                                                                  size="small"
                                                                  onClick={() => {
                                                                      handleEditTaxRateButtonClick(tax)
                                                                  }}
                                                              >
                                                                  <EditRounded
                                                                      fontSize="small"
                                                                      sx={classes.iconColor(tax.active)}
                                                                  />
                                                              </IconButton>

                                                              <TbxTooltip
                                                                  title={
                                                                      tax.active
                                                                          ? disableArchiveTaxRate
                                                                              ? t(
                                                                                    'You must have at least one active tax rate'
                                                                                )
                                                                              : t('Archive tax rate')
                                                                          : t('Unarchive tax rate')
                                                                  }
                                                                  arrow
                                                              >
                                                                  {tax.active ? (
                                                                      <span>
                                                                          <IconButton
                                                                              aria-label="archive"
                                                                              data-testid="archive-button"
                                                                              disabled={disableArchiveTaxRate}
                                                                              size="small"
                                                                              sx={classes.iconColor()}
                                                                              onClick={() => {
                                                                                  handleArchiveTaxRateButtonClick(tax)
                                                                              }}
                                                                          >
                                                                              <Archive fontSize="small" />
                                                                          </IconButton>
                                                                      </span>
                                                                  ) : (
                                                                      <span>
                                                                          <IconButton
                                                                              aria-label="unarchive"
                                                                              data-testid="unarchive-button"
                                                                              size="small"
                                                                              sx={classes.iconColor()}
                                                                              onClick={() => {
                                                                                  handleUnarchiveTaxRateButtonClick(tax)
                                                                              }}
                                                                          >
                                                                              <Unarchive fontSize="small" />
                                                                          </IconButton>
                                                                      </span>
                                                                  )}
                                                              </TbxTooltip>
                                                          </Box>
                                                      </TableCell>
                                                  </TableRow>
                                              )
                                          })
                                    : null}
                            </TableBody>
                        </Table>

                        <TablePagination
                            component="div"
                            count={taxRates?.length ?? 0}
                            labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                            labelRowsPerPage={t('Tax rates per page')}
                            page={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            rowsPerPageOptions={TAX_RATES_PER_PAGE_OPTIONS}
                            sx={classes.tableFooter}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            ) : (
                <Grid xs={12}>
                    {showPricing && showQuotingIteration2 ? (
                        <Controller
                            control={control}
                            name="taxRate"
                            render={({ field: { onChange, value } }) => (
                                <NumericFormat
                                    allowNegative={false}
                                    customInput={TextField}
                                    InputLabelProps={{
                                        'data-testid': 'organisation-tax-rate-label',
                                    }}
                                    inputProps={{
                                        'data-testid': 'organisation-tax-rate-input',
                                    }}
                                    label={t('Tax Rate')}
                                    placeholder="0"
                                    size="small"
                                    suffix="%"
                                    value={value}
                                    fullWidth
                                    onValueChange={({ floatValue }) => onChange(floatValue)}
                                />
                            )}
                            rules={{ required: 'Required' }}
                        />
                    ) : null}
                </Grid>
            )}

            <Grid xs={12}>
                <Typography
                    color="text.secondary"
                    data-testid="organisation-tax-calculation-setting"
                    mb={1}
                    variant="body1"
                >
                    {t('Tax calculation methods')}
                </Typography>
                <Typography
                    color="text.secondary"
                    mb={1}
                    variant="small"
                >
                    {t(
                        'Each method rounds tax calculations at a different stage, which can result in a different tax total. Set this to match your downstream systems.'
                    )}
                </Typography>
                <Controller
                    control={control}
                    name="calculateTaxOnSubtotal"
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup
                            data-testid="organisation-tax-calculation-method"
                            value={value}
                            onChange={(e, value) => {
                                onChange(value)
                            }}
                        >
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                data-testid="organisation-tax-calculation-method-subtotal"
                                key="calculateTaxOnSubtotal"
                                label={t('Calculated on the quote subtotal')}
                                slotProps={{
                                    typography: {
                                        variant: 'body1',
                                        color: 'text.secondary',
                                    },
                                }}
                                value={true}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                data-testid="organisation-tax-calculation-method-line-items"
                                key="calculateTaxOnLineItems"
                                label={t('Calculated per line item')}
                                slotProps={{
                                    typography: {
                                        variant: 'body1',
                                        color: 'text.secondary',
                                    },
                                }}
                                value={false}
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>

            {showAddTaxRateDialog ? (
                <AddTaxRateDialog
                    open={showAddTaxRateDialog}
                    taxRate={taxRateToEdit}
                    onClose={handleCloseAddTaxRateDialog}
                />
            ) : null}

            {showArchiveTaxRateDialog ? (
                <AlertDialog
                    content={t('Are you sure want to archive {{taxRateName}} rate from your organisation?', {
                        taxRateName: taxRateToArchive?.displayName,
                    })}
                    okButtonText={t('Yes, Archive')}
                    open={showArchiveTaxRateDialog}
                    title={t('Archive tax rate')}
                    onCancelClose={handleCancelArchive}
                    onOkClose={handleArchiveConfirmation}
                />
            ) : null}

            {showUnarchiveTaxRateDialog ? (
                <AlertDialog
                    content={t('Are you sure want to unarchive {{taxRateName}} rate from your organisation?', {
                        taxRateName: taxRateToArchive?.displayName,
                    })}
                    okButtonText={t('Yes, Unarchive')}
                    open={showUnarchiveTaxRateDialog}
                    title={t('Unarchive tax rate')}
                    onCancelClose={handleCancelArchive}
                    onOkClose={handleUnarchiveConfirmation}
                />
            ) : null}
        </>
    )
}

TaxRates.propTypes = {
    control: PropTypes.object.isRequired,
}

export default TaxRates
