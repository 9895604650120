import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddOrganisationUserMutation } from '@/app/services/organisationUsers'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { validateEmail } from '@/common/utils/GeneralUtils/GeneralUtils'

const classes = {}

const AddUserDialog = ({ onAddUser, onClose, open = false }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [addOrganisationUser, { isLoading }] = useAddOrganisationUserMutation()

    const userRoles = [
        { name: t('User'), value: 'user' },
        { name: t('Administrator'), value: 'administrator' },
    ]

    const helperTextMap = {
        user: t('Create quotes, process drawings and download outputs'),
        administrator: t(
            'Manage materials and users. Create quotes, process drawings and download outputs. The Administrator (Owner) can also manage subscriptions.'
        ),
    }

    const {
        control,
        formState: { isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            role: 'user',
        },
    })

    const handleAddButtonClick = async (userData, _e) => {
        const { emailAddress, firstName, lastName, role } = userData

        try {
            await addOrganisationUser({
                organisationId,
                firstName,
                lastName,
                emailAddress,
                role,
            })
            enqueueSnackbar(t('User added successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to add user'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            reset()
        }
        onAddUser()
    }

    const closeDialog = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: 380,
                    borderRadius: 2,
                    p: 2,
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                <Typography variant="h6">{t('Add new user')}</Typography>
                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('First Name') + ' *'}
                                value={value}
                                autoFocus
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: classes.input }}
                                label={t('Last Name') + ' *'}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />
                    <Controller
                        control={control}
                        name="emailAddress"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: classes.input }}
                                label={t('Email Address') + ' *'}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            required: 'Required',
                            validate: (value) => {
                                return validateEmail(value) || t('Invalid email address')
                            },
                        }}
                    />

                    <Controller
                        control={control}
                        name="role"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <FormControl>
                                <InputLabel datatest-id="material-type-label">{t('Role')} *</InputLabel>
                                <Select
                                    error={fieldState.invalid}
                                    label={t('Role')}
                                    value={value}
                                    variant="standard"
                                    fullWidth
                                    required
                                    onBlur={onBlur}
                                    onChange={onChange}
                                >
                                    {userRoles.map((role) => (
                                        <MenuItem
                                            key={role.value}
                                            value={role.value}
                                        >
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{helperTextMap[value]}</FormHelperText>
                            </FormControl>
                        )}
                        rules={{ required: true }}
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isValid || isLoading}
                    key="add"
                    variant="contained"
                    onClick={handleSubmit(handleAddButtonClick)}
                >
                    {isLoading ? t('Adding user...') : t('Add user')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddUserDialog.propTypes = {
    open: PropTypes.bool,
    onAddUser: PropTypes.func,
    onClose: PropTypes.func,
}

export default AddUserDialog
