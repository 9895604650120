import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowForwardRounded } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useLoginMutation, useValidateCodeMutation } from '@/app/services/web-store/webStoreAuth'
import { useLazyGetCustomerQuery } from '@/app/services/web-store/webStoreCustomer'
import { setGuestUserLoggin } from '@/app/slices/web-store/webStoreAuthSlice'

import { acceptedPatterns } from '../../helpers/utilities'

const classes = {
    root: (theme) => ({
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'grey[300]',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: '410px',
        },
        [theme.breakpoints.up('md')]: {
            height: '680px',
        },
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 114px)',
        },
    }),
    mainContent: {
        maxWidth: '600px',
        gap: 5,
    },
    mainContent_text: {
        gap: 4,
    },
    logInInput: {
        marginBottom: 2,
    },
    loginAction: {
        paddingInline: 2,
        paddingBlockEnd: 3,
        gap: 2,
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const Login = ({ onSuccessClose, showGuestButton = true, showRequestAccessButton = true }) => {
    const { organisationId } = useParams()
    const theme = useTheme()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loginStep, setLoginStep] = useState('requestAccessCode')
    const [email, setEmail] = useState('')
    const [accessCode, setAccessCode] = useState('')
    const [fullName, setFullName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const [requestAccessCode, { isLoading: isRequesting }] = useLoginMutation()
    const [validateAccessCode, { isLoading: isValidating }] = useValidateCodeMutation()

    const [getCustomer] = useLazyGetCustomerQuery()

    function isValidEmail(email) {
        return acceptedPatterns.email.test(email)
    }

    const handleEmailChange = (event) => {
        if (!isValidEmail(event.target.value)) {
            setError(true)
            setErrorMessage(t('Please enter a valid email address'))
        } else {
            setError(false)
            setErrorMessage('')
        }
        setEmail(event.target.value)
    }

    const handleRequestAccessCode = async () => {
        if (isValidEmail(email)) {
            try {
                await requestAccessCode({
                    organisationId,
                    emailAddress: email,
                }).unwrap()
                enqueueSnackbar(t('A verification code has been sent to this email address'), { variant: 'success' })
                setLoginStep('validateAccessCode')
            } catch (_error) {
                const errorMessage = t('A verification code could not be sent at this time. Please try again later.')
                setError(true)
                setErrorMessage(errorMessage)
                enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
            }
        }
    }

    const handleValidateAccessCode = async () => {
        let redirectURL
        const quoteId = sessionStorage.getItem('redirect')
        if (quoteId) {
            redirectURL = `/store/${organisationId}/${quoteId}`
        } else {
            redirectURL = `/store/${organisationId}/`
        }

        try {
            const response = await validateAccessCode({
                organisationId,
                emailAddress: email,
                code: accessCode,
            }).unwrap()
            enqueueSnackbar(t('Email address verified'), { variant: 'success' })
            await getCustomer({
                organisationId,
                customerId: response.customerId,
            }).unwrap()
            if (onSuccessClose && typeof onSuccessClose === 'function') {
                onSuccessClose()
            } else {
                navigate(redirectURL)
                sessionStorage.removeItem('redirect')
            }
        } catch (error) {
            let errorMessage = t('The code you entered is not valid. Please request a new verification code.')
            if (error?.status === 404) {
                setLoginStep('invalidAccount')
                errorMessage = t('You do not yet have access to this store. Please request access.')
            }
            setAccessCode('')
            setError(true)
            setErrorMessage(errorMessage)
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleRequestAccountAccess = () => {
        enqueueSnackbar('Request access to store not implemented yet', {
            variant: 'info',
        })
        //TODO: Implement request access
        setLoginStep('requestAccountAccessSuccess')
    }

    const handleGoToLogin = () => {
        setAccessCode('')
        setError(false)
        setErrorMessage('')
        setLoginStep('requestAccessCode')
    }

    const handleRequestAccessToStore = () => {
        setAccessCode('')
        setError(false)
        setErrorMessage('')
        setLoginStep('requestAccountAccess')
    }

    const handleContinueAsGuest = () => {
        dispatch(setGuestUserLoggin())
        navigate(`/store/${organisationId}/`)
    }

    return (
        <Box sx={{ width: '100%' }}>
            {loginStep === 'requestAccessCode' ? (
                <Card elevation={0}>
                    <CardHeader title={t('Log In')} />
                    <CardContent>
                        <TextField
                            color="primary"
                            error={error}
                            helperText={errorMessage}
                            placeholder={t('Email address')}
                            size="medium"
                            sx={classes.logInInput}
                            type="email"
                            value={email}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => handleEmailChange(e)}
                        />
                        <Button
                            color="primary"
                            disabled={!isValidEmail(email) || isRequesting}
                            endIcon={<ArrowForwardRounded />}
                            size="large"
                            sx={classes.logInInput}
                            variant="contained"
                            fullWidth
                            onClick={handleRequestAccessCode}
                        >
                            {t('Log in')}
                        </Button>

                        {(storeSettings?.webStoreIsPublic && showGuestButton) || showRequestAccessButton ? (
                            <Typography align="center">{t('or')}</Typography>
                        ) : null}
                    </CardContent>
                    <CardActions sx={classes.loginAction}>
                        {showRequestAccessButton ? (
                            <Button
                                color="secondary"
                                size="medium"
                                variant="outlined"
                                fullWidth
                                onClick={() => setLoginStep('requestAccountAccess')}
                            >
                                {t('Request access')}
                            </Button>
                        ) : null}
                        {storeSettings?.webStoreIsPublic && showGuestButton ? (
                            <Button
                                color="secondary"
                                size="medium"
                                variant="contained"
                                fullWidth
                                onClick={handleContinueAsGuest}
                            >
                                {t('Continue as guest')}
                            </Button>
                        ) : null}
                    </CardActions>
                </Card>
            ) : null}

            {loginStep === 'validateAccessCode' ? (
                <Card elevation={0}>
                    <CardHeader
                        subheader={t('Enter the access code sent to your email address.')}
                        title={t('Verify account')}
                    />
                    <CardContent>
                        <TextField
                            color="primary"
                            placeholder={t('Email address')}
                            size="medium"
                            sx={classes.logInInput}
                            type="email"
                            value={email}
                            variant="outlined"
                            disabled
                            fullWidth
                            required
                        />
                        <TextField
                            color="primary"
                            error={error}
                            helperText={errorMessage}
                            placeholder={t('Access code')}
                            size="medium"
                            sx={classes.logInInput}
                            type="text"
                            value={accessCode}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setAccessCode(e.target.value)}
                        />
                        <Button
                            color="primary"
                            disabled={!accessCode || isValidating}
                            endIcon={<ArrowForwardRounded />}
                            size="large"
                            sx={classes.logInInput}
                            variant="contained"
                            fullWidth
                            onClick={handleValidateAccessCode}
                        >
                            {t('Verify code')}
                        </Button>
                    </CardContent>
                    <CardActions sx={classes.loginAction}>
                        <Typography variant="body1">{t("Didn't receive your access code?")}</Typography>
                        <Button
                            color="secondary"
                            size="small"
                            variant="text"
                            onClick={handleGoToLogin}
                        >
                            {t('Request new access code')}
                        </Button>
                    </CardActions>
                </Card>
            ) : null}

            {loginStep === 'invalidAccount' ? (
                <Card elevation={0}>
                    <CardHeader title={t('Invalid account')} />
                    <CardContent>
                        <TextField
                            color="primary"
                            placeholder={t('Email address')}
                            size="medium"
                            sx={classes.logInInput}
                            type="email"
                            value={email}
                            variant="outlined"
                            disabled
                            fullWidth
                            required
                        />
                        <Typography
                            style={{ marginBottom: '1rem', fontWeight: 700 }}
                            variant="body1"
                        >
                            {t(
                                "Your account currently doesn't have access to {{storeName}}'s store. Please request access for your account.",
                                { storeName: storeSettings?.webStoreDisplayName }
                            )}
                        </Typography>

                        <Button
                            color="primary"
                            disabled={false}
                            endIcon={<ArrowForwardRounded />}
                            size="large"
                            sx={classes.logInInput}
                            variant="contained"
                            fullWidth
                            onClick={handleRequestAccessToStore}
                        >
                            {t('Request access to store')}
                        </Button>
                    </CardContent>
                    <CardActions sx={classes.loginAction}>
                        <Typography variant="body1">{t('Already have an active account?')}</Typography>
                        <Button
                            color="secondary"
                            size="small"
                            variant="text"
                            onClick={handleGoToLogin}
                        >
                            {t('Go to Log In')}
                        </Button>
                    </CardActions>
                </Card>
            ) : null}

            {loginStep === 'requestAccountAccess' ? (
                <Card elevation={0}>
                    <CardHeader
                        subheader={t(
                            'Please enter the information below to request access to {{storeName}} store for your account.',
                            { storeName: storeSettings?.webStoreDisplayName }
                        )}
                        title={t('Request access')}
                    />
                    <CardContent>
                        <TextField
                            color="primary"
                            error={error}
                            helperText={errorMessage}
                            placeholder={t('Email address')}
                            size="medium"
                            sx={classes.logInInput}
                            type="email"
                            value={email}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => handleEmailChange(e)}
                        />
                        <TextField
                            color="primary"
                            placeholder={t('Full name')}
                            size="medium"
                            sx={classes.logInInput}
                            type="text"
                            value={fullName}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                            color="primary"
                            placeholder={t('Company name')}
                            size="medium"
                            sx={classes.logInInput}
                            type="text"
                            value={companyName}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <Button
                            color="primary"
                            disabled={!email || !fullName || !companyName}
                            endIcon={<ArrowForwardRounded />}
                            size="large"
                            sx={classes.logInInput}
                            variant="contained"
                            fullWidth
                            onClick={handleRequestAccountAccess}
                        >
                            {t('Send access request')}
                        </Button>
                    </CardContent>
                    <CardActions sx={classes.loginAction}>
                        <Typography variant="body1">{t('Already have an active account?')}</Typography>
                        <Button
                            color="secondary"
                            size="small"
                            variant="text"
                            onClick={handleGoToLogin}
                        >
                            {t('Go to Log In')}
                        </Button>
                    </CardActions>
                </Card>
            ) : null}

            {loginStep === 'requestAccountAccessSuccess' ? (
                <Card elevation={0}>
                    <CardHeader
                        subheader={t(
                            "Please enter the information below to request access to {{storeName}}'s store for your account.",
                            { storeName: storeSettings?.webStoreDisplayName }
                        )}
                        title={t('Request access')}
                    />
                    <CardContent>
                        <TextField
                            color="primary"
                            error={error}
                            helperText={errorMessage}
                            placeholder={t('Email address')}
                            size="medium"
                            sx={classes.logInInput}
                            type="email"
                            value={email}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => handleEmailChange(e)}
                        />
                        <TextField
                            color="primary"
                            placeholder={t('Full name')}
                            size="medium"
                            sx={classes.logInInput}
                            type="text"
                            value={fullName}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                            color="primary"
                            placeholder={t('Company name')}
                            size="medium"
                            sx={classes.logInInput}
                            type="text"
                            value={companyName}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <Button
                            color="primary"
                            disabled={!email || !fullName || !companyName}
                            endIcon={<ArrowForwardRounded />}
                            size="large"
                            sx={classes.logInInput}
                            variant="contained"
                            fullWidth
                            onClick={handleRequestAccountAccess}
                        >
                            {t('Send access request')}
                        </Button>
                    </CardContent>
                    <CardActions sx={classes.loginAction}>
                        <Typography variant="body1">{t('Already have an active account?')}</Typography>
                        <Button
                            color="secondary"
                            size="small"
                            variant="text"
                            onClick={handleGoToLogin}
                        >
                            {t('Go to Log In')}
                        </Button>
                    </CardActions>
                </Card>
            ) : null}
        </Box>
    )
}

Login.propTypes = {
    showGuestButton: PropTypes.bool,
    showRequestAccessButton: PropTypes.bool,
    onSuccessClose: PropTypes.func,
}

export default Login
