import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DeleteForeverRounded, UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { Box, Checkbox, Fab, FormControlLabel, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddMiscItemMutation, useDeleteMiscItemsMutation, useGetMiscItemsQuery } from '@/app/services/miscItems'
import { useCalculateQuoteMutation } from '@/app/services/quotes'
import {
    addExpandedMiscItem,
    clearExpandedMiscItems,
    clearSelectedMiscItems,
    selectSelectedMiscItems,
    setExpandedMiscItemsIds,
    setSelectedMiscItemsIds,
} from '@/app/slices/miscItemsSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { AddIcon } from '@/common/icons'
import TbxDialog from '@/features/web-store/components/shared/TbxDialog'

import MiscItemListSort from './MiscItemListSort'

const MiscItemListActions = ({ isLoadingItems }) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const selectedMiscItemsArray = useSelector(selectSelectedMiscItems)

    const { data: miscItems } = useGetMiscItemsQuery({ organisationId, quoteId })

    const [addNewMiscItem, { isLoading: isAdding }] = useAddMiscItemMutation()
    const [deleteQuoteItems, { isLoading: isDeleting }] = useDeleteMiscItemsMutation()
    const [calculateQuoteMutation] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [allCollapsed, setAllCollapsed] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const miscItemsIds = miscItems?.map((item) => item?.id)

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDeleteDialogOpen = () => {
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const collapseAll = () => {
        dispatch(clearExpandedMiscItems())
        setAllCollapsed(true)
    }

    const expandAll = () => {
        dispatch(setExpandedMiscItemsIds(miscItemsIds))
        setAllCollapsed(false)
    }

    const handleSelectAllMiscItems = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            dispatch(setSelectedMiscItemsIds(miscItemsIds))
        } else {
            dispatch(clearSelectedMiscItems())
        }
    }

    const handleAddNewMiscItem = async () => {
        dispatch(clearSelectedMiscItems())

        try {
            const newItem = await addNewMiscItem({
                organisationId,
                quoteId,
                miscItem: {
                    quoteId,
                    name: `Miscellaneous item ${miscItems?.length + 1}`,
                    index: miscItems?.length,
                    price: 0,
                },
            }).unwrap()

            dispatch(addExpandedMiscItem(newItem.id))

            enqueueSnackbar(t('New miscellaneous item added'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to add new miscellaneous item'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDeleteMiscItems = async () => {
        const miscItemsCount = selectedMiscItemsArray.length

        try {
            await deleteQuoteItems({
                organisationId,
                quoteId: quoteId,
                miscItemsIds: selectedMiscItemsArray,
            }).unwrap()
            enqueueSnackbar(t(`${miscItemsCount} parts deleted`), { variant: 'success' })
            dispatch(clearSelectedMiscItems())
            dispatch(clearExpandedMiscItems())
            calculateQuote()
        } catch (error) {
            enqueueSnackbar(t(`Failed to delete ${miscItemsCount} $t(quote) items`), {
                variant: 'error',
            })
        } finally {
            setIsDeleteDialogOpen(false)
        }
    }

    const itemsSelectedLabel = useMemo(() => {
        const itemsSelectedCount = selectedMiscItemsArray.length
        return miscItemsIds?.length === selectedMiscItemsArray.length
            ? 'All items selected'
            : selectedMiscItemsArray.length === 0
              ? 'Select all'
              : `${itemsSelectedCount} ${itemsSelectedCount > 1 ? 'items' : 'item'} selected`
    }, [selectedMiscItemsArray, miscItemsIds])

    useEffect(() => {
        if (!miscItemsIds?.length) {
            if (selectAll !== false) {
                setSelectAll(false)
            }
        } else {
            const allSelected = miscItemsIds.every((id) => selectedMiscItemsArray.includes(id))
            if (selectAll !== allSelected) {
                setSelectAll(allSelected)
            }
        }
        // Stringified quoteItemsIds prevents the app from infinite rerenders
    }, [JSON.stringify(miscItemsIds), selectedMiscItemsArray, selectAll])

    useEffect(() => {
        if (!isLoadingItems && miscItems?.length) {
            dispatch(setExpandedMiscItemsIds(miscItemsIds))
        }
    }, [isLoadingItems])

    useEffect(() => {
        return () => {
            dispatch(setSelectedMiscItemsIds([]))
        }
    }, [])

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={3}
                justifyContent="space-between"
            >
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                >
                    <FormControlLabel
                        aria-label="Select all miscellaneous items"
                        control={
                            <Checkbox
                                checked={selectAll}
                                color="secondary"
                                indeterminate={!selectAll ? selectedMiscItemsArray.length > 0 : null}
                                inputProps={{
                                    'data-testid': 'select-all-misc',
                                }}
                                size="small"
                                onChange={handleSelectAllMiscItems}
                            />
                        }
                        data-testid="select-all-misc-label"
                        disabled={!miscItems?.length}
                        label={t(itemsSelectedLabel)}
                        sx={{ ml: 0 }}
                    />

                    <TbxTooltip
                        title={!selectedMiscItemsArray.length ? t('Select the items to delete') : ''}
                        arrow
                    >
                        <span>
                            <IconButton
                                aria-label="delete"
                                color="secondary"
                                disabled={!selectedMiscItemsArray.length}
                                size="small"
                                onClick={handleDeleteDialogOpen}
                            >
                                <DeleteForeverRounded />
                            </IconButton>
                        </span>
                    </TbxTooltip>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={2}
                >
                    <Fab
                        aria-label="add item"
                        color="secondary"
                        disabled={isAdding}
                        size="small"
                        onClick={handleAddNewMiscItem}
                    >
                        <AddIcon />
                    </Fab>

                    <MiscItemListSort />

                    {allCollapsed ? (
                        <IconButton
                            aria-label="Expand all"
                            color="secondary"
                            size="large"
                            onClick={expandAll}
                        >
                            <UnfoldMore />
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-label="Collapse all"
                            color="secondary"
                            size="large"
                            onClick={collapseAll}
                        >
                            <UnfoldLess />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Proceed')}
                content={t(
                    `You are about to delete ${selectedMiscItemsArray.length} ${
                        selectedMiscItemsArray.length > 1 ? 'items' : 'item'
                    } from $t(quote)`
                )}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleDeleteMiscItems}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={quoteId}
                title={t(
                    `Delete ${selectedMiscItemsArray.length} ${selectedMiscItemsArray.length > 1 ? 'items' : 'item'}`
                )}
            />
        </>
    )
}

MiscItemListActions.propTypes = {
    isLoadingItems: PropTypes.bool,
}
export default MiscItemListActions
