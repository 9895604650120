import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFromPartsMutation } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'

import QuickPart from '../QuickPart/QuickPart'

const classes = {
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        p: 0,
    },
}

const AddQuickPartDialog = ({ onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { quoteId } = useParams()
    const organisationId = useSelector(selectOrganisationId)
    const organisation = useSelector(selectOrganisation)
    const locale = useSelector(selectLocale)

    const [uploadQuoteItems] = useAddQuoteItemsFromPartsMutation()

    const handleClose = () => {
        onClose()
    }

    const handleQuickPartSubmit = async (drawing) => {
        const partsToAdd = {
            drawing: drawing,
            partSource: 'quickpart',
        }

        try {
            await uploadQuoteItems({ organisationId, quoteId, parts: [partsToAdd] }).unwrap()
            enqueueSnackbar(`${t('QuickPart added to $t(quote)')}`, { variant: 'success' })
            handleClose()
        } catch (error) {
            const errorMessage = t('$t(An error occurred) adding QuickPart')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <QuickPart
                    drawingUnits={organisation.defaultDrawingUnits}
                    locale={locale}
                    nqp={true}
                    onCancelClicked={handleClose}
                    onSubmitClicked={handleQuickPartSubmit}
                />
            </DialogContent>
        </Dialog>
    )
}

AddQuickPartDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddQuickPartDialog
