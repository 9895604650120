import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'

import { useGetQuoteItemsQuery, useUpdateQuoteItemsMutation } from '@/app/services/quoteItems'
import { useCalculateQuoteMutation, useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import {
    selectFilterOptions,
    selectOpenDrawingDoctorModal,
    selectOpenPartQuoteHistoryModal,
    selectSelectedPartFromLibrary,
    setFilterOptions,
    setIsCalculatingQuote,
    setOpenDrawingDoctorModal,
    setOpenPartQuoteHistoryModal,
    setSelectedPartFromLibrary,
    setSelectedQuoteItemDrawingId,
    setSelectedQuoteStatus,
} from '@/app/slices/quoteItemsSlice'
import { QUOTE_STATUS } from '@/common/utils'
import { quoteItemErrors, quoteItemHasIssues, quoteItemUnset, quoteItemWarnings } from '@/common/utils/quoteUtilities'
import PartQuoteHistoryDialog from '@/features/customer-central/components/Tabs/PartLibraryTab/PartQuoteHistoryDialog'

import DrawingDoctorModal from '../DrawingDoctorModal/DrawingDoctorModal'

import QuoteItem from './ItemContent/QuoteItem'
import QuoteItemOverlay from './QuoteItemOverlay'
import SeekQuoteItemOverlay from './SeekQuoteItemOverlay'

const QuoteItemList = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { quoteId } = useParams()

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const organisationId = useSelector(selectOrganisationId)

    const isDrawingDoctorModalOpen = useSelector(selectOpenDrawingDoctorModal)
    const isPartQuoteHistoryModalOpen = useSelector(selectOpenPartQuoteHistoryModal)
    const selectedPartFromLibrary = useSelector(selectSelectedPartFromLibrary)

    const filterOptions = useSelector(selectFilterOptions)
    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { data: quoteItems, isLoading: isLoadingQuoteitems } = useGetQuoteItemsQuery({ organisationId, quoteId })

    const quoteItemsIds = useMemo(() => {
        if (isLoadingQuoteitems) return []
        return quoteItems.map((item) => item?.id)
    }, [quoteItems, isLoadingQuoteitems])

    const [calculateQuoteMutation, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [updateQuoteItems] = useUpdateQuoteItemsMutation()
    const [updateQuote] = useUpdateQuoteMutation()

    const [activeItemId, setActiveItemId] = useState(null)
    const [activeItemName, setActiveItemName] = useState(null)

    const onDragStart = (event) => {
        const id = event.active.id
        setActiveItemId(id)

        const activeItem = quoteItems.find((item) => item?.id === id)
        setActiveItemName(activeItem.name)
    }

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleUpdateQuoteItems = async (sortedList) => {
        try {
            await updateQuoteItems({
                organisationId,
                quoteId,
                quoteItems: sortedList,
            }).unwrap()
        } catch (e) {
            enqueueSnackbar(t('Failed to reorder $t(quote) items. Undoing changes'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } finally {
            setActiveItemId(null)
        }
    }

    const handleDrawingCleanerClose = () => {
        dispatch(setOpenDrawingDoctorModal(false))
        dispatch(setSelectedQuoteItemDrawingId(null))
        dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        }
    }

    const handleClosePartQuoteHistoryModal = () => {
        dispatch(setOpenPartQuoteHistoryModal(false))
        dispatch(setSelectedPartFromLibrary(null))
    }

    const onDragEnd = useCallback(
        (event) => {
            const { active, over } = event

            if (!active || !over) {
                return
            }

            if (active.id === over.id) {
                return
            }

            const oldIndex =
                active?.data?.current?.sortable?.index ?? quoteItems.find((item) => item?.id === active.id)?.index
            const newIndex = over.data.current.sortable.index

            const items = structuredClone(arrayMove(quoteItems, oldIndex, newIndex))

            items?.forEach((item, index) => {
                item.index = index
            })

            if (selectedQuote.sortQuoteItemsBy !== 'Custom') {
                handleUpdateQuote({ sortQuoteItemsBy: 'Custom', sortQuoteItemsAsc: true })
            }
            handleUpdateQuoteItems(items)
        },
        [quoteItems, updateQuoteItems, quoteId, enqueueSnackbar, t]
    )

    const filteredQuoteItems = useMemo(() => {
        if (filterOptions === 'showAllItems') {
            return quoteItems
        }

        return quoteItems.filter((quoteItem) => {
            if (filterOptions.includes('showUnsetItems') && quoteItemUnset(quoteItem)) {
                return true
            }

            if (
                filterOptions.includes('showWarningItems') &&
                !quoteItemUnset(quoteItem) &&
                quoteItemWarnings(quoteItem)
            ) {
                return true
            }

            if (filterOptions.includes('showErrorItems') && !quoteItemUnset(quoteItem) && quoteItemErrors(quoteItem)) {
                return true
            }

            if (
                filterOptions.includes('showSuccessItems') &&
                !quoteItemHasIssues(quoteItem) &&
                !quoteItemUnset(quoteItem)
            ) {
                return true
            }

            return false
        })
    }, [quoteItems, filterOptions])

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    useEffect(() => {
        if (!filterOptions.includes('showAllItems') && filteredQuoteItems.length <= 0) {
            dispatch(setFilterOptions('showAllItems'))
        }
    }, [filteredQuoteItems, filterOptions])

    useEffect(() => {
        dispatch(setIsCalculatingQuote(isCalculating))
    }, [isCalculating])

    useEffect(() => {
        if (organisationId && quoteId && [QUOTE_STATUS.Draft].includes(selectedQuote?.status)) {
            calculateQuote()
        }
    }, [organisationId, quoteId, selectedQuote?.status])

    return !isLoadingQuoteitems ? (
        <>
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                sensors={sensors}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            >
                <SortableContext
                    items={quoteItemsIds}
                    strategy={verticalListSortingStrategy}
                >
                    <Virtuoso
                        components={{
                            ScrollSeekPlaceholder: SeekQuoteItemOverlay,
                            List: forwardRef(function List(props, ref) {
                                return (
                                    <Box
                                        {...props}
                                        display="flex"
                                        flexDirection="column"
                                        gap={2}
                                        ref={ref}
                                    ></Box>
                                )
                            }),
                        }}
                        data={filteredQuoteItems}
                        defaultItemHeight={510}
                        increaseViewportBy={500}
                        itemContent={(index, quoteItem) => (
                            <QuoteItem
                                id={quoteItem?.id}
                                key={quoteItem?.id}
                            />
                        )}
                        scrollSeekConfiguration={{
                            enter: (velocity) => Math.abs(velocity) > 600,
                            exit: (velocity) => Math.abs(velocity) < 100,
                        }}
                        style={{ height: '100%' }}
                        useWindowScroll
                    />
                </SortableContext>
                <DragOverlay>
                    {activeItemId ? (
                        <QuoteItemOverlay
                            id={activeItemId}
                            name={activeItemName}
                        />
                    ) : null}
                </DragOverlay>
            </DndContext>

            {isDrawingDoctorModalOpen ? (
                <DrawingDoctorModal
                    open={isDrawingDoctorModalOpen}
                    onClose={handleDrawingCleanerClose}
                />
            ) : null}

            {isPartQuoteHistoryModalOpen ? (
                <PartQuoteHistoryDialog
                    handleClose={handleClosePartQuoteHistoryModal}
                    open={isPartQuoteHistoryModalOpen}
                    partLibrary={selectedPartFromLibrary}
                />
            ) : null}
        </>
    ) : null
}

QuoteItemList.propTypes = {}

export default memo(QuoteItemList)
