import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetMaterialsQuery } from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { useGetSheetsQuery } from '@/app/services/sheets'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { isProfileSizeFit, thicknessSameWithTolerance } from '@/common/utils/SheetUtils/SheetUtils'

const compareMaterials = (a, b) => {
    if (a.materialName.toLocaleLowerCase() < b.materialName.toLocaleLowerCase()) {
        return -1
    }
    if (a.materialName.toLocaleLowerCase() > b.materialName.toLocaleLowerCase()) {
        return 1
    }
    return 0
}

export function useQuoteItemPropertyOptions({
    cuttingTechnologyId: cuttingTechnologyValue,
    materialId: materialValue,
    mode,
    partDimensions,
    thickness: thicknessValue,
}) {
    const { t } = useTranslation()
    const organisationId = useSelector(selectOrganisationId)
    const organisation = useSelector(selectOrganisation)
    const { data: materials } = useGetMaterialsQuery({ organisationId })
    const { data: sheets } = useGetSheetsQuery({ organisationId })
    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables } = useGetRateTablesQuery({ organisationId })

    const customersMaterialOptions = [
        { label: t('Yes'), value: 'true' },
        { label: t('No'), value: 'false' },
    ]
    const consumptionModeOptions = [
        {
            label: t(mode === 'flat' ? 'Whole Sheet' : 'Whole Length'),
            value: 'WholeSheet',
        },
        { label: t('Nest Bounds'), value: 'NestBounds' },
    ]

    const grainDirectionOptions = [
        { label: t('None'), value: 'None' },
        { label: t('Horizontal'), value: 'Horizontal' },
        { label: t('Vertical'), value: 'Vertical' },
    ]

    const filterSheet = useCallback(
        (sheet) => {
            const cuttingTech = sheet?.sheetCuttingTechnology?.find(
                (cuttingTech) => cuttingTech?.cuttingTechnologyId === cuttingTechnologyValue
            )
            const isNotDisabled = cuttingTech?.isEnabled ?? true
            return !sheet?.isDeleted && isNotDisabled && isProfileSizeFit(sheet, partDimensions, organisation)
        },
        [cuttingTechnologyValue, partDimensions, organisation]
    )

    const cuttingTechOptions = useMemo(() => {
        if (cuttingTechnologies)
            return Object.values(cuttingTechnologies).filter(
                (tech) => tech?.type?.toLowerCase() === mode && !tech?.isDeleted
            )
    }, [cuttingTechnologies, mode])

    const materialRateTableFilter = useCallback(
        (materialRateTable) =>
            rateTables && rateTables[materialRateTable.rateTableId]?.cuttingTechnologyId === cuttingTechnologyValue,
        [rateTables, cuttingTechnologyValue]
    )

    const materialFilter = useCallback(
        (material) =>
            material.materialRateTables.some(materialRateTableFilter) &&
            !material.isDeleted &&
            material.type.toLowerCase() === mode,
        [materialRateTableFilter, mode]
    )

    const materialOptions = useMemo(() => {
        if (!materials) {
            return
        }
        return Object.values(materials).filter(materialFilter)?.sort(compareMaterials)
    }, [materials, materialFilter])

    const thicknessOptions = useMemo(() => {
        if (!materials || !sheets) {
            return
        }
        const material = materials[materialValue]
        const sheetsForMaterial = material?.sheetIds.map((sheetId) => sheets[sheetId]).filter(filterSheet)
        const thicknesses = sheetsForMaterial?.map((sheet) => sheet?.thickness)?.sort((a, b) => a - b)
        return [...new Set(thicknesses ?? [])]
    }, [materialValue, materials, sheets, filterSheet])

    const sheetOptions = useMemo(() => {
        if (!materials || !sheets) {
            return
        }
        const material = materials[materialValue]
        const sheetsForMaterial = material?.sheetIds
            ?.map((sheetId) => sheets[sheetId])
            ?.filter((sheet) => sheet?.thickness === thicknessValue)
            ?.filter(filterSheet)
            ?.filter((sheet) =>
                mode === 'flat'
                    ? sheet?.thickness === thicknessValue
                    : thicknessSameWithTolerance(sheet, thicknessValue, partDimensions, organisation)
            )
        return (
            sheetsForMaterial?.map((sheet) => ({
                sheetId: sheet?.sheetId,
                sheetLength: sheet?.materialLength,
                sheetWidth: sheet?.sheetWidth,
                sheetHeight: sheet?.sheetHeight,
                sheetSize: `${sheet?.sheetWidth ?? ''} x ${sheet?.sheetHeight ?? ''}`,
                sheetCost: sheet?.sheetCost,
                sheetRatePrice: sheet?.sheetRatePrice,
                expiryDate: sheet?.expiryDate,
                isSoldByOrganisation: sheet?.isSoldByOrganisation,
                defaultMaterialConsumptionMode: sheet?.defaultMaterialConsumptionMode,
            })) ?? []
        )
    }, [materialValue, materials, sheets, filterSheet, thicknessValue])

    return {
        cuttingTechOptions,
        materialOptions,
        thicknessOptions,
        sheetOptions,
        customersMaterialOptions,
        consumptionModeOptions,
        grainDirectionOptions,
    }
}
