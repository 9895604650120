import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useCreateSecondaryProcessMutation,
    useGetSecondaryProcessesQuery,
    useUpdateSecondaryProcessMutation,
} from '@/app/services/secondaryProcesses'
import { selectCurrencySymbol, selectOrganisation, selectUseImperialUnits } from '@/app/slices/organisationSlice'

import ProcessMeasureTypeSelect from '../ProcessMeasureTypeSelect/ProcessMeasureTypeSelect'
import ProcessSetupModeSelect from '../ProcessSetupModeSelect/ProcessSetupModeSelect'

const classes = {
    title: {
        fontWeight: 500,
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: 'text.secondary',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
    switch: {
        width: '100%',
        justifyContent: 'space-between',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '24px',
    },
}

const AddSecondaryProcessForm = ({ onCancel, onSave, secondaryProcess }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const { data: secondaryProcesses } = useGetSecondaryProcessesQuery({
        organisationId: organisation.organisationId,
        requestParams: {
            includeDeleted: false,
        },
    })
    const [updateSecondaryProcess, { isLoading: isUpdating }] = useUpdateSecondaryProcessMutation()
    const [createSecondaryProcess, { isLoading: isSaving }] = useCreateSecondaryProcessMutation()

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            name: secondaryProcess?.name || '',
            hourlyRate: secondaryProcess?.hourlyRate || 0,
            setupTimeSeconds: secondaryProcess?.setupTimeSeconds || 0,
            setupMode: secondaryProcess?.setupMode || 'ByLine',
            measureType: secondaryProcess?.measureType || 'rectangulararea1side',
            pricePerMeasure: secondaryProcess?.pricePerMeasure || 0,
            timePerPartSeconds: secondaryProcess?.timePerPartSeconds || 0,
            timePerMeasureSeconds: secondaryProcess?.timePerMeasureSeconds || 0,
            customerVisible: secondaryProcess?.customerVisible ?? true,
        },
    })

    const handleAddSecondaryProcessButtonClick = async (data, _) => {
        const maxIndex = Math.max(-1, ...(secondaryProcesses?.map((p) => p.index ?? -1) ?? []))

        const process = {
            ...data,
            organisationId: organisation.organisationId,
        }

        try {
            if (secondaryProcess) {
                process.secondaryProcessId = secondaryProcess.secondaryProcessId
                process.index = secondaryProcess.index
                await updateSecondaryProcess({
                    organisationId: organisation.organisationId,
                    secondaryProcess: process,
                }).unwrap()

                enqueueSnackbar(t('Secondary process updated'), { variant: 'success' })
            } else {
                process.index = maxIndex + 1
                await createSecondaryProcess({
                    organisationId: organisation.organisationId,
                    secondaryProcess: process,
                }).unwrap()

                enqueueSnackbar(t('Secondary process created'), { variant: 'success' })
            }
            onSave()
        } catch (error) {
            enqueueSnackbar(t(error.response?.data?.title || 'An error occurred'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        }
    }

    return (
        <>
            <Typography
                sx={classes.title}
                variant="h5"
            >
                {secondaryProcess ? t('Edit secondary process') : t('Add secondary process')}
            </Typography>

            <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'cutting-technology-name',
                        }}
                        label={t('Display Name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Display Name is required' }}
            />

            <Typography sx={classes.addressTitle}>{t('Details')}</Typography>

            <Controller
                control={control}
                name="hourlyRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'add-hourly-rate',
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                        }}
                        label={t('Hourly Rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="setupTimeSeconds"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        inputProps={{
                            'data-testid': 'add-setup-time',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('s')}</InputAdornment>,
                        }}
                        label={t('Setup time')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="setupMode"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <ProcessSetupModeSelect
                        className={classes.input}
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'add-setup-mode',
                        }}
                        label={t('Setup mode')}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="measureType"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <ProcessMeasureTypeSelect
                        className={classes.input}
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'add-measure-type',
                        }}
                        isImperial={useImperialUnits}
                        label={t('Measure type')}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="pricePerMeasure"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={t(fieldState.error?.message)}
                        inputProps={{
                            'data-testid': 'add-price-per-measure',
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                        }}
                        label={t('Price per measure')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="timePerPartSeconds"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        inputProps={{
                            'data-testid': 'add-time-per-part',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('s')}</InputAdornment>,
                        }}
                        label={t('Time per part')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="timePerMeasureSeconds"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        inputProps={{
                            'data-testid': 'add-time-per-measure',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('s')}</InputAdornment>,
                        }}
                        label={t('Time per measure')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="customerVisible"
                render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                        checked={value}
                        control={<Switch color="primary" />}
                        label={t('Customer visible')}
                        labelPlacement="start"
                        sx={classes.switch}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="secondary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddSecondaryProcessButtonClick)}
                >
                    {isSaving || isUpdating ? t('Saving...') : secondaryProcess ? t('Update') : t('Add')}
                </Button>
            </Box>
        </>
    )
}

AddSecondaryProcessForm.propTypes = {
    secondaryProcess: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
}

export default AddSecondaryProcessForm
