import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Archive, EditRounded, Unarchive } from '@mui/icons-material'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetDeliveryContainersQuery } from '@/app/services/deliveryContainers'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import { useCurrencyFormatter } from '@/common/hooks'

const classes = {
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeContainer: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),

    zoneTitle: {
        fontWeight: 500,
        color: 'text.primary',
        textTransform: 'uppercase',
    },
    zoneBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    zoneBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
}

const DeliveryZoneContainerRow = ({
    deliveryPricingOption,
    handleArchiveButtonClick,
    handleEditButtonClick,
    handleUnarchiveButtonClick,
    zoneContainer,
}) => {
    const { t } = useTranslation()
    const { formatCurrency } = useCurrencyFormatter()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const { data: deliveryContainers, isLoading: isLoadingContainers } = useGetDeliveryContainersQuery({
        organisationId,
    })

    const container = useMemo(() => {
        if (!isLoadingContainers && deliveryContainers) {
            return deliveryContainers.find((c) => c.containerId === zoneContainer.containerId)
        }
    }, [deliveryContainers, isLoadingContainers, zoneContainer.containerId])

    return (
        <TableRow
            key={zoneContainer.containerId}
            sx={classes.tableRow}
        >
            <TableCell
                sx={[
                    classes.tableCell,
                    classes.tableCellWithEllipsis,
                    classes.activeContainer(zoneContainer.isEnabled),
                ]}
            >
                {container?.name}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.activeContainer(zoneContainer.isEnabled)]}
            >
                {formatCurrency(zoneContainer.flatPrice) || String.fromCharCode(8212)}
            </TableCell>

            {deliveryPricingOption === 'ByDistance' ? (
                <TableCell
                    align="right"
                    sx={[classes.tableCell, classes.activeContainer(zoneContainer.isEnabled)]}
                >
                    {formatCurrency(zoneContainer.pricePerDistance) || String.fromCharCode(8212)}
                </TableCell>
            ) : null}

            {deliveryPricingOption === 'ByWeight' ? (
                <TableCell
                    align="right"
                    sx={[classes.tableCell, classes.activeContainer(zoneContainer.isEnabled)]}
                >
                    {formatCurrency(zoneContainer.pricePerWeight) || String.fromCharCode(8212)}
                </TableCell>
            ) : null}

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconButton
                        disabled={!zoneContainer.isEnabled}
                        size="small"
                        onClick={() => {
                            handleEditButtonClick(zoneContainer)
                        }}
                    >
                        <EditRounded
                            fontSize="small"
                            sx={classes.iconColor(zoneContainer.isEnabled)}
                        />
                    </IconButton>

                    <TbxTooltip
                        title={
                            zoneContainer.isEnabled ? t('Disable container for zone') : t('Enable container for zone')
                        }
                        arrow
                    >
                        {zoneContainer.isEnabled ? (
                            <span>
                                <IconButton
                                    aria-label="archive"
                                    data-testid="archive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleArchiveButtonClick(zoneContainer)
                                    }}
                                >
                                    <Archive fontSize="small" />
                                </IconButton>
                            </span>
                        ) : (
                            <span>
                                <IconButton
                                    aria-label="unarchive"
                                    data-testid="unarchive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleUnarchiveButtonClick(zoneContainer)
                                    }}
                                >
                                    <Unarchive fontSize="small" />
                                </IconButton>
                            </span>
                        )}
                    </TbxTooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

DeliveryZoneContainerRow.propTypes = {
    deliveryPricingOption: PropTypes.string.isRequired,
    handleArchiveButtonClick: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleUnarchiveButtonClick: PropTypes.func.isRequired,
    zoneContainer: PropTypes.object.isRequired,
}

export default DeliveryZoneContainerRow
