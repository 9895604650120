import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import {
    useGetMaterialsQuery,
    useUpdateMaterialMutation,
    useUpdateMaterialRateTableMutation,
} from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { selectSelectedMaterialId, setSelectedMaterialId } from '@/app/slices/materialsSlice'
import { selectOrganisationId, selectPaidFeatures, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { setSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import TbxCardWithAction from '@/common/components/TbxCardWithAction/TbxCardWithAction'
import TbxDrawer from '@/common/components/TbxDrawer/TbxDrawer'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { useNumberFormatter } from '@/common/hooks'
import { fractionToPercentage, Paths, percentageToFraction } from '@/common/utils'
import ChangeMaterialDensityDialog from '@/features/materials/components/ChangeMaterialDensityDialog'
import AddCuttingTechnologyForm from '@/features/processes/components/AddCuttingTechnologyForm/AddCuttingTechnologyForm'
import AddRateTableForm from '@/features/rate-tables/components/AddRateTableForm'
import MaterialRateTableDialog from '@/features/rate-tables/components/MaterialRateTableDialog'

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'row',
    },

    settingRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '12px',
    },
    settingLabel: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        color: 'text.primary',
        maxWidth: '200px',
    },
    settingValue: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        color: 'text.primary',
        height: 32,
    },
    settingLabelLink: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        maxWidth: '200px',
        cursor: 'pointer',
    },
    settingValueLink: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        height: 32,
        cursor: 'pointer',
    },

    textInput: {
        width: 130,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    disabledLabel: {
        color: (theme) => theme.palette.text.disabled,
    },
}

const MaterialDetails = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const paidFeatures = useSelector(selectPaidFeatures)
    const selectedMaterialId = useSelector(selectSelectedMaterialId)

    const { data: materials, isLoading: isLoadingMaterials } = useGetMaterialsQuery({ organisationId })
    const { data: cuttingTechnologies, isLoading: isLoadingCT } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables, isLoading: isLoadingRT } = useGetRateTablesQuery({ organisationId })
    const selectedMaterial = materials[selectedMaterialId]

    const [updateMaterial] = useUpdateMaterialMutation()
    const [updateMaterialRateTable] = useUpdateMaterialRateTableMutation()

    const [editMaterialSettings, setEditMaterialSettings] = useState(false)
    const [densityData, setDensityData] = useState({})
    const [showChangeMaterialDensityDialog, setShowChangeMaterialDensityDialog] = useState(false)
    const [showMaterialRateTableDialog, setShowMaterialRateTableDialog] = useState(false)
    const [currentCuttingTechId, setCurrentCuttingTechId] = useState('')
    const [showAddCuttingTechDrawer, setShowAddCuttingTechDrawer] = useState(false)
    const [currentMaterialRateTableId, setCurrentMaterialRateTableId] = useState('')
    const [showAddRateTableDrawer, setShowAddRateTableDrawer] = useState(false)

    const selectedMaterialRateTables = useMemo(() => {
        if (!selectedMaterialId) return []

        return selectedMaterial?.materialRateTables
    }, [selectedMaterialId, selectedMaterial])

    const sortMaterialRateTable = useCallback(
        (a, b) => {
            const cuttingTechAName = cuttingTechnologies[rateTables[a.rateTableId]?.cuttingTechnologyId]?.name
            const cuttingTechBName = cuttingTechnologies[rateTables[b.rateTableId]?.cuttingTechnologyId]?.name

            return !!cuttingTechAName?.localeCompare(cuttingTechBName)
        },
        [cuttingTechnologies, rateTables]
    )

    const { n } = useNumberFormatter()

    const {
        control,
        formState: { isValid },
        getValues,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            density: selectedMaterial?.density,
            standardMarkupPercentage: fractionToPercentage(selectedMaterial?.standardMarkupPercentage),
            customerSuppliedMarkupPercentage: fractionToPercentage(selectedMaterial?.customerSuppliedMarkupPercentage),
            sheetRecalculationMode: null,
        },
    })

    const showConfirmMaterialUpdateDialog = () => {
        setShowChangeMaterialDensityDialog(true)
    }

    const hideConfirmMaterialUpdateDialog = () => {
        reset()
        setShowChangeMaterialDensityDialog(false)
    }

    const shouldShowEditMaterialDialog = (newDensity) => {
        return newDensity !== selectedMaterial?.density
    }

    const toggleShowMaterialRateTableDialog = () => {
        setShowMaterialRateTableDialog(!showMaterialRateTableDialog)
    }

    const handleAddCuttingTechDrawerOpen = () => {
        setShowAddCuttingTechDrawer(true)
    }

    const handleAddCuttingTechFormClose = () => {
        setCurrentCuttingTechId(undefined)
        setShowAddCuttingTechDrawer(false)
    }

    const handleAddCuttingTechDrawerClose = () => {
        setCurrentCuttingTechId(undefined)
        setShowAddCuttingTechDrawer(false)
    }

    const handleAddRateTableOpen = (cuttingTechId, materialRateTableId) => {
        setCurrentCuttingTechId(cuttingTechId)
        setCurrentMaterialRateTableId(materialRateTableId)
        setShowAddRateTableDrawer(true)
    }

    const handleAddRateTableClose = () => {
        setCurrentCuttingTechId(undefined)
        setCurrentMaterialRateTableId(undefined)
        setShowAddRateTableDrawer(false)
    }

    const handleAddRateTableSave = (rateTableId) => {
        if (rateTableId) {
            updateMaterialRateTable({
                organisationId,
                materialRateTable: {
                    materialRateTableId: currentMaterialRateTableId,
                    materialId: selectedMaterialId,
                    rateTableId: rateTableId,
                },
            })
        }
    }

    const saveMaterialChanges = async (data) => {
        const updatedMaterial = {
            ...selectedMaterial,
            ...{
                density: data.density,
                densityRecalculationMode: data.densityRecalculationMode ?? null,
                standardMarkupPercentage: data.standardMarkupPercentage
                    ? percentageToFraction(data.standardMarkupPercentage)
                    : null,
                customerSuppliedMarkupPercentage: data.standardMarkupPercentage
                    ? percentageToFraction(data.customerSuppliedMarkupPercentage)
                    : null,
            },
        }
        try {
            const response = await updateMaterial({
                organisationId,
                material: updatedMaterial,
            }).unwrap()
            dispatch(setSelectedMaterialId(response.materialId))
            enqueueSnackbar(t('Material settings updated'), { variant: 'success' })
            reset({ ...data })
        } catch (error) {
            enqueueSnackbar(t('An error occurred updating material settings.'), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        }
    }

    const handleUpdateClick = (data) => {
        if (data.density !== selectedMaterial?.density && shouldShowEditMaterialDialog(data.density)) {
            setDensityData(data)
            showConfirmMaterialUpdateDialog()
        } else {
            saveMaterialChanges(data)
        }
    }

    const toggleEditMaterial = () => {
        if (editMaterialSettings && isValid) {
            handleUpdateClick(getValues())
        }
        if (isValid) {
            setEditMaterialSettings(!editMaterialSettings)
        }
    }

    const confirmMaterialUpdateDialog = (value) => {
        densityData.densityRecalculationMode = value['recalculation-mode']
        saveMaterialChanges(densityData)
        hideConfirmMaterialUpdateDialog()
    }

    const handleRateTablesClick = () => {
        navigate(Paths.RATE_TABLES)
    }

    const selectAndGoToRateTable = (rateTableId) => () => {
        dispatch(setSelectedRateTableId(rateTableId))
        handleRateTablesClick()
    }

    const selectAndEditMaterialCuttingTech = (cuttingTechId) => () => {
        setCurrentCuttingTechId(cuttingTechId)
        handleAddCuttingTechDrawerOpen()
    }

    useEffect(() => {
        reset({
            density: selectedMaterial?.density,
            standardMarkupPercentage: fractionToPercentage(selectedMaterial?.standardMarkupPercentage),
            customerSuppliedMarkupPercentage: fractionToPercentage(selectedMaterial?.customerSuppliedMarkupPercentage),
        })
    }, [reset, selectedMaterial])

    return !isLoadingMaterials && !isLoadingCT && !isLoadingRT ? (
        <>
            <Box sx={classes.container}>
                <Box
                    data-testid="material-properties-card"
                    sx={classes.column}
                >
                    <TbxCardWithAction
                        disabled={selectedMaterial?.isDeleted}
                        isEditing={editMaterialSettings}
                        title={t('Material settings')}
                        onActionButtonClick={toggleEditMaterial}
                    >
                        {!editMaterialSettings ? (
                            <>
                                <Box sx={classes.settingRow}>
                                    <Typography
                                        data-testid="material-density-label"
                                        sx={classes.settingLabel}
                                    >
                                        {`${t('Density')} (${useImperialUnits ? 'lb/ft3' : 'kg/m3'})`}
                                    </Typography>
                                    <Typography sx={classes.settingValue}>{n(selectedMaterial?.density)}</Typography>
                                </Box>

                                <Box sx={classes.settingRow}>
                                    <Typography
                                        data-testid="material-markup-label"
                                        sx={classes.settingLabel}
                                    >
                                        {t('Standard markup')}
                                    </Typography>
                                    <Typography sx={classes.settingValue}>
                                        {fractionToPercentage(selectedMaterial?.standardMarkupPercentage)}%
                                    </Typography>
                                </Box>

                                <Box sx={classes.settingRow}>
                                    <TbxTooltip
                                        leaveDelay={500}
                                        title={paidFeatures.hasCustomerSuppliedMaterial ? '' : <UpgradePlanLink />}
                                        arrow
                                        disableFocusListener
                                        disableTouchListener
                                    >
                                        <Typography
                                            data-testid="material-customer-supplied-material-label"
                                            sx={
                                                paidFeatures.hasCustomerSuppliedMaterial
                                                    ? [classes.settingLabel]
                                                    : [classes.settingLabel, classes.disabledLabel]
                                            }
                                        >
                                            {t('Customer-supplied material handling fee (% material cost)')}
                                        </Typography>
                                    </TbxTooltip>
                                    {paidFeatures.hasCustomerSuppliedMaterial ? (
                                        <Typography sx={classes.settingValue}>
                                            {fractionToPercentage(selectedMaterial?.customerSuppliedMarkupPercentage)}%
                                        </Typography>
                                    ) : null}
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={classes.settingRow}>
                                    <Typography
                                        data-testid="material-density-label"
                                        sx={classes.settingLabel}
                                    >{`${t('Density')} (${useImperialUnits ? 'lb/ft3' : 'kg/m3'})`}</Typography>
                                    <Controller
                                        control={control}
                                        name="density"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                disabled={selectedMaterial?.isDeleted}
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message || ''}
                                                inputProps={{
                                                    'data-testid': 'add-material-density',
                                                    style: { textAlign: 'right' },
                                                }}
                                                sx={classes.textInput}
                                                type="number"
                                                value={value}
                                                fullWidth
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{ required: 'Required' }}
                                    />
                                </Box>
                                <Box sx={classes.settingRow}>
                                    <Typography
                                        data-testid="material-markup-label"
                                        sx={classes.settingLabel}
                                    >
                                        {t('Standard markup')}
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name="standardMarkupPercentage"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                disabled={selectedMaterial?.isDeleted}
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message || ''}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                inputProps={{
                                                    'data-testid': 'add-material-standard-markup',
                                                    style: { textAlign: 'right' },
                                                }}
                                                sx={classes.textInput}
                                                type="number"
                                                value={value}
                                                fullWidth
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={classes.settingRow}>
                                    <Typography
                                        sx={
                                            paidFeatures.hasCustomerSuppliedMaterial
                                                ? [classes.settingLabel]
                                                : [classes.settingLabel, classes.disabledLabel]
                                        }
                                    >
                                        {t('Customer-supplied material handling fee (% material cost)')}
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name="customerSuppliedMarkupPercentage"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TbxTooltip
                                                leaveDelay={500}
                                                title={
                                                    paidFeatures.hasCustomerSuppliedMaterial ? '' : <UpgradePlanLink />
                                                }
                                                arrow
                                                disableFocusListener
                                                disableTouchListener
                                            >
                                                <TextField
                                                    disabled={
                                                        selectedMaterial?.isDeleted ||
                                                        !paidFeatures.hasCustomerSuppliedMaterial
                                                    }
                                                    error={fieldState.invalid}
                                                    helperText={fieldState.error?.message || ''}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}
                                                    inputProps={{
                                                        'data-testid': 'add-customer-supplied-markup',
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    sx={classes.textInput}
                                                    type="number"
                                                    value={paidFeatures.hasCustomerSuppliedMaterial ? value : ''}
                                                    fullWidth
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                />
                                            </TbxTooltip>
                                        )}
                                    />
                                </Box>
                            </>
                        )}
                    </TbxCardWithAction>
                </Box>
                <Box
                    data-testid="rate-tables-for-material"
                    sx={classes.column}
                >
                    <TbxCardWithAction
                        disabled={selectedMaterial?.isDeleted}
                        title={`${t('Rate tables for')} ${t(selectedMaterial?.type)}: ${
                            selectedMaterial?.materialName
                        }`}
                        onActionButtonClick={() => {
                            toggleShowMaterialRateTableDialog()
                        }}
                    >
                        {selectedMaterialRateTables?.length > 0 ? (
                            selectedMaterialRateTables
                                ?.slice()
                                ?.sort(sortMaterialRateTable)
                                .map((mrt, index) => (
                                    <Box
                                        key={index}
                                        sx={classes.settingRow}
                                    >
                                        <Link
                                            underline="hover"
                                            onClick={selectAndEditMaterialCuttingTech(
                                                rateTables[mrt.rateTableId]?.cuttingTechnologyId
                                            )}
                                        >
                                            {cuttingTechnologies[rateTables[mrt.rateTableId]?.cuttingTechnologyId]
                                                ?.name ? (
                                                <Typography sx={classes.settingLabelLink}>{`${t(
                                                    cuttingTechnologies[
                                                        rateTables[mrt.rateTableId]?.cuttingTechnologyId
                                                    ]?.type
                                                )}:
                                                                    ${
                                                                        cuttingTechnologies[
                                                                            rateTables[mrt.rateTableId]
                                                                                ?.cuttingTechnologyId
                                                                        ]?.name
                                                                    }`}</Typography>
                                            ) : null}
                                        </Link>
                                        <Link
                                            underline="hover"
                                            onClick={selectAndGoToRateTable(mrt.rateTableId)}
                                        >
                                            <Typography sx={classes.settingValueLink}>
                                                {rateTables[mrt.rateTableId]?.name}
                                            </Typography>
                                        </Link>
                                    </Box>
                                ))
                        ) : (
                            <Typography
                                mt={1}
                                style={{ color: 'red' }}
                                variant="body2"
                            >
                                {t(
                                    'No cutting speeds or hourly rates have been set for this material. Click the edit button to assign a rate table.'
                                )}
                            </Typography>
                        )}
                    </TbxCardWithAction>
                </Box>
            </Box>

            <TbxDrawer
                showDrawer={showAddCuttingTechDrawer}
                onClose={handleAddCuttingTechDrawerClose}
            >
                <AddCuttingTechnologyForm
                    cuttingTechnology={(currentCuttingTechId && cuttingTechnologies[currentCuttingTechId]) || {}}
                    onCancel={handleAddCuttingTechDrawerClose}
                    onSave={handleAddCuttingTechFormClose}
                />
            </TbxDrawer>

            <TbxDrawer
                showDrawer={showAddRateTableDrawer}
                onClose={handleAddRateTableClose}
            >
                <AddRateTableForm
                    cuttingTechnologyId={currentCuttingTechId}
                    onClose={handleAddRateTableClose}
                    onSave={handleAddRateTableSave}
                />
            </TbxDrawer>

            {selectedMaterial ? (
                <ChangeMaterialDensityDialog
                    handleConfirmButtonClick={confirmMaterialUpdateDialog}
                    material={selectedMaterial}
                    showDialog={showChangeMaterialDensityDialog}
                    onCancelClose={hideConfirmMaterialUpdateDialog}
                />
            ) : null}

            <MaterialRateTableDialog
                addCuttingTech={handleAddCuttingTechDrawerOpen}
                addRateTable={handleAddRateTableOpen}
                showDialog={showMaterialRateTableDialog}
                title={`${t('Rate tables for')} ${selectedMaterial?.materialName}`}
                onClose={toggleShowMaterialRateTableDialog}
            />
        </>
    ) : null
}

export default MaterialDetails
