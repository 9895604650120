import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Checkbox } from '@mui/material'
import Box from '@mui/material/Box'
import { blueGrey } from '@mui/material/colors'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useNumberFormatter } from '@/common/hooks'
import MiscItemIcon from '@/common/icons/MiscItemIcon/MiscItemIcon'

import OrderProcessCell from './OrderProcessCell'

const classes = {
    noPageBreak: {
        breakInside: 'avoid',
    },

    colDivider: {
        py: 2,
        borderRight: `1px dashed ${blueGrey[200]}`,
        typography: 'body2',
        '&:last-of-type': {
            borderRight: 0,
        },
    },

    rowDivider: {
        borderBottom: `1px dashed ${blueGrey[200]}`,
    },
}

const WorkOrderPartRow = ({ colspan, item }) => {
    const { t } = useTranslation()

    const hasNotes = Boolean(item.customerNotes || item.vendorNotes || item.privateNotes)

    const useImperialUnits = useSelector(selectUseImperialUnits)

    const partUnits = useImperialUnits ? 'in' : 'mm'

    const weightUnits = useImperialUnits ? 'lb' : 'kg'

    const { n } = useNumberFormatter()

    const formatNumberWithX = (str) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) => n(Number(part)))
        return formattedParts.join(' x ')
    }

    const secondsToMinutes = (seconds) => {
        if (!seconds) {
            return '0 min'
        }
        const minutes = (seconds / 60).toFixed(2)
        return `${minutes} min`
    }

    const renderPartNameAndId = (item) => {
        if (!item.partId) return <Typography variant="body2">{item.name}</Typography>
        else
            return (
                <>
                    <Typography variant="body2">{item.name}</Typography>
                    <Typography variant="body2">{t('ID: ') + item.partId}</Typography>
                </>
            )
    }

    return (
        <>
            <TableRow sx={classes.noPageBreak}>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasNotes && classes.rowDivider]}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                    >
                        <MiscItemIcon
                            fontSize="large"
                            titleAccess={t('Miscellaneous item icon')}
                        />
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={0.5}
                            justifyContent="center"
                            px={1}
                        >
                            {renderPartNameAndId(item)}
                        </Box>
                    </Box>
                </TableCell>

                {item.dimensions ? (
                    <OrderProcessCell
                        showBottomInput={false}
                        sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                        topInputValue={formatNumberWithX(item.dimensions) + ' ' + partUnits}
                    />
                ) : (
                    <TableCell sx={[classes.colDivider, hasNotes && classes.rowDivider]} />
                )}

                {item.weight ? (
                    <OrderProcessCell
                        showBottomInput={false}
                        sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                        topInputValue={n(item.weight) + ' ' + weightUnits}
                    />
                ) : (
                    <TableCell sx={[classes.colDivider, hasNotes && classes.rowDivider]} />
                )}

                {item.productionTimePerItemSeconds ? (
                    <OrderProcessCell
                        showBottomInput={false}
                        sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                        topInputValue={secondsToMinutes(item.productionTimePerItemSeconds)}
                    />
                ) : (
                    <TableCell sx={[classes.colDivider, hasNotes && classes.rowDivider]} />
                )}

                <TableCell
                    align="right"
                    sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                    >
                        <Typography variant="body2">{item.quantity}</Typography>
                        <Checkbox sx={{ pointerEvents: 'none' }} />
                    </Box>
                </TableCell>
            </TableRow>

            {item.vendorNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={[
                            { py: 2, borderColor: blueGrey[200] },
                            item.privateNotes && { borderBottom: 'none', pb: 0 },
                        ]}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Vendor Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={item.vendorNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}

            {item.privateNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={{ py: 2, borderColor: blueGrey[200] }}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Private Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={item.privateNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    )
}

WorkOrderPartRow.propTypes = {
    colspan: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
}

export default WorkOrderPartRow
