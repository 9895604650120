import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { KeyboardArrowDown } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useSnackbar } from 'notistack'

import { useGetMiscItemsQuery } from '@/app/services/miscItems'
import { useCreatePartLibraryEntriesMutation } from '@/app/services/partLibrary'
import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import {
    useCalculateDeliveryPriceMutation,
    useCalculateQuoteMutation,
    useCalculateTaxRateMutation,
    useDuplicateQuoteMutation,
    useGetQuoteQuery,
    useInvoiceQuoteMutation,
    useOrderQuoteMutation,
    useParagonInvoiceQuoteMutation,
    useUpdateQuoteMutation,
} from '@/app/services/quotes'
import { selectSelectedContact } from '@/app/slices/contactsSlice'
import { selectSelectedCustomer } from '@/app/slices/customersSlice'
import {
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
    selectResolvedPlanMetadata,
} from '@/app/slices/organisationSlice'
import {
    selectedQuoteStatus,
    selectQuotePaymentStatus,
    setIsCalculatingQuote,
    setSelectedQuotePaymentStatus,
    setSelectedQuoteStatus,
} from '@/app/slices/quoteItemsSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { useToolBoxTreatments } from '@/common/hooks/useToolBoxTreatments'
import { Paths, QUOTE_SOURCE_TYPES, QUOTE_STATE_TO_ACTION_MAP, QUOTE_STATUS } from '@/common/utils'
import { quoteHasUnsetItems } from '@/common/utils/quoteUtilities'

import EmailCustomerModal from '../EmailCustomerModal/EmailCustomerModal'

const ActionsButtonGroup = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        showEmailCustomerModal,
        showIntegrationStatus,
        showInvoices,
        showPartLibrary,
        showPayments,
        showRetryIntegration,
    } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const organisation = useSelector(selectOrganisation)
    const paidFeatures = useSelector(selectPaidFeatures)
    const resolvedPlanMetadata = useSelector(selectResolvedPlanMetadata)
    const quoteCustomer = useSelector(selectSelectedCustomer)
    const quoteContact = useSelector(selectSelectedContact)

    const quoteStatus = useSelector(selectedQuoteStatus)
    const quotePaymentStatus = useSelector(selectQuotePaymentStatus)

    const { data: quote, isFetching: isFetchingQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { data: quoteItems, isFetching: isFetchingQuoteItems } = useGetQuoteItemsQuery({ organisationId, quoteId })
    const { data: miscItems, isFetching: isFetchingMiscItems } = useGetMiscItemsQuery({ organisationId, quoteId })

    const [updateQuote, { isLoading: isUpdating }] = useUpdateQuoteMutation({ fixedCacheKey: 'shared-update-quote' })
    const [orderQuote, { isLoading: isOrdering }] = useOrderQuoteMutation()
    const [invoiceQuote, { isLoading: isInvoicing }] = useInvoiceQuoteMutation()
    const [paragonInvoiceQuote, { isLoading: isParagonInvoicing }] = useParagonInvoiceQuoteMutation()
    const [duplicateQuote, { isLoading: isDuplicating }] = useDuplicateQuoteMutation()
    const [calculateQuote, { isLoading: isCalculating }] = useCalculateQuoteMutation()
    const [, { isLoading: isCalculatingDelivery }] = useCalculateDeliveryPriceMutation({
        fixedCacheKey: 'shared-calculate-delivery-price',
    })
    const [, { isLoading: isCalculatingTax }] = useCalculateTaxRateMutation({
        fixedCacheKey: 'shared-calculate-tax-rate',
    })
    const [createPartLibraryEntries] = useCreatePartLibraryEntriesMutation()

    const [anchorEl, setAnchorEl] = useState(null)
    const [displaySendEmailModal, setDisplaySendEmailModal] = useState(false)
    const [documentToSend, setDocumentToSend] = useState(null)

    const quoteNameRef = useRef(quote?.name)

    const open = Boolean(anchorEl)

    const isPartLibraryEnabled = showPartLibrary && paidFeatures?.hasPartLibrary

    const showResendInvoiceData =
        showRetryIntegration &&
        (resolvedPlanMetadata?.xero || resolvedPlanMetadata?.quickbooks) &&
        (organisation.hasXeroIntegration || organisation.hasQuickBooksIntegration)

    const notAcceptedByVendor = useMemo(() => {
        return quote?.quoteSourceType === QUOTE_SOURCE_TYPES.WebStore && quote?.vendorOrderAcceptance === false
    }, [quote])

    const areAllItemsCalculated = useMemo(() => {
        const hasQuoteItems = quoteItems?.length
        const hasMiscItems = miscItems?.length
        if (!hasQuoteItems && !hasMiscItems) return false

        const allQuoteItemsCalculated = quoteItems?.every((item) => Boolean(item.cutPrice))

        return allQuoteItemsCalculated
    }, [quote, quoteItems, miscItems])

    const canCalculateQuote = useMemo(() => {
        return !quoteHasUnsetItems(quoteItems)
    }, [quoteItems])

    const isMenuItemDisabled = (menuItemId) => {
        if (menuItemId === 'calculateQuote') return !canCalculateQuote
        if (menuItemId === 'emailQuote') return !quoteCustomer || !showEmailCustomerModal
        if (menuItemId === 'reviewQuote') return !areAllItemsCalculated
        if (menuItemId === 'markAsIssued') return !areAllItemsCalculated
        if (menuItemId === 'markAsInvoiced') return !showInvoices
        if (menuItemId === 'markAsPaid' || menuItemId === 'markAsPaidAndOrdered') return !showPayments
        if (menuItemId === 'markAsUnpaid') return quotePaymentStatus === 'GatewayPaid' || !showPayments
        if (menuItemId === 'sendToPartLibrary')
            return !areAllItemsCalculated || !paidFeatures.hasPartLibrary || !quoteCustomer || notAcceptedByVendor
    }

    const menuItemDisabledMessage = (menuItemId) => {
        if (menuItemId === 'calculateQuote') return t('This quote has unset parts. Please set up all parts first.')
        if (menuItemId === 'emailQuote') if (!quoteCustomer) return t('There is no contact assigned to this quote.')
        if (!showEmailCustomerModal) return t('Your plan does not include this feature.')
        if (menuItemId === 'reviewQuote') return t('This quote has not been calculated yet.')
        if (menuItemId === 'markAsIssued') return t('This quote has not been calculated yet.')
        if (menuItemId === 'markAsInvoiced') return t('Your plan does not include this feature.')
        if (menuItemId === 'markAsPaid') return t('Your plan does not include this feature.')
        if (menuItemId === 'markAsPaidAndOrdered') return t('Your plan does not include this feature.')
        if (menuItemId === 'markAsUnpaid') {
            if (quotePaymentStatus === 'GatewayPaid') return t('Payment for this order has been received via Stripe.')
            if (!showPayments) return t('Your plan does not include this feature.')
        }
        if (menuItemId === 'sendToPartLibrary') {
            if (!areAllItemsCalculated) return t('This quote has not been calculated yet.')
            if (!quoteCustomer) return t('There is no contact assigned to this quote.')
            if (!paidFeatures.hasPartLibrary) return t('Your plan does not include this feature.')
            if (notAcceptedByVendor) return t('This quote has not been accepted by the vendor.')
        }
    }

    const disableButton = useMemo(() => {
        return (
            isFetchingQuote ||
            isFetchingQuoteItems ||
            isFetchingMiscItems ||
            isUpdating ||
            isOrdering ||
            isInvoicing ||
            isParagonInvoicing ||
            isDuplicating ||
            isCalculating ||
            isCalculatingDelivery ||
            isCalculatingTax
        )
    }, [
        isFetchingQuote,
        isFetchingMiscItems,
        isFetchingQuoteItems,
        isUpdating,
        isOrdering,
        isInvoicing,
        isDuplicating,
        isCalculating,
        isCalculatingDelivery,
        isCalculatingTax,
    ])

    const handleActionButtonClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleActionMenuClose = () => {
        setAnchorEl(null)
    }

    const handleCalculateQuote = async (quote) => {
        handleActionMenuClose()
        dispatch(setIsCalculatingQuote(true))

        try {
            await calculateQuote({ organisationId, quoteId: quote.id }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)

            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        } finally {
            dispatch(setIsCalculatingQuote(false))
        }
    }

    const handleQuoteStatusChange = async (quote, newQuoteStatus) => {
        handleActionMenuClose()
        dispatch(setSelectedQuoteStatus(newQuoteStatus))
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...quote,
                    status: newQuoteStatus,
                    vendorOrderAcceptance:
                        newQuoteStatus === QUOTE_STATUS.PendingOrderConfirmation ? false : quote?.vendorOrderAcceptance,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleOrderQuote = async (quote) => {
        handleActionMenuClose()

        try {
            const response = await orderQuote({
                organisationId,
                quoteId: quote.id,
                params: {
                    sendToPartLibrary: isPartLibraryEnabled,
                },
            }).unwrap()

            if (response.paragonInvoiceFailed) {
                enqueueSnackbar(t('$t(An error occurred) sending the invoice to integrations.'), {
                    variant: 'error',
                })
            }
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleQuotePaymentStatusChange = async (quote, newQuotePaymentStatus) => {
        handleActionMenuClose()
        dispatch(setSelectedQuotePaymentStatus(newQuotePaymentStatus))
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...quote,
                    paymentStatus: newQuotePaymentStatus,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) payment status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleMarkAsPaidAndOrdered = async (quote, newQuotePaymentStatus) => {
        handleActionMenuClose()
        dispatch(setSelectedQuotePaymentStatus(newQuotePaymentStatus))

        try {
            await handleQuotePaymentStatusChange(quote, newQuotePaymentStatus)
            await handleOrderQuote(quote)
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleParagonInvoiceQuote = async (quote) => {
        handleActionMenuClose()
        if (!showResendInvoiceData) return

        try {
            const invoiceResponse = await paragonInvoiceQuote({ organisationId, quoteId: quote.id }).unwrap()

            if (invoiceResponse.integrationExportSucceeded) {
                const integrationMessage =
                    organisation.hasXeroIntegration && organisation.hasQuickBooksIntegration
                        ? 'Xero and QuickBooks'
                        : organisation.hasXeroIntegration
                          ? 'Xero'
                          : organisation.hasQuickBooksIntegration
                            ? 'QuickBooks'
                            : ''

                if (showIntegrationStatus) {
                    const message = t(`The invoice has been created successfully in ${integrationMessage}.`)
                    enqueueSnackbar(message, { variant: 'success' })
                }
            } else if (invoiceResponse.integrationExportFailureMessage && showIntegrationStatus) {
                const errorMessage = t(
                    `$t(An error occurred) sending the quote to the integration: ${invoiceResponse.integrationExportFailureMessage}`
                )

                enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
            }
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleInvoiceQuote = async (quote) => {
        handleActionMenuClose()

        try {
            await invoiceQuote({ organisationId, quoteId: quote.id }).unwrap()
            if ((organisation.hasXeroIntegration || organisation.hasQuickBooksIntegration) && showResendInvoiceData) {
                await handleParagonInvoiceQuote(quote)
            }
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleCopyQuoteLink = async (quote, linkToCopy) => {
        if (!navigator.clipboard) return
        handleActionMenuClose()

        try {
            await navigator.clipboard.writeText(
                `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quote.id}/${linkToCopy}`
            )
            enqueueSnackbar(t('Copied to clipboard'), { variant: 'info' })
        } catch (err) {
            enqueueSnackbar(t('Failed to copy!'), { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleDuplicateQuote = async (quote) => {
        handleActionMenuClose()

        const newName = `${t('Copy of')} ${quoteNameRef.current}`

        try {
            const response = await duplicateQuote({ organisationId, quoteId: quote.id, newName }).unwrap()

            handleCalculateQuote(response)

            navigate(`/quotes/${response.id}`)
        } catch (error) {
            const errorMessage = t('$t(An error occurred) duplicating this $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleSendToPartLibrary = async (quote) => {
        handleActionMenuClose()
        try {
            await createPartLibraryEntries({ organisationId, quoteId: quote.id }).unwrap()

            enqueueSnackbar(
                t(`${areAllItemsCalculated ? 'All' : 'Calculated'} parts successfully sent to Part Library`),
                {
                    variant: 'success',
                }
            )
        } catch (error) {
            const errorMessage = t('$t(An error occurred) sending parts to the library.')
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 })
        }
    }

    const handleSendEmailModalOpen = (quote, documentToSend) => {
        if (!documentToSend || !quote) return

        setDocumentToSend(documentToSend)
        handleActionMenuClose()
        setDisplaySendEmailModal(true)
    }

    const handleSendEmailModalClose = () => {
        setDocumentToSend(null)
        setDisplaySendEmailModal(false)
    }

    const menuItems = useMemo(() => {
        const stateToActionList = QUOTE_STATE_TO_ACTION_MAP(quoteStatus, quotePaymentStatus)
        return stateToActionList?.map((action) => {
            const { handler, id, label, param, tagAttrs } = action
            let handleOptionClick
            switch (handler) {
                case 'handleCalculateQuote':
                    handleOptionClick = handleCalculateQuote
                    break
                case 'handleQuoteStatusChange':
                    handleOptionClick = handleQuoteStatusChange
                    break
                case 'handleMarkAsOrdered':
                    handleOptionClick = handleOrderQuote
                    break
                case 'handleMarkAsInvoiced':
                    handleOptionClick = handleInvoiceQuote
                    break
                case 'handleCopyLink':
                    handleOptionClick = handleCopyQuoteLink
                    break
                case 'handleSendEmail':
                    handleOptionClick = handleSendEmailModalOpen
                    break
                case 'handleSendToPartLibrary':
                    handleOptionClick = handleSendToPartLibrary
                    break
                case 'handleDuplicateQuote':
                    handleOptionClick = handleDuplicateQuote
                    break
                case 'handleQuotePaymentStatusChange':
                    handleOptionClick = handleQuotePaymentStatusChange
                    break
                case 'handleMarkAsPaidAndOrdered':
                    handleOptionClick = handleMarkAsPaidAndOrdered
                    break
                case 'handleResendInvoiceData':
                    handleOptionClick = handleParagonInvoiceQuote
                    break
                default:
                    break
            }

            return {
                id,
                label,
                handleOptionClick,
                param,
                tagAttrs,
                hidden: action?.hidden
                    ? action.hidden(id === 'resendInvoiceData' ? showResendInvoiceData : showPayments)
                    : false,
            }
        })
    }, [quoteStatus, quotePaymentStatus])

    const showPrimaryAction = useMemo(() => {
        return ![QUOTE_STATUS.Lost, QUOTE_STATUS.Cancelled, QUOTE_STATUS.Voided, QUOTE_STATUS.Rejected].includes(
            quoteStatus
        )
    }, [quoteStatus])

    const showEditAction = useMemo(() => {
        return [QUOTE_STATUS.Draft].includes(quoteStatus)
    }, [quoteStatus])

    const primaryAction = useMemo(() => {
        return showPrimaryAction ? menuItems?.[0] : null
    }, [menuItems, showPrimaryAction])

    const editAction = useMemo(() => {
        return showEditAction ? menuItems?.[1] : null
    }, [menuItems, showPrimaryAction])

    useEffect(() => {
        quoteNameRef.current = quote?.name
    }, [quote?.name])

    useEffect(() => {
        if (quote) {
            dispatch(setSelectedQuotePaymentStatus(quote?.paymentStatus))
            dispatch(setSelectedQuoteStatus(quote?.status))
        }
    }, [quote])

    return (
        <>
            {showEditAction ? (
                <Button
                    color="secondary"
                    disabled={disableButton || !areAllItemsCalculated}
                    id={editAction?.id}
                    variant="outlined"
                    disableElevation
                    {...editAction?.tagAttrs}
                    onClick={() => editAction?.handleOptionClick(quote, editAction?.param)}
                >
                    {t(editAction?.label)}
                </Button>
            ) : null}

            {showPrimaryAction ? (
                <TbxTooltip
                    key={`${primaryAction?.id}-tooltip`}
                    title={isMenuItemDisabled(primaryAction?.id) ? menuItemDisabledMessage(primaryAction?.id) : ''}
                    arrow
                >
                    <span>
                        <Button
                            color="primary"
                            disabled={
                                disableButton ||
                                (!areAllItemsCalculated && primaryAction?.id !== 'calculateQuote') ||
                                !canCalculateQuote
                            }
                            id={primaryAction?.id}
                            variant="outlined"
                            disableElevation
                            {...primaryAction?.tagAttrs}
                            onClick={() => primaryAction?.handleOptionClick(quote, primaryAction?.param)}
                        >
                            {t(primaryAction?.label)}
                        </Button>
                    </span>
                </TbxTooltip>
            ) : null}

            <Button
                aria-controls={open ? 'quote-actions-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                color="secondary"
                disabled={disableButton}
                endIcon={<KeyboardArrowDown />}
                id="quote-actions-button"
                variant="contained"
                disableElevation
                onClick={handleActionButtonClick}
            >
                {t('Actions')}
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="quote-actions-button-menu"
                MenuListProps={{
                    'aria-labelledby': 'quote-actions-button',
                }}
                open={open}
                onClose={handleActionMenuClose}
            >
                {menuItems
                    ?.filter((menuItem) => !menuItem.hidden)
                    ?.map((menuItem) =>
                        isMenuItemDisabled(menuItem?.id) ? (
                            <TbxTooltip
                                key={`${menuItem?.id}-tooltip`}
                                title={menuItemDisabledMessage(menuItem?.id)}
                                arrow
                            >
                                <span>
                                    <MenuItem
                                        disabled={isMenuItemDisabled(menuItem?.id)}
                                        key={menuItem?.id}
                                        {...menuItem?.tagAttrs}
                                    >
                                        {t(menuItem?.label)}
                                    </MenuItem>
                                </span>
                            </TbxTooltip>
                        ) : (
                            <MenuItem
                                key={menuItem?.id}
                                {...menuItem?.tagAttrs}
                                onClick={() => menuItem?.handleOptionClick(quote, menuItem?.param)}
                            >
                                {t(menuItem?.label)}
                            </MenuItem>
                        )
                    )}
            </Menu>

            {displaySendEmailModal ? (
                <EmailCustomerModal
                    documentToSend={documentToSend}
                    handleClose={handleSendEmailModalClose}
                    open={displaySendEmailModal}
                    quote={quote}
                    quoteContact={quoteContact}
                />
            ) : null}
        </>
    )
}

export default ActionsButtonGroup
