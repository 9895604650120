import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import * as DOMPurify from 'dompurify'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, OrganisationLogo } from '@/common/components'
import { DELIVERY_PRICING_METHODS, PUBLIC_DOCUMENTS } from '@/common/utils'

import AccountingDocumentsCustomerInfo from '../../components/AccountingDocumentsCustomerInfo'
import QuoteOrderMiscItemRow from '../../components/QuoteOrderMiscItemRow'
import QuoteOrderQuoteItemRow from '../../components/QuoteOrderQuoteItemRow'
import TotalTableRow from '../../components/TotalTableRow'

const classes = {
    documentContainer: {
        width: 1024,
        margin: '24px auto',
    },
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        py: 3,
        px: 3,
        my: 3,
    },
    noPageBreak: {
        breakInside: 'avoid',
    },
    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
}

const OrderConfirmation = ({
    customer,
    customerContact,
    issueSeverityDictionary,
    organisation,
    paymentsEnabled = false,
    quote,
    showShipping,
    taxRate,
}) => {
    const { t } = useTranslation()

    const locale = organisation?.locale

    const sortQuoteItems = (quoteItems) => {
        return quoteItems.toSorted((a, b) => a.index - b.index)
    }

    const sortMiscItems = (miscItems) => {
        return miscItems.toSorted((a, b) => a.index - b.index)
    }

    const deliveryPricingMethod = showShipping
        ? DELIVERY_PRICING_METHODS[quote.deliveryPricingMethod]?.documentLabel
        : ''

    const pickupAddress = organisation?.pickupLine1
        ? {
              line1: organisation?.pickupLine1,
              line2: organisation?.pickupLine2,
              city: organisation?.pickupCity,
              state: organisation?.pickupState,
              postcode: organisation?.pickupPostcode,
              country: organisation?.pickupCountry,
          }
        : organisation?.pickupAddress

    return quote && quote.organisationId && quote.quoteItems ? (
        <Box sx={classes.documentContainer}>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                id="QuoteHeader"
                sx={classes.card}
            >
                <Typography
                    textTransform="uppercase"
                    variant="h4"
                >
                    {t('Order Confirmation')}
                </Typography>

                <Box
                    alignItems="flex-end"
                    display="flex"
                    justifyContent="space-between"
                >
                    {customer ? (
                        <AccountingDocumentsCustomerInfo
                            billingAddress={quote.billingAddress}
                            customer={customer}
                            customerContact={customerContact}
                            deliveryAddress={quote.deliveryAddress}
                            deliveryOption={quote.deliveryOption}
                            deliveryPricingMethod={deliveryPricingMethod}
                            lastModifiedByUserName={quote.lastModifiedByUserName}
                            pickupAddress={pickupAddress}
                        />
                    ) : null}

                    <Box
                        alignItems="flex-end"
                        alignSelf="stretch"
                        className="quote-logo-info"
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <OrganisationLogo
                            organisationId={quote.organisationId}
                            organisationLogoUri={organisation.logoUri}
                            wrapperHeight={100}
                            wrapperWidth="auto"
                        />
                        <Box
                            alignItems="flex-end"
                            className="quote-details-info"
                            display="flex"
                            flexDirection="column"
                            gap={0.5}
                        >
                            {quote.purchaseReference ? (
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {t('Purchase reference:')}
                                    </Typography>
                                    <Typography
                                        minWidth={80}
                                        textAlign="right"
                                        variant="strong2"
                                    >
                                        {quote.purchaseReference}
                                    </Typography>
                                </Box>
                            ) : null}
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Quote number:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {`${quote.name ?? ''}`}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Work order number:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {quote.workOrderNumberText}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('PO number:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {quote.poNumber}
                                </Typography>
                            </Box>

                            {quote.orderedDateUtc ? (
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {t('Ordered date:')}
                                    </Typography>
                                    <Typography
                                        minWidth={80}
                                        textAlign="right"
                                        variant="body2"
                                    >
                                        <DateTime
                                            data-testid="ordered-date"
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.orderedDateUtc}
                                        </DateTime>
                                    </Typography>
                                </Box>
                            ) : null}

                            {quote.requiredDateUtc ? (
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {t('Required shipping date:')}
                                    </Typography>
                                    <Typography
                                        minWidth={80}
                                        textAlign="right"
                                        variant="body2"
                                    >
                                        <DateTime
                                            data-testid="required-shipping-date"
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.requiredDateUtc}
                                        </DateTime>
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                height="160px"
                id="orderNotes"
                sx={classes.card}
            >
                <Typography variant="h6">{t('Order notes')}</Typography>

                {quote.vendorNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Vendor notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.vendorNotes}
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            <Box
                id="QuoteContent"
                sx={classes.card}
            >
                <TableContainer>
                    <Table
                        aria-label="Prices table"
                        sx={{ minWidth: 952 }}
                    >
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                <TableCell
                                    align="left"
                                    width={30}
                                >
                                    #
                                </TableCell>
                                <TableCell
                                    align="center"
                                    width={100}
                                >
                                    {t('Part')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={200}
                                >
                                    {t('Part details')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={180}
                                >
                                    {t('Material details')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={120}
                                >
                                    {t('Details')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ borderRight: '1px dashed rgba(52, 73, 94, 0.38)' }}
                                    width={60}
                                >
                                    {t('Quantity')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={80}
                                >
                                    {t('Unit price')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={80}
                                >
                                    {t('Line total')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortQuoteItems(quote.quoteItems).map((part, index) => (
                                <QuoteOrderQuoteItemRow
                                    colspan={8}
                                    index={index}
                                    issueSeverityDictionary={issueSeverityDictionary}
                                    key={part.id}
                                    organisation={organisation}
                                    part={part}
                                />
                            ))}

                            {sortMiscItems(quote.miscellaneousItems).map((item, index) => (
                                <QuoteOrderMiscItemRow
                                    colspan={8}
                                    index={quote.quoteItems.length + index}
                                    item={item}
                                    key={item.id}
                                    organisation={organisation}
                                />
                            ))}

                            <TotalTableRow
                                currencyCode={organisation.currencyCode}
                                deliveryOption={quote.deliveryOption}
                                deliveryPrice={quote.deliveryPrice}
                                deliveryPricingMethod={quote.deliveryPricingMethod}
                                document={PUBLIC_DOCUMENTS.Quote}
                                linePrice={quote.linePrice}
                                locale={locale}
                                minimumQuoteChargePrice={quote.minimumQuoteChargePrice}
                                organisationId={quote.organisationId}
                                paymentsEnabled={paymentsEnabled}
                                paymentStatus={quote.paymentStatus}
                                quoteId={quote.id}
                                quoteStatus={quote.status}
                                taxRate={taxRate}
                                totalAmount={quote.lineTaxedPrice}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {quote.quoteFooterText ? (
                <Box
                    id="QuoteNotes"
                    sx={[classes.card, classes.noPageBreak]}
                >
                    <Typography
                        mb={1}
                        variant="strong1"
                    >
                        {t('Additional notes')}
                    </Typography>
                    <Typography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(quote.quoteFooterText) }}
                        variant="body2"
                    />
                </Box>
            ) : null}

            {organisation.paymentNotes || organisation.contactDetails ? (
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    id="QuoteFooter"
                    justifyContent="space-between"
                    sx={[classes.card, classes.noPageBreak]}
                >
                    {organisation?.paymentNotes ? (
                        <Typography
                            alignItems="flex-start"
                            color="text.secondary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organisation.paymentNotes) }}
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={1}
                            sx={classes.footerTextContent}
                            textAlign="left"
                            variant="body2"
                            width="50%"
                        />
                    ) : null}
                    {organisation?.contactDetails ? (
                        <Typography
                            alignItems="flex-end"
                            color="text.secondary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organisation.contactDetails) }}
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={1}
                            sx={classes.footerTextContent}
                            textAlign="right"
                            variant="body2"
                            width="50%"
                        />
                    ) : null}
                </Box>
            ) : null}

            {!import.meta.env.VITE_RESELLER ? (
                <Box
                    component="footer"
                    sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                    textAlign="center"
                >
                    <Typography variant="small">
                        {t('Powered by')}{' '}
                        <Link
                            color="primary"
                            href={`https://tempustools.com?org_id=${quote.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=document-branding&utm_content=order-confirmation`}
                            target="_blank"
                            underline="always"
                        >
                            Tempus Tools
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    ) : null
}

OrderConfirmation.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    paymentsEnabled: PropTypes.bool,
    quote: PropTypes.object,
    showShipping: PropTypes.bool,
    taxRate: PropTypes.object,
}

export default OrderConfirmation
