import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, InputAdornment, Link, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateRateMutation, useUpdateRateMutation } from '@/app/services/rates'
import { useGetRateTableQuery } from '@/app/services/rateTables'
import {
    selectCurrencySymbol,
    selectIsOnFreePlan,
    selectOrganisationId,
    selectPaidFeatures,
    selectReduceTrialAccess,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { setSelectedRate } from '@/app/slices/ratesSlice'
import { TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
}

const AddRateForm = ({ onCancel, rate = null, selectedRateTableId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const paidFeatures = useSelector(selectPaidFeatures)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const { data: selectedRateTable } = useGetRateTableQuery({ organisationId, rateTableId: selectedRateTableId })

    const rates = selectedRateTable?.rates

    const [updateRate] = useUpdateRateMutation()
    const [createRate] = useCreateRateMutation()

    const foundSimilarRate = (thickness) => {
        return (
            rates &&
            rates.find(
                (r) => r.thickness.toString() === thickness && r.rateTableId === selectedRateTableId && !r.isDeleted
            )
        )
    }

    const [isSaving, setIsSaving] = useState(false)

    const { control, formState, handleSubmit, watch } = useForm({
        mode: 'all',
        defaultValues: {
            rateId: rate?.rateId || '',
            rateTableId: selectedRateTableId,
            thickness: rate?.thickness || '',
            web: rate?.web || '',
            cuttingFeedRate: rate?.cuttingFeedRate || '',
            pierceTime: rate?.pierceTime || '',
            fineSpeedThreshold: rate?.fineSpeedThreshold || '',
            fineCuttingRate: rate?.fineCuttingRate || '',
            finePierceTime: rate?.finePierceTime || '',
            smallHolesLimit: rate?.smallHolesLimit || '',
            hourlyRateMultiplier: rate?.hourlyRateMultiplier || 1,
            isDeleted: rate?.isDeleted || '',
        },
    })

    const handleAddRateButtonClick = async (data, _) => {
        setIsSaving(true)
        try {
            if (rate) {
                await updateRate({ organisationId, rate: data }).unwrap()
            } else {
                const response = await createRate({ organisationId, rate: data }).unwrap()
                dispatch(setSelectedRate(response))
            }
            enqueueSnackbar(t('Rate saved'), { variant: 'success' })
            onCancel()
        } catch (error) {
            enqueueSnackbar(t('An error occurred'), { variant: 'error', autoHideDuration: 5000 })
        } finally {
            setIsSaving(false)
        }
    }

    const getTitle = () => {
        return t(rate ? 'Edit thickness' : 'Add thickness')
    }

    const getSaveButtonText = () => {
        return t(rate ? 'Update' : 'Add')
    }

    const canAddRate = (thickness) => {
        if (foundSimilarRate(thickness)) {
            return t('Thickness already exists for selected rate table')
        }
        return true
    }

    const hourlyRateMultiplier = watch('hourlyRateMultiplier')
    const getAppliedHourlyRateValue = () => selectedRateTable?.baseHourlyRatePrice * hourlyRateMultiplier

    return (
        <>
            <Typography sx={classes.title}>{getTitle()}</Typography>
            <Controller
                control={control}
                name="thickness"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        disabled={!!rate}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment>{useImperialUnits ? 'in' : 'mm'}</InputAdornment>,
                        }}
                        label={t('Thickness')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: true,
                    min: 0,
                    max: 1000,
                    validate: (value) => !!rate || canAddRate(value),
                }}
            />
            <Controller
                control={control}
                name="web"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment>{useImperialUnits ? 'in' : 'mm'}</InputAdornment>,
                        }}
                        label={t('Web')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required', min: 0, max: 1000 }}
            />
            <Controller
                control={control}
                name="cuttingFeedRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment>{useImperialUnits ? 'in/min' : 'mm/min'}</InputAdornment>,
                        }}
                        label={t('Cutting feed rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required', min: 1, max: 99999999 }}
            />
            <Controller
                control={control}
                name="pierceTime"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment>{t('s')}</InputAdornment>,
                        }}
                        label={t('Pierce time')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required', min: 0, max: 99999999 }}
            />
            <Controller
                control={control}
                name="fineSpeedThreshold"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        leaveDelay={1000}
                        title={paidFeatures.hasAdvancedMaterials ? '' : <UpgradePlanLink />}
                        arrow
                    >
                        <TextField
                            disabled={!paidFeatures.hasAdvancedMaterials}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: <InputAdornment>{useImperialUnits ? 'in' : 'mm'}</InputAdornment>,
                            }}
                            label={t('Fine speed diameter')}
                            sx={classes.input}
                            type="number"
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
                rules={{ min: 1, max: 99999999 }}
            />
            <Controller
                control={control}
                name="fineCuttingRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        leaveDelay={1000}
                        title={
                            isOnFreePlan ? (
                                <Trans t={t}>
                                    Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : reduceTrialAccess ? (
                                <Trans t={t}>
                                    Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : paidFeatures.hasAdvancedMaterials ? (
                                ''
                            ) : (
                                <UpgradePlanLink />
                            )
                        }
                        arrow
                    >
                        <TextField
                            disabled={!paidFeatures.hasAdvancedMaterials || reduceTrialAccess}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: <InputAdornment>{useImperialUnits ? 'in/min' : 'mm/min'}</InputAdornment>,
                            }}
                            label={t('Fine cutting rate')}
                            sx={classes.input}
                            type="number"
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
                rules={{ min: 1, max: 99999999 }}
            />
            <Controller
                control={control}
                name="finePierceTime"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        // disableFocusListener
                        // disableTouchListener
                        leaveDelay={1000}
                        title={
                            isOnFreePlan ? (
                                <Trans t={t}>
                                    Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : reduceTrialAccess ? (
                                <Trans t={t}>
                                    Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : paidFeatures.hasAdvancedMaterials ? (
                                ''
                            ) : (
                                <UpgradePlanLink />
                            )
                        }
                        arrow
                    >
                        <TextField
                            disabled={!paidFeatures.hasAdvancedMaterials || reduceTrialAccess}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: <InputAdornment>{t('s')}</InputAdornment>,
                            }}
                            label={t('Fine pierce time')}
                            sx={classes.input}
                            type="number"
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
                rules={{ min: 0, max: 99999999 }}
            />
            <Controller
                control={control}
                name="smallHolesLimit"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        leaveDelay={1000}
                        title={
                            isOnFreePlan ? (
                                <Trans t={t}>
                                    Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : reduceTrialAccess ? (
                                <Trans t={t}>
                                    Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                                </Trans>
                            ) : paidFeatures.hasAdvancedMaterials ? (
                                ''
                            ) : (
                                <UpgradePlanLink />
                            )
                        }
                        arrow
                    >
                        <TextField
                            disabled={!paidFeatures.hasAdvancedMaterials || reduceTrialAccess}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: <InputAdornment>{useImperialUnits ? 'in' : 'mm'}</InputAdornment>,
                            }}
                            label={t('Small holes limit')}
                            sx={classes.input}
                            type="number"
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
                rules={{ min: 0, max: 99999999 }}
            />
            <Controller
                control={control}
                name="hourlyRateMultiplier"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment>{currencySymbol}</InputAdornment>,
                        }}
                        label={t('Hourly rate multiplier')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required', min: 0, max: 1000 }}
            />

            <TextField
                InputProps={{
                    endAdornment: <InputAdornment>{currencySymbol}</InputAdornment>,
                }}
                label={t('Applied hourly rate')}
                sx={classes.input}
                value={getAppliedHourlyRateValue()}
                disabled
                fullWidth
            />
            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddRateButtonClick)}
                >
                    {isSaving ? t('Saving...') : getSaveButtonText()}
                </Button>
            </Box>
        </>
    )
}

AddRateForm.propTypes = {
    rate: PropTypes.object,
    selectedRateTableId: PropTypes.string,
    onCancel: PropTypes.func,
}

export default AddRateForm
