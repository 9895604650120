//import '@microsoft/applicationinsights-shims'
// Uncomment this to enable the mock service worker
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { SnackbarProvider } from 'notistack'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './app/store'
import { ErrorPageContainer, PageSpinner } from './common/components'
import { theme } from './common/themes/LightTheme'
import { browserHistory, reactPlugin, setPageTitleAndFavicon } from './common/utils'
import App from './App'
import { msalInstance } from './authConfig'
import i18n from './i18n'
import { unregister } from './registerServiceWorker'

// if (process.env.NODE_ENV === 'development') {
//     const { worker } = await import('./mocks/browser')
//     worker.start({
//         onUnhandledRequest: 'warn',
//     })
//     worker.printHandlers()
// }

// Set favicon and page title dynamically
const hideZendesk = () => {
    const maxTries = 30
    let tries = 0
    let pollZendeskTimeout

    const pollZendesk = () => {
        if (typeof window.$zopim === 'undefined' && tries < maxTries) {
            tries++
            pollZendeskTimeout = window.setTimeout(pollZendesk, 1000)
        } else {
            window.$zopim(() => {
                window.$zopim.livechat.hideAll()
            })
        }
    }

    pollZendesk()
}

const siteTheme = setPageTitleAndFavicon(theme)

// Optional Connected account configuration
let stripeOptions = {}
if (import.meta.env.VITE_STRIPE_CONNECTED_ACCOUNT_ID && import.meta.env.VITE_STRIPE_CONNECTED_ACCOUNT_ID !== '') {
    stripeOptions = {
        stripeAccount: import.meta.env.VITE_STRIPE_CONNECTED_ACCOUNT_ID,
    }
}

// Stripe configuration
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, stripeOptions)

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
}

// Google Tag Manager

const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID,
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root')

const onError = () => (
    <ErrorPageContainer
        linkHref="/"
        linkText="Back to your dashboard"
        subtitle="It's not you, it's us, please try that again, or contact support for help."
        title="Something went wrong"
    />
)

createRoot(rootElement).render(
    <StrictMode>
        <Suspense fallback={<PageSpinner />}>
            <ThemeProvider theme={siteTheme}>
                <CssBaseline />
                <Provider store={store}>
                    <PersistGate
                        loading={null}
                        persistor={persistor}
                    >
                        <AppInsightsErrorBoundary
                            appInsights={reactPlugin}
                            onError={onError}
                        >
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <Elements
                                    options={ELEMENTS_OPTIONS}
                                    stripe={stripePromise}
                                >
                                    <I18nextProvider i18n={i18n}>
                                        <MsalProvider instance={msalInstance}>
                                            <BrowserRouter>
                                                <SnackbarProvider
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    autoHideDuration={2500}
                                                    maxSnack={3}
                                                    preventDuplicate
                                                >
                                                    <App />
                                                </SnackbarProvider>
                                            </BrowserRouter>
                                        </MsalProvider>
                                    </I18nextProvider>
                                </Elements>
                            </AppInsightsContext.Provider>
                        </AppInsightsErrorBoundary>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </Suspense>
    </StrictMode>
)

// Unregister all service workers until we can fix bugs related to them
unregister()

export { browserHistory as history }
