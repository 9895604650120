import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { useGetDeliveryPriceDetailByQuoteQuery } from '@/app/services/deliveryPriceDetails'
import { selectCurrencyCode, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useCurrencyFormatter } from '@/common/hooks/'
import { DELIVERY_PRICING_OPTIONS } from '@/common/utils'

import DeliveryContainersTable from './DeliveryContainersTable'

const classes = {
    dialog: (deliveryPriceOption) => ({
        maxWidth: deliveryPriceOption === 'ByWeight' ? 928 : 848,
    }),

    dialogContent: {
        gap: 2,
    },
    dialogSection: {
        gap: 1,
    },

    dialogBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.9),
    },
}

const ShippingBreakdownDialog = ({ onClose, open, quoteId }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)
    const currencyCode = useSelector(selectCurrencyCode)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const { formatCurrency } = useCurrencyFormatter({ currencyCode })

    const weightUnit = useImperialUnits ? 'lb' : 'kg'
    const distanceUnit = useImperialUnits ? 'mi' : 'km'

    const { data: deliveryPriceDetails, isSuccess } = useGetDeliveryPriceDetailByQuoteQuery({
        organisationId,
        quoteId,
    })

    const minimumDeliveryCharge = deliveryPriceDetails?.minimumDeliveryPriceCharge || 0

    const deliveryPriceOption = deliveryPriceDetails?.deliveryPricingOption ?? 'Flat'

    return (
        <Dialog
            maxWidth="lg"
            open={open ? isSuccess : null}
            PaperProps={{
                sx: classes.dialog(deliveryPriceOption),
            }}
            slotProps={{
                backdrop: {
                    sx: classes.dialogBackdrop,
                },
            }}
            fullWidth
            onClose={onClose}
        >
            <DialogTitle>
                <Typography
                    textTransform="uppercase"
                    variant="h6"
                >
                    {t('Delivery price details')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={classes.dialogContent}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.dialogSection}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                            marginBlockEnd={1}
                        >
                            <Typography
                                data-testid="material-section-label"
                                sx={classes.bold}
                                variant="strong2"
                            >
                                {t('Delivery details')}
                            </Typography>
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="sheet-cost-label"
                                variant="body2"
                            >
                                {t('Delivery address')}
                            </Typography>
                            <Typography
                                data-testid="sheet-cost"
                                variant="body2"
                            >
                                {deliveryPriceDetails?.customerDeliveryAddress}
                            </Typography>
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="mark-up-percentage-label"
                                variant="body2"
                            >
                                {t('Delivery zone')}
                            </Typography>
                            <Typography
                                data-testid="mark-up-percentage"
                                variant="body2"
                            >
                                {deliveryPriceDetails?.deliveryZoneName}
                            </Typography>
                        </Box>
                        {DELIVERY_PRICING_OPTIONS[deliveryPriceOption].value === 'ByDistance' ? (
                            <>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="price-adjustment-label"
                                        variant="body2"
                                    >
                                        {t('Pickup address')}
                                    </Typography>
                                    <Typography
                                        data-testid="price-adjustment"
                                        variant="body2"
                                    >
                                        {deliveryPriceDetails?.vendorPickupAddress}
                                    </Typography>
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="sheet-sell-price-label"
                                        variant="body2"
                                    >
                                        {t('Distance')}
                                    </Typography>
                                    <Typography
                                        data-testid="sheet-sell-price"
                                        variant="body2"
                                    >
                                        {deliveryPriceDetails?.distance} {distanceUnit}
                                    </Typography>
                                </Box>
                            </>
                        ) : null}
                    </Box>

                    <Divider />

                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.dialogSection}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                            marginBlockEnd={1}
                        >
                            <Typography
                                data-testid="cutting-section-label"
                                sx={classes.bold}
                                variant="strong2"
                            >
                                {t('Delivery containers')}
                            </Typography>
                        </Box>
                        <DeliveryContainersTable
                            deliveryContainerDetails={deliveryPriceDetails?.deliveryContainerDetails}
                            deliveryPriceOption={deliveryPriceOption}
                        />
                    </Box>

                    <Divider />

                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.dialogSection}
                    >
                        {minimumDeliveryCharge ? (
                            <>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="total-label"
                                        sx={classes.summaryRow}
                                        variant="strong2"
                                    >
                                        {t('Subtotal')}
                                    </Typography>
                                    <Typography
                                        align="right"
                                        data-testid="total-item"
                                        sx={classes.summaryRow}
                                        variant="strong2"
                                    >
                                        {formatCurrency(deliveryPriceDetails?.deliveryPrice - minimumDeliveryCharge) ??
                                            String.fromCharCode(8212)}
                                    </Typography>
                                </Box>

                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="total-label"
                                        sx={classes.summaryRow}
                                        variant="body2"
                                    >
                                        {t('Minimum delivery charge')}
                                    </Typography>
                                    <Typography
                                        align="right"
                                        data-testid="total-item"
                                        sx={classes.summaryRow}
                                        variant="body2"
                                    >
                                        {formatCurrency(minimumDeliveryCharge) ?? String.fromCharCode(8212)}
                                    </Typography>
                                </Box>
                            </>
                        ) : null}

                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="total-label"
                                sx={classes.summaryRow}
                                variant="strong1"
                            >
                                {t('Delivery price')}
                            </Typography>
                            <Typography
                                align="right"
                                data-testid="total-item"
                                sx={classes.summaryRow}
                                variant="strong1"
                            >
                                {formatCurrency(deliveryPriceDetails?.deliveryPrice) ?? String.fromCharCode(8212)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

ShippingBreakdownDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    quoteId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default ShippingBreakdownDialog
